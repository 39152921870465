import {useMemo} from 'react';
import {Activities, Genders, SpeedModeList} from "../enums/onBoardingEnum";
import {useAppSelector} from "../store/types";

export function roundToNearest50(num: number) {
  return Math.round(num / 25) * 25;
}

export function roundToNearestHalf(num: number) {
  return Math.round(num * 2) / 2;
}

export const useTDEE = (mode: SpeedModeList) => {
  const {activity, age, height, gender, weight, targetWeight} = useAppSelector(
    state => state.onBoarding,
  );
  const bmrMale = useMemo(() => {
    if (!weight || !height || !age) {
      return 0;
    }
    const w = 13.75 * weight;
    const h = 5.003 * height;
    const a = 6.755 * age;
    // console.log(w, h, a)
    return 66.47 + w + h - a;
  }, [weight, age, height]);

  const looseWeight = useMemo(
    () => (weight && targetWeight ? weight > targetWeight : false),
    [weight, targetWeight],
  );

  const bmrFamale = useMemo(() => {
    if (!weight || !height || !age) {
      return 0;
    }
    const w = 9.563 * weight;
    const h = 1.85 * height;
    const a = 4.676 * age;
    return 655.1 + w + h - a;
  }, [weight, age, height]);

  let tdee = 0;
  let bmr = 0;

  if (gender === Genders.MALE) {
    bmr = bmrMale;
  } else if (gender === Genders.FEMALE) {
    bmr = bmrFamale;
  } else {
    bmr = (bmrMale + bmrFamale) / 2;
  }

  switch (activity) {
    case Activities.LOW:
      tdee = bmr * 1.2;
      break;
    case Activities.MEDIUM:
      tdee = bmr * 1.375;
      break;
    case Activities.HIGH:
      tdee = bmr * 1.55;
      break;
    case Activities.VERY_HIGH:
      tdee = bmr * 1.725;
      break;
    case Activities.EXTRA:
      tdee = bmr * 1.9;
      break;
  }
  // console.log(activity, age, height, gender, weight, targetWeight);

  let month = 0;
  let kg = 0;
  let calories = 0;
  let surplus = 0;
  if (looseWeight) {
    switch (mode) {
      case SpeedModeList.GRADUAL:
        surplus = 0.9 * tdee;
        break;
      case SpeedModeList.MODERATE:
        surplus = 0.8 * tdee;
        break;
      case SpeedModeList.AGGRESSIVE:
        surplus = 0.7 * tdee;
        break;
    }
    calories = Number(tdee - surplus);
    let monthCalorieDeficit = calories * 30;
    kg = Number(Number(monthCalorieDeficit) / 7700);
    if (targetWeight && weight) {
      month = roundToNearestHalf(Number((weight - targetWeight) / kg));
    }
    kg = Number(kg.toFixed(1));
    calories = roundToNearest50(Number(surplus));
  } else {
    switch (mode) {
      case SpeedModeList.GRADUAL:
        surplus = tdee * 0.1;
        break;
      case SpeedModeList.MODERATE:
        surplus = tdee * 0.2;
        break;
      case SpeedModeList.AGGRESSIVE:
        surplus = tdee * 0.3;
        break;
    }
    calories = roundToNearest50(Number(surplus + tdee));
    let surplusMonth = surplus * 30;
    let cala = 0;
    if (targetWeight && weight) {
      cala = Number(7700 * Number(targetWeight - (weight)));
    }
    month = Number(cala / surplusMonth);
    if (targetWeight && weight) {
      kg = Number(((targetWeight - weight) / month).toFixed(1));
    }
    month = roundToNearestHalf(Number(cala / surplusMonth));
  }

  return {
    month,
    kg,
    calories,
    looseWeight,
    tdee,
  };
};
