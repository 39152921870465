import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./SubCategoryItem.module.css";
import {TreeNodeType} from "../../../../../types/onBoardingTypes";
import AngelDownIcon from "../../../../../icons/AngelDownIcon";
import {ColorsEnum} from "../../../../../enums/ColorsEnum";
import DisableIcon from "../../../../../icons/DisableIcon";
import ProductMainCategoryItem from "../ProductMainCategoryItem/ProductMainCategoryItem";

interface TreeNodeProps {
  node: TreeNodeType;
  onNodeToggle: (nodeId: number, isSelected: boolean) => void;
  onNodeExpand: (nodeId: number, level: number) => void;
  expandedNodeIds: Set<number>;
  level: number;
  onChangeHeight: () => void;
  width?: number;
}

const SubCategoryItem: React.FC<TreeNodeProps> = ({
  node,
  onNodeExpand,
  onNodeToggle,
  expandedNodeIds,
  level,
  width
}) => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const isExpanded = expandedNodeIds.has(node.id);


  const toggleAnimationValue = useCallback(
    (isLocalOpen: boolean) => {
      if (isLocalOpen) {
        if (contentRef.current) {
          const newHeight = contentRef.current.scrollHeight;
          setHeight(newHeight);
        }
      } else {
          setHeight(0);
      }
    },
    [],
  );

  const handlePress = () => {
    onNodeExpand(node.id, level);
  };

  const handleToggle = () => {
    onNodeToggle(node.id, !node.selected);
  };

  useEffect(() => {
    toggleAnimationValue(isExpanded);
  }, [isExpanded, toggleAnimationValue]);

  return (
    <div className={styles.container} style={{
      // display: isExpanded ? "block" : 'none'
    }}>
      <div className={styles.subCategoryItem}>
        <div className={styles.header} onClick={handlePress}>
          <div className={[styles.iconContainer, isExpanded ? styles.rotate : null].join(' ')}>
            <AngelDownIcon/>
          </div>
          <div className={styles.textContainer}>
          <span className={styles.text} style={{
            color: ColorsEnum.GRAY_SCALE,
            maxWidth: width ? width - 120 : 'initial'
          }}>
            {node.name}
          </span>
          </div>
        </div>
        <div onClick={handleToggle} className={styles.disableProductButton}>
          <DisableIcon fill={node.selected ? ColorsEnum.ERROR_TEXT : ColorsEnum.GRAY_SCALE}/>
          {node.partial ? <div className={styles.dot} style={{
            backgroundColor: ColorsEnum.ERROR_TEXT
          }}/> : null}
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div ref={contentRef} className={[styles.body, isExpanded ? styles.open : null].join(' ')}
             style={{ maxHeight: isExpanded ? `${height}px` : '0' }}
         >
          {node.children ? (
            <div>
              {node.children.map(el => {
                return (
                  <ProductMainCategoryItem
                    selected={el.selected}
                    name={el.name}
                    key={el.id}
                    onPress={() => onNodeToggle(el.id, !el.selected)}
                    width={width}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default SubCategoryItem
