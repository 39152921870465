import React, {useEffect, useRef, useState} from 'react';
import styles from './PlanRow.module.css'

interface PlanRowProps {
  name: string;
  description: string;
  color: string;
  icon: string;
  timeout: number
}

const PlanRow: React.FC<PlanRowProps> = ({
                                           name,
                                           description,
                                           color,
                                           icon,
                                           timeout
                                         }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [contentWidth, setContentWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContentWidth((containerRef.current.clientWidth - 80) * 0.9)
      }
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    setTimeout(() => {
      if (containerRef.current) {
        setContentWidth((containerRef.current.clientWidth - 80) * 0.9)
      }
    }, timeout)
    return () => {
      setContentWidth(0)
    }
  }, [containerRef.current]);

  return (
    <div ref={containerRef}>
      <div className={styles.smilesElement}>
        <div className={styles.smilesElementStartIcon}>
          <img src={icon} alt=""/>
        </div>
        <div className={styles.rowContainer}>
          <div className={styles.progress}>
            <div className={styles.title}>
              {name}
            </div>
            <div className={styles.progressElement} style={{
              backgroundColor: color,
              width: contentWidth,
            }}></div>
          </div>
        </div>
        <div className={styles.smilesElementEndIcon}>
          <img src={require('../../../../assets/smiles/SadEmoji.png')} alt=""/>
        </div>
      </div>
      <div className={styles.description}>
        {description}
      </div>
    </div>
  )
}

export default PlanRow
