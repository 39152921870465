import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../store/types";
import {TreeNodeType} from "../../../../types/onBoardingTypes";
import {loadProductCategories, setProductsCategories} from "../../../../store/OnBoarding/OnBoardingSlice";
import {cloneDeep} from "lodash"
import {useProductsTree} from "../../../../hooks/useProductsTree";
import {useHistory} from "react-router-dom";
import PrimaryCategoryItem from "./PrimaryCategoryItem/PrimaryCategoryItem";
import ArrowLeftIcon from "../../../../icons/ArrowLeftIcon";
import {ColorsEnum} from "../../../../enums/ColorsEnum";
import styles from './DislikesProductsModal.module.css'

const DislikesProductsModal: React.FC = () => {
  const history = useHistory();
  const {
    productsCategories,
    selectedProductsCategory,
  } = useAppSelector(state => state.onBoarding);
  const dispatch = useAppDispatch();
  const [treeData, setTreeData] = useState<TreeNodeType[]>([]);
  const [expandedNodeIds, setExpandedNodeIds] = useState<Set<number>>(
    new Set(),
  );
  const {updateNodeSelection} = useProductsTree()
  useEffect(() => {
    setTreeData(
      cloneDeep(
        selectedProductsCategory.length > 0
          ? selectedProductsCategory
          : productsCategories,
      ),
    );
  }, [selectedProductsCategory, productsCategories]);

  const handleNodeToggle = (nodeId: number, isSelected: boolean) => {
    const list = cloneDeep(treeData)
    const updatedData = list.map(node =>
      updateNodeSelection(node, nodeId, isSelected),
    );
    // setTreeData(updatedData);
    dispatch(setProductsCategories(updatedData));
  };

  const handleNodeExpand = (nodeId: number, level: number) => {
    const newExpandedNodeIds = new Set(expandedNodeIds);
    const parentLevelNodes = findNodesAtLevel(treeData, level);
    if (newExpandedNodeIds.has(nodeId)) {
      newExpandedNodeIds.delete(nodeId);
    } else {
      parentLevelNodes.forEach(node => {
        if (node.id !== nodeId) {
          if (node.children) {
            const childLevelNodes = findNodesAtLevel(node.children, level);
            childLevelNodes.forEach(node => {
              newExpandedNodeIds.delete(node.id);
            })
          }
          newExpandedNodeIds.delete(node.id);
        }
      });
      newExpandedNodeIds.add(nodeId);
    }

    setExpandedNodeIds(newExpandedNodeIds);
  };

  const findNodesAtLevel = (
    nodes: TreeNodeType[],
    level: number,
    currentLevel: number = 0,
  ): TreeNodeType[] => {
    if (currentLevel === level) {
      return nodes;
    }

    let result: TreeNodeType[] = [];
    for (const node of nodes) {
      if (node.children) {
        result = result.concat(
          findNodesAtLevel(node.children, level, currentLevel + 1),
        );
      }
    }
    return result;
  };

  const goBack = () => {
    history.goBack()
  };

  const saveProducts = () => {
    dispatch(setProductsCategories(treeData));
    goBack();
  };
  return (
    <div className={styles.container}>
      <div className={styles.navBar}>
        <div className={styles.title}>
          <div onClick={goBack} className={styles.backButton}>
            <ArrowLeftIcon stroke={ColorsEnum.GRAY_BLACK}/>
          </div>
          <div className={styles.text}>All products</div>
        </div>
        <div onClick={saveProducts} className={styles.done}>Done</div>
      </div>
      <div className={styles.description}>
        Select the products you dislike or don't eat.
      </div>
      {treeData.map(node => (
        <PrimaryCategoryItem
          key={node.id}
          node={node}
          onNodeToggle={handleNodeToggle}
          onNodeExpand={handleNodeExpand}
          expandedNodeIds={expandedNodeIds}
          level={0}
        />
      ))}
    </div>
  )
}

export default DislikesProductsModal
