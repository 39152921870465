import React from "react";
import styles from './ReviewItem.module.css';
import {ReactComponent as Star} from '../../../../../assets/icons/star.svg';
import {colorsList} from "../../../../../hooks/appEnum";


interface ReviewItemProps {
  rating: number;
  name: string;
  text: string;
  color?: string;
}

function initials(str: string) {
  return str.split(/\s+/).map((w,i) => w.substring(0,1).toUpperCase());
}

const ReviewItem: React.FC<ReviewItemProps> = ({name, rating, text, color}) => {

  return (
    <div className={styles.container}>
      <div className={styles.reviewInfo}>
        <div className={styles.nameContainer}>
          <div className={styles.initials} style={{backgroundColor: color ? color : colorsList[0]}}>
            {initials(name)}
          </div>
          <div className={styles.name}>{name}</div>
        </div>
        <div className={styles.ratingContainer}>
          {Array.from(Array(Math.round(rating)).keys()).map(el => {
            return (
              <div className={styles.starContainer}>
                <Star width={17} key={el} />
              </div>
            )
          })}
        </div>
      </div>
      {text.length > 0 ? (
        <div className={styles.textContainer}>
          "{text}"
        </div>
      ) : null}

    </div>
  )
}

export default ReviewItem
