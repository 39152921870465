import React, {useEffect, useRef} from "react";
import styles from "./Graph.module.css";
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import GraphGraph from "./GraphGraph";
import {useHistory} from "react-router-dom";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";

const Graph: React.FC = () => {
  const history = useHistory();
  const onPressNext = () => {
    history.push(OnBoardingRoutesEnum.GENDER)
  }
  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement}className={styles.container}>
      <div className={styles.containerData}>
        <div className={styles.listData}>
          <StepInfo title={"Eatr provides long-term result"}/>
          <div>
            <GraphGraph />
            <div className={styles.title}>
              78% of Eatr users sustain their weight loss for six months.
            </div>
          </div>
        </div>
      </div>
        <NextButton
          fixed={true} withGradient={true}
          onClick={onPressNext}/>
    </div>
  )
}

export default Graph
