import React from 'react';
import styles from './MoneyBack.module.css';
import { ReactComponent as  MoneyBackImage} from '../../../assets/money-back.svg'

const MoneyBack: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      Money-Back guarantee
    </div>
    <div className={styles.textContainer}>
      <p className={styles.text}>
        We're confident with our service quality and its results. So, if you're ready to reach your goals, give us a
        try! If you're not happy with your results, just let us know within 30 days after your purchase and we'll
        provide a full refund.
        {/*<MoneyBackImage className={styles.image}/>*/}
      </p>
      <img src={require('../../../assets/money-back.png')} alt="" className={styles.image}/>
      <p className={styles.text}>
        You will need to demonstrate that you have followed the program.
      </p>
    </div>
  </div>
)

export default MoneyBack
