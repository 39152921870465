import React from "react";
import {IconProps} from "../types/types";
const ModerateSpeedIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg viewBox="0 0 69 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3011_67386)">
        <path
          d="M44.7166 3.11303C43.9476 1.77558 42.018 1.77552 41.249 3.11292L18.3047 43.0137H67.6575L44.7166 3.11303Z"
          fill="#E6E7E6"
        />
        <path
          d="M43.8651 2.49021C43.4907 1.8326 42.5498 1.81301 42.1483 2.45446L37.5203 9.84942C37.1035 10.5154 37.5823 11.3799 38.368 11.3799H43.0387H47.2061C47.9732 11.3799 48.4547 10.5518 48.0751 9.88517L43.8651 2.49021Z"
          fill="white"
        />
        <path
          d="M67.6587 43.0137L43.8509 1.60531C43.4664 0.936588 42.5016 0.936558 42.1171 1.60526L22.6484 35.4618"
          stroke="#B5B6B5"
          strokeWidth="1.5"
          strokeMiterlimit="9.57"
          strokeLinecap="round"
        />
        <path
          d="M21.3389 8.22192L1.33594 43.0134H21.3407H41.3454L21.3389 8.22192Z"
          fill="#F3F3F3"
        />
        <path
          d="M22.452 9.78328C22.0803 9.11169 21.1218 9.092 20.7228 9.74776L16.8778 16.067C16.4724 16.7334 16.9521 17.5868 17.7321 17.5868H21.6198H25.0748C25.8366 17.5868 26.3187 16.7691 25.9498 16.1026L22.452 9.78328Z"
          fill="white"
        />
        <path
          d="M1.33594 43.0134L20.4832 9.71028C20.8652 9.04587 21.8219 9.04047 22.2113 9.70053L34.4948 30.5179"
          stroke="#B5B6B5"
          strokeWidth="1.5"
          strokeMiterlimit="9.57"
          strokeLinecap="round"
        />
        <path
          d="M15.4977 51.5849L20.7073 48.9133L20.3104 47.0291M28.2441 47.3259L25.3876 43.7295L19.6489 43.8888L16.2633 37.7802M16.2633 37.7802L12.482 42.2499L14.6244 44.9471M16.2633 37.7802L21.1029 38.335L24.5143 37.0917M13.4068 34.1838C13.5962 34.4223 13.8725 34.5757 14.1751 34.6104C14.4776 34.6451 14.7815 34.5582 15.02 34.3688C15.2584 34.1794 15.4119 33.903 15.4466 33.6005C15.4813 33.298 15.3943 32.994 15.2049 32.7556C15.0155 32.5171 14.7392 32.3637 14.4366 32.329C14.1341 32.2943 13.8302 32.3812 13.5917 32.5706C13.3533 32.76 13.1998 33.0364 13.1652 33.3389C13.1305 33.6415 13.2174 33.9454 13.4068 34.1838Z"
          stroke="#9D9E9C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.4134 1.30434C46.6944 37.4501 37.2049 51.3646 1.49954 58.5"
          stroke="#8DCC6F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3011_67386">
          <rect
            width="68"
            height="60"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ModerateSpeedIcon
