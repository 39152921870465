import React, {ReactElement, SVGProps, useMemo} from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import {useAppSelector} from "../../store/types";
import {useTDEE} from "../../hooks/useTDEE";
import {MayorSystem} from "../../store/Options/OptionsSlice";
import {ColorsEnum} from "../../enums/ColorsEnum";
import {lb} from "../../hooks/useConvert";
import moment from "moment";
import {addMonths} from "../../helper/date";
import { log } from "console";

interface TableProps {
  width: number
}

interface TableDataProps {
  name: string,
  value: number,
  target: boolean,
}

const Table: React.FC<TableProps> = ({width}) => {
  const {mayorSystem} = useAppSelector(state => state.settings)
  const {targetWeight, weight, mode, imperialTargetWeight, imperialWeight} = useAppSelector(state => state.onBoarding);
  const {month, looseWeight} = useTDEE(mode);

  const currentTargetWeight = useMemo(() => {
    if (mayorSystem === MayorSystem.METRIC) {
      return targetWeight || 0
    } else {
      return imperialTargetWeight || 0;
    }
  }, [imperialTargetWeight, mayorSystem, targetWeight])

  const currentWeight = useMemo(() => {
    if (mayorSystem === MayorSystem.METRIC) {
      return weight || 0
    } else {
      return imperialWeight || 0
    }
  }, [imperialWeight, mayorSystem, weight])

  const data = useMemo<TableDataProps[]>(() => {
    const values: TableDataProps[] = [];
    const w = currentWeight;
    const tw = currentTargetWeight;
    values.push({
      name: 'Now',
      value: w,
      target: false
    });
    if (w && tw) {
      if (tw !== w) {
        if (w > tw) {
          values.push({
            name: '',
            value: w  - ((w - tw) * 0.75),
            target: false
          });
        } else {
          values.push({
            name: '',
            value: w  + ((tw - w) * 0.75),
            target: false
          });
        }
      }
    }

    let date = addMonths(moment(), Math.floor(month))

    if (tw === w) {
      values.push({
        name: '',
        value: w,
        target: false
      });
      values.push({
        name: date.format('MMM, YYYY'),
        value: w,
        target: true
      });
      values.push({
        name: '',
        value: w,
        target: false
      });
    } else {
      values.push({
        name: date.format('MMM, YYYY'),
        value: tw,
        target: false
      });

      values.push({
        name: '',
        value: tw,
        target: false
      });
      values.push({
        name: '',
        value: tw,
        target: false
      });
    }
    return values
  }, [currentTargetWeight, currentWeight, looseWeight, month])

  const percentage = useMemo(() => {
    const targetIndex = data.findIndex(el => el.target)
    return 100 - ((data.length - targetIndex - 1) / (data.length - 1)) * 100
  }, [data]);

  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value, index } = props;

    if (value !== currentWeight && value !== currentTargetWeight) {
      return null
    }

    if (currentWeight === currentTargetWeight) {
      if (index === 0) {
        return (
          <text x={x} y={y} dy={looseWeight ? -15 : 15} dx={20} fill={stroke} fontSize={14} fontFamily={'Nunito Sans'} color={ColorsEnum.GRAY_BLACK} textAnchor="middle">
            {value} {mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'}
          </text>
        )
      }
      if (index === 2) {
        return (
          <>
            <svg x={x - 28.5} y={y - 50} width="56" height="43" viewBox="0 0 56 43" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M12 0C5.37258 0 0 5.37258 0 12V24C0 30.6274 5.37258 36 12 36H21.8408L28.1992 42.3584L34.5576 36H44C50.6274 36 56 30.6274 56 24V12C56 5.37258 50.6274 0 44 0H12Z"
                    fill="#D0FFB9"/>

            </svg>
            <text x={x} y={y} dy={-25} fill={stroke} fontSize={14} fontFamily={'Nunito Sans'}
                  color={ColorsEnum.GRAY_BLACK} textAnchor="middle">
              {value} {mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'}
            </text>
          </>
        );
      }
    } else {
      if (value === currentWeight) {
        return (
          <text x={x} y={y} dy={looseWeight ? -10 : 10} dx={30} fill={stroke} fontSize={14} fontFamily={'Nunito Sans'}
                color={ColorsEnum.GRAY_BLACK} textAnchor="middle">
            {value} {mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'}
          </text>
        )
      }
      if (index !== 2) {
        return
      }

      return (
        <>
          {/*<svg x={x - 28.5} y={y - 68} width="57" height="63" viewBox="0 0 57 63" fill="none"*/}
          {/*     xmlns="http://www.w3.org/2000/svg">*/}
          {/*  <path fillRule="evenodd" clipRule="evenodd"*/}
          {/*        d="M12.5 0C5.87258 0 0.5 5.37258 0.5 12V44C0.5 50.6274 5.87258 56 12.5 56H22.3408L28.6992 62.3584L35.0576 56H44.5C51.1274 56 56.5 50.6274 56.5 44V12C56.5 5.37258 51.1274 0 44.5 0H12.5Z"*/}
          {/*        fill="#79C257"/>*/}

          {/*</svg>*/}
          <svg x={x - 17} y={y - 50} width={80} height="38" viewBox={`0 0 80 38`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.21324 0.164551C3.22948 0.164551 0 3.39403 0 7.37779V25.9513C0 29.9351 3.22948 33.1646 7.21323 33.1646H13.0905L17.2188 37.269L21.347 33.1646H72.7868C76.7705 33.1646 80 29.9351 80 25.9513V7.37779C80 3.39403 76.7705 0.164551 72.7868 0.164551H7.21324Z"
                  fill="#79C257"/>
          </svg>

          <text x={x} y={y} dx={22} dy={-28.5} fontWeight={'600'} fill={ColorsEnum.GRAY_SCALE_WHITE} fontSize={14} fontFamily={'Nunito Sans'}
                textAnchor="middle">
            Goal {value} {mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'}
          </text>
          {/*<text x={x} y={y} dy={-25} fill={stroke} fontSize={14} fontFamily={'Nunito Sans'}*/}
          {/*      color={ColorsEnum.GRAY_BLACK} textAnchor="middle">*/}

          {/*</text>*/}
        </>
      );
    }
  }
  const CustomizedDot = (props: any) => {
    const {cx, cy, value, index} = props;
    const [_, dotValue] = value;

    if (currentWeight === currentTargetWeight) {
      if (index !== 0 && index !== 2) {
        return <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="transparent" viewBox="0 0 1024 1024"/>
      }

      if (index === 0 && index === 2) {
        return <svg x={cx - 5.5} y={cy - 5.5} width={11} height={11} fill="#8BCEFF" viewBox="0 0 30 30">
          <circle r="14" cx="14" cy="14"/>
        </svg>
      }
    }

    if (dotValue !== currentWeight && dotValue !== currentTargetWeight) {
      return <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="transparent" viewBox="0 0 1024 1024"/>
    }
    if (index !== 2 && index !== 0) {
      return <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="transparent" viewBox="0 0 1024 1024"/>
    }
    return (
      <svg x={cx - 5.5} y={cy - 5.5} width={11} height={11} fill="#8BCEFF" viewBox="0 0 30 30">
        <circle r="14" cx="14" cy="14"/>
      </svg>
    );
  };

  const CustomXAxisLabel = (props: any) => {
    const {x, y, payload} = props
    return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={12} fontSize={14} fontFamily={'Nunito Sans'}
                color={ColorsEnum.GRAY_BLACK} textAnchor="middle">
            {payload.value}
          </text>
        </g>
    )
  }

  const gradientOffset = () => {
    const dataMax = Math.max(...data.map((i) => i.value));
    const dataMin = Math.min(...data.map((i) => i.value));
    if (targetWeight) {
      if (dataMax <= targetWeight) {
        return 0;
      }
      if (dataMin === 0) {
        return 1;
      }
    } else {
      return 1
    }

    return dataMax / (dataMax - dataMin);
  };
  const xAxeDomain = useMemo(() => {
    if (window.screen.width <= 540) {
      let maxValCT = 15;
      let maxVal = 15;
      if (currentTargetWeight > currentWeight) {
        if (currentTargetWeight - currentWeight > 50) {
          maxValCT = 30;
        }
      } else {
        if (currentWeight - currentTargetWeight > 50) {
          maxVal = 30;
        }
      }

      return currentWeight < currentTargetWeight ? ['dataMin - 5', `dataMax + ${maxValCT}`] : ['dataMin - 5', `dataMax + ${maxVal}`]
    } else {
      return currentWeight < currentTargetWeight ? ['dataMin - 3', 'dataMax + 3'] : ['dataMin - 2', 'dataMax + 3']
    }
  }, [currentTargetWeight, currentWeight])
  console.log(window.screen.width < 540, window.screen)
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // paddingBottom: 12
    }}>
      <ResponsiveContainer  height={window.screen.width < 540 ? 200 : 280} style={{
        paddingLeft: 10,
        paddingRight: 10
      }}>
        <AreaChart
          // width={width}
          height={window.screen.width < 540 ? 200 : 280}
          data={data}
          syncId="anyId"
          margin={{
            top: window.screen.width < 540 ? 0 : 50,
            right: 10,
            left: 20,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
              <stop offset="0%" stopColor="#8BCEFF"/>
              <stop offset={`${percentage}%`} stopColor="#8BCEFF"/>
              <stop offset={`${percentage}%`} stopColor="#8BCEFF"/>
              <stop offset="100%" stopColor="#D0FFB9"/>
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#8BCEFF" stopOpacity={0.2}/>
              <stop offset={`100%`} stopColor="#8BCEFF" stopOpacity={0}/>
              {/*<stop offset={`${percentage}%`} stopColor="#8DCC6F" stopOpacity={0.2}/>*/}
              {/*<stop offset="100%" stopColor="#8DCC6F" stopOpacity={0}/>*/}
            </linearGradient>
          </defs>
          <CartesianGrid horizontal={false} vertical={true}/>
          <XAxis
            name="name"
            dataKey='name'
            axisLine={false}
            tickLine={false}
            tick={CustomXAxisLabel}
            interval={0}
          />
          <YAxis hide={true} axisLine={false} tickSize={0} tickLine={false} domain={xAxeDomain}/>
          <Area
            key={new Date().toDateString()}
            label={CustomizedLabel}
            dot={CustomizedDot}
            type="monotone"
            dataKey="value"
            strokeWidth={2}
            stroke={currentWeight === currentTargetWeight ? '#82ca9d' : "url(#gradient)"}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Table
