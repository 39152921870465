import React, {useEffect, useState} from 'react';
import styles from './PaymentMethods.module.css'
import Payments from "./Payments/Payments";
import {ReactComponent as ApplePayIcon} from "../../../assets/icons/applePay.svg";
import {ReactComponent as PayPalIcon} from "../../../assets/icons/paypal.svg";
import {ReactComponent as VisaIcon} from "../../../assets/icons/visa.svg";
import {ReactComponent as MasterCardIcon} from "../../../assets/icons/mastercard.svg";
import {ReactComponent as MaestroIcon} from "../../../assets/icons/maestro.svg";
import {ReactComponent as AmericanExpressIcon} from "../../../assets/icons/american-express.svg";
import PayPal from "./Payments/PayPal/PayPal";
import ApplePay from "./Payments/ApplePay/ApplePay";
import Card from "./Payments/Card/Card";
import {useAppSelector} from "../../../store/types";
import {useSubscriptionData} from "../../../hooks/useSubscriptionData";


export enum PaymentTypes {
  ApplePay = "ApplePay",
  PayPay = "PayPay",
  Card = 'card'
}

interface PaymentsVariants {
  name: PaymentTypes,
  title: string,
  icon: React.FC[]
  Component: React.FC<{ planId?: string, appleShow?: boolean, setShowApple?: (status: boolean) => void }>,
}

export const paymentTypes: PaymentsVariants[] = [
  {
    name: PaymentTypes.ApplePay,
    title: 'Apple Pay',
    icon: [ApplePayIcon],
    Component: ApplePay,
  },
  // {
  //   name: PaymentTypes.PayPay,
  //   title: 'PayPay',
  //   icon: [PayPalIcon],
  //   Component: PayPal
  // },
  {
    name: PaymentTypes.Card,
    title: 'Credit Card',
    icon: [VisaIcon, MasterCardIcon, MaestroIcon, AmericanExpressIcon],
    Component: Card
  },
]

const PaymentMethods: React.FC<{ clientSecret: string }> = ({clientSecret}) => {
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentTypes>(PaymentTypes.ApplePay)
  const {couponIndex} = useAppSelector(state => state.products)
  const [showApple, setShowApple] = useState<boolean>(true)
  const {subdcriptionList} = useSubscriptionData();
  useEffect(() => {
    if (!showApple) {
      setSelectedPaymentType(PaymentTypes.Card)
    } else {
      setSelectedPaymentType(PaymentTypes.ApplePay)
    }
  }, [showApple]);
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Payment method
      </div>
      <div className={styles.subtitle}>
        Eatr will use your payment details for seamless future payments.
      </div>
      <div className={styles.paymentsListContainer}>
        {
          paymentTypes.map((el, index) => {
            const FormComponent: React.FC<{ planId?: string, appleShow?: boolean, setShowApple?: (status: boolean) => void }> = el.Component;
            return (
              <div key={index} className={styles.paymentElementContainer} style={{
                display: el.name === PaymentTypes.ApplePay ? (showApple ? 'block' : 'none') : 'block'
              }}>
                <div className={styles.paymentElementTitle} onClick={() => setSelectedPaymentType(el.name)}>
                  <div className={styles.titleContainer}>
                    <div className={styles.checkbox}>
                      <input checked={selectedPaymentType === el.name} type="checkbox"/>
                      <span className={styles.checkmark}></span>
                    </div>
                    <div className={styles.paymentTitle}>{el.title}</div>
                  </div>
                  <div className={styles.paymentsIconsList}>{el.icon.map(El => <El/>)}</div>
                </div>
                {selectedPaymentType === el.name ? <FormComponent appleShow={showApple} setShowApple={setShowApple} planId={subdcriptionList[couponIndex]} /> : null}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default PaymentMethods
