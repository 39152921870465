import React from 'react';
import styles from './ChartLegendElement.module.css'

interface ChartLegendElementProps {
  name: string;
  value: number;
  color: string;
}

const ChartLegendElement: React.FC<ChartLegendElementProps> = ({
  name,
  value,
  color
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerName} style={{
        backgroundColor: color
      }}>
        <span className={styles.name}>{name}</span>
      </div>
      <span className={styles.value}>
        {value}%
      </span>
    </div>
  )
}

export default ChartLegendElement
