import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {TreeNodeType} from "../../../../../types/onBoardingTypes";
import styles from './PrimaryCategoryItem.module.css'
import {ColorsEnum} from "../../../../../enums/ColorsEnum";
import AngelDownIcon from "../../../../../icons/AngelDownIcon";
import DisableIcon from "../../../../../icons/DisableIcon";
import {getImageLinkWithResize} from "../../../../../helper/appHelper";
import {ImageSizeEnum} from "../../../../../hooks/appEnum";
import SubCategoryItem from "../SubCategoryItem/SubCategoryItem";
import {commonProductsList, productsList} from "../../productsList";

interface TreeNodeProps {
  node: TreeNodeType;
  onNodeToggle: (nodeId: number, isSelected: boolean) => void;
  onNodeExpand: (nodeId: number, level: number) => void;
  expandedNodeIds: Set<number>;
  level: number;
}

const PrimaryCategoryItem: React.FC<TreeNodeProps> = ({
                                                        node,
                                                        onNodeExpand,
                                                        onNodeToggle,
                                                        expandedNodeIds,
                                                        level
                                                      }) => {
  const isExpanded = expandedNodeIds.has(node.id);
  const [infoText, setInfoText] = useState('');
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const getInfoText = useCallback(() => {
    let text = '';
    if (node.partial && node.children) {
      node.children.map(sub => {
        if (sub.selected) {
          if (text.length === 0) {
            text = `All ${sub.name.toLowerCase()}`;
          } else {
            text += `, all ${sub.name.toLowerCase()}`;
          }
        } else if (sub.partial && sub.children) {
          sub.children.map(child => {
            if (child.selected) {
              if (text.length === 0) {
                const name = child.name;
                name[0].toUpperCase();
                text = name;
              } else {
                text += `, ${child.name.toLowerCase()}`;
              }
            }
          });
        }
      });
    }
    return text;
  }, [node]);

  useEffect(() => {
    setInfoText(getInfoText());
  }, [getInfoText, node.children]);

  const handlePress = () => {
    onNodeExpand(node.id, level);
  };


  const toggleAnimationValue = useCallback(
    (isLocalOpen: boolean) => {
      if (isLocalOpen) {
        if (contentRef.current) {
          const newHeight = contentRef.current.scrollHeight;
          // setHeight(newHeight);
        }
      } else {
        // setHeight(0);
      }
    },
    [],
  );

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      // setHeight(entries[0].borderBoxSize);
      setHeight(entries[0].target.scrollHeight)
      setWidth(entries[0].target.clientWidth)
    })
    if (contentRef.current) {
      observer.observe(contentRef.current)
    }
    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current)
      }
    }
  }, [contentRef])

  const handleToggle = () => {
    onNodeToggle(node.id, !node.selected);
  };

  useEffect(() => {
    toggleAnimationValue(isExpanded);
  }, [isExpanded, toggleAnimationValue]);

  const getCommonImageByName = (name: string) => {
    const index = commonProductsList.findIndex(el => el.name === name);
    if (index >= 0) {
      return commonProductsList[index].image;
    }
  }

  return (
    <div className={styles.container} style={{
      zIndex: 10 - level
    }}>
      <div className={styles.row}>
        <div className={styles.header} style={{
          borderColor: isExpanded ? ColorsEnum.DARK_20 : ColorsEnum.GRAY_SCALE_95,
          backgroundColor: isExpanded ? ColorsEnum.NORMAL_80 : ColorsEnum.GRAY_SCALE_WHITE,
        }} onClick={handlePress}>
          <div className={[styles.iconContainer, isExpanded ? styles.rotate : null].join(' ')}>
            <AngelDownIcon/>
          </div>
          {getCommonImageByName(node.name) ? (
            <img
              src={getCommonImageByName(node.name)}
              alt={node.name}
              className={styles.image}
            />
          ) : <div className={styles.image}></div>}
          <div className={styles.textContainer} style={{
            color: ColorsEnum.GRAY_SCALE
          }}>
            <div className={styles.text} style={{
              color: ColorsEnum.GRAY_BLACK,
              maxWidth: width - 175
            }}>{node.name}</div>
            {infoText.length > 0 ? (<div className={styles.subText} style={{
              maxWidth: width - 175
            }}>{infoText}</div>): null}
          </div>
        </div>
        <div onClick={handleToggle} className={styles.disableProductButton} style={{
          borderColor: ColorsEnum.GRAY_SCALE_95
        }}>
          <DisableIcon fill={node.selected ? ColorsEnum.ERROR_TEXT : ColorsEnum.GRAY_SCALE}/>
          {node.partial ? <div className={styles.dot} style={{
            backgroundColor: ColorsEnum.ERROR_TEXT
          }}/> : null}
        </div>
      </div>
      {node.children ? (
        <div className={styles.bodyContainer} style={{
          borderColor: isExpanded ? ColorsEnum.DARK_20 : 'transparent',
          marginTop: isExpanded ? 8 : 0,
          // maxHeight: isExpanded ? `none` : '0'
        }}>
          <div ref={contentRef} className={[styles.body, isExpanded ? styles.open : null].join(' ')}
               style={{maxHeight: isExpanded ? `${height}px` : '0'}}
          >
            {node.children.map(child => {
              return (
                <SubCategoryItem
                  key={child.id}
                  node={child}
                  onNodeToggle={onNodeToggle}
                  onNodeExpand={onNodeExpand}
                  expandedNodeIds={expandedNodeIds}
                  onChangeHeight={() => toggleAnimationValue(isExpanded)}
                  level={level + 1}
                  width={width}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PrimaryCategoryItem
