import React from "react";
import {IconProps} from "../types/types";

const ArrowLeftIcon: React.FC<IconProps> = ({stroke}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12H21M3 12L10.7143 20M3 12L10.7143 4" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowLeftIcon
