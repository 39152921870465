import {Product} from "./Products";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {defHttp} from "../../api";
import qs from "qs";


interface ProductsInitialState {
  products: Product[];
  selectedProduct: Product | null,
  couponIndex: number
}
const initialState: ProductsInitialState = {
  products: [],
  selectedProduct: null,
  couponIndex: 1
}

export const fetchProducts = createAsyncThunk<
  Product[]
>(
  'products/fetchProducts',
  async () => {
    try {
      const response = await defHttp.get<Product[]>('/api/v2/subscription-products', {
        params: {
          limit: 100
        }
      })
      return response.data.sort((a, b) => a.price - b.price);
    } catch (error) {
      throw error;
    }
  }
)

export const fetchProductsByIds = createAsyncThunk<
  Product[],
  string[]
>(
  'products/fetchProductsByIds',
  async (ids) => {
    try {
      const response = await defHttp.get<Product[]>('/api/v2/subscription-products-by-ids', {
        params: {
          ids
        },
        paramsSerializer: {
          serialize: data => {
            return qs.stringify(data, {arrayFormat: 'repeat'});
          },
        },
      })
      return response.data.sort((a, b) => a.price - b.price);
    } catch (error) {
      throw error;
    }
  }
)

const ProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setCouponIndex: (state, action: {payload: number}) => {
      state.couponIndex = action.payload
    },
    clearProducts: (state) => {
      state.products = []
    },
    setSelectedProduct: (state, {payload}: {payload: Product}) => {
      state.selectedProduct = payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      if ([...action.payload][1]) {
        state.selectedProduct = [...action.payload][1]
        state.couponIndex = 1
      }
    })
    builder.addCase(fetchProductsByIds.pending, (state) => {
      state.products = [];
    });
    builder.addCase(fetchProductsByIds.fulfilled, (state, action) => {
      state.products = action.payload;
      if ([...action.payload][1]) {
        state.selectedProduct = [...action.payload][1]
        state.couponIndex = 1
      }
    })
  }
})

export const {setSelectedProduct, clearProducts, setCouponIndex} = ProductsSlice.actions
export default ProductsSlice.reducer
