import React from "react";
import {IconProps} from "../types/types";

const MentalIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
      <path
        d="M18.9985 19.6729L20.3331 22.5429C20.3331 22.5429 26.3329 23.8169 26.3329 26.8472C26.3329 29.0001 23.6636 29.0001 23.6636 29.0001H16.5547L13.0547 27.0556"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9985 19.6729L9.66539 22.5429C9.66539 22.5429 3.66406 23.8169 3.66406 26.8472C3.66406 29.0001 6.3334 29.0001 6.3334 29.0001H9.5534L13.0534 27.0556L17.3312 24.3334"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 21.1071C1 21.1071 4.33356 20.39 6.334 19.6729C8.33289 9.62711 14.3311 10.3442 15 10.3442C15.6673 10.3442 21.6671 9.62711 23.666 19.6729C25.6664 20.3884 29 21.1071 29 21.1071M15 7.22222C15.8251 7.22222 16.6164 6.89445 17.1999 6.311C17.7833 5.72755 18.1111 4.93623 18.1111 4.11111C18.1111 3.28599 17.7833 2.49467 17.1999 1.91122C16.6164 1.32778 15.8251 1 15 1C14.1749 1 13.3836 1.32778 12.8001 1.91122C12.2167 2.49467 11.8889 3.28599 11.8889 4.11111C11.8889 4.93623 12.2167 5.72755 12.8001 6.311C13.3836 6.89445 14.1749 7.22222 15 7.22222Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MentalIcon
