import {IconProps} from "../types/types";
import React from "react";

const OlimpIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 21" fill="none">
      <path
        d="M10.3333 5.66667C10.3333 6.90434 9.84167 8.09133 8.9665 8.9665C8.09133 9.84167 6.90434 10.3333 5.66667 10.3333C4.42899 10.3333 3.242 9.84167 2.36684 8.9665C1.49167 8.09133 1 6.90434 1 5.66667C1 4.42899 1.49167 3.242 2.36684 2.36684C3.242 1.49167 4.42899 1 5.66667 1C6.90434 1 8.09133 1.49167 8.9665 2.36684C9.84167 3.242 10.3333 4.42899 10.3333 5.66667ZM10.3333 5.66667C10.3333 6.90434 10.825 8.09133 11.7002 8.9665C12.5753 9.84167 13.7623 10.3333 15 10.3333C16.2377 10.3333 17.4247 9.84167 18.2998 8.9665C19.175 8.09133 19.6667 6.90434 19.6667 5.66667M10.3333 5.66667C10.3333 4.42899 10.825 3.242 11.7002 2.36684C12.5753 1.49167 13.7623 1 15 1C16.2377 1 17.4247 1.49167 18.2998 2.36684C19.175 3.242 19.6667 4.42899 19.6667 5.66667M19.6667 5.66667C19.6667 6.90434 20.1583 8.09133 21.0335 8.9665C21.9087 9.84167 23.0957 10.3333 24.3333 10.3333C25.571 10.3333 26.758 9.84167 27.6332 8.9665C28.5083 8.09133 29 6.90434 29 5.66667C29 4.42899 28.5083 3.242 27.6332 2.36684C26.758 1.49167 25.571 1 24.3333 1C23.0957 1 21.9087 1.49167 21.0335 2.36684C20.1583 3.242 19.6667 4.42899 19.6667 5.66667ZM15 15C15 16.2377 14.5083 17.4247 13.6332 18.2998C12.758 19.175 11.571 19.6667 10.3333 19.6667C9.09566 19.6667 7.90867 19.175 7.0335 18.2998C6.15833 17.4247 5.66667 16.2377 5.66667 15C5.66667 13.7623 6.15833 12.5753 7.0335 11.7002C7.90867 10.825 9.09566 10.3333 10.3333 10.3333C11.571 10.3333 12.758 10.825 13.6332 11.7002C14.5083 12.5753 15 13.7623 15 15ZM15 15C15 16.2377 15.4917 17.4247 16.3668 18.2998C17.242 19.175 18.429 19.6667 19.6667 19.6667C20.9043 19.6667 22.0913 19.175 22.9665 18.2998C23.8417 17.4247 24.3333 16.2377 24.3333 15C24.3333 13.7623 23.8417 12.5753 22.9665 11.7002C22.0913 10.825 20.9043 10.3333 19.6667 10.3333C18.429 10.3333 17.242 10.825 16.3668 11.7002C15.4917 12.5753 15 13.7623 15 15Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OlimpIcon
