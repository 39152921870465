import {
  BrowserRouter,
} from "react-router-dom";
import store from "./store/store";
import {Provider} from "react-redux";
import './App.css'
import NavigationRoutes from "./routes";
import {useEffect} from "react";
import Facebook from "./helper/FacebookPixel";
import mixpanel from "mixpanel-browser";
import {ScreenHeightProvider} from "./context/ScreenHeightContext";

function App() {
  useEffect(() => {
    document.title = 'Eart: Onboarding';
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: false, ignore_dnt: true, persistence: 'localStorage'});
      mixpanel.track('web_get_started')
    }
  }, []);

  return (
    <Provider store={store}>
      <Facebook />
      <ScreenHeightProvider>
        <BrowserRouter>
          <NavigationRoutes />
        </BrowserRouter>
      </ScreenHeightProvider>
    </Provider>
  );
}

export default App;
