import React, {useEffect, useRef, useState} from "react";
import {setGender} from "../../../store/OnBoarding/OnBoardingSlice";
import {Genders, GendersList} from "../../../enums/onBoardingEnum";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {useHistory} from "react-router-dom";
import styles from './Gender.module.css'
import StepInfo from "../StepInfo";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";

const Gender: React.FC = () => {
  const history = useHistory()
  const [selectedGender, setSelectedGender] = useState('');
  const {gender} = useAppSelector(state => state.onBoarding);
  const dispatch = useAppDispatch();
  const onPress = (selected: Genders) => {
      dispatch(setGender(selected));
      const eventParams = {
        gender: selected,
      };
      mixpanel.track('gender_selected', eventParams);
      logEvent(analitics, 'gender_selected', eventParams);
      setSelectedGender(selected);
      setTimeout(() => nextPage(), 200);
  };

  function nextPage() {
    history.push('/onboarding/age');
  }

  useEffect(() => {
    if (gender) {
      setSelectedGender(gender)
    }
  }, [gender]);

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement}>
      <StepInfo
        title="Gender"
        description="What is your gender? We'll use this information to calculate your
          daily energy needs"
      />
      <div className={styles.genderContainer}>
        {GendersList.map((el, index) => {
          return (
            <div className={[styles.genderItem, el === selectedGender ? styles.selected : ''].join(' ')} onClick={() => onPress(el)}
                 key={index}>
              <div className={styles.text}>{el}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Gender;
