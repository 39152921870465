import React from "react";
import styles from './Chip.module.css'
import {ColorsEnum} from "../../../../enums/ColorsEnum";

interface ChipProps {
  text: string;
  image?: string;
  color?: string;
  textColor?: string;
  borderColor?: string;
  onPress: (value: string) => void;
  selected?: boolean;
  active?: boolean;
}

const Chip: React.FC<ChipProps> = ({
  text,
  image,
  color,
  textColor,
  borderColor,
  onPress,
  selected,
  active
}) => {
  if (image) {
  }
  if (selected) {
    return null;
  }
  return (
    <div
      className={styles.chip}
      style={{
        backgroundColor: color || 'transparent',
        borderColor: borderColor || color || ColorsEnum.GRAY_SCALE_90,
      }}
      onClick={() => onPress(text)}
    >
      <div className={styles.chipContainer}>
        {
          image
          ? (
            <img className={styles.image} src={image} alt={text}/>
          )
          : null
        }
      </div>
      <div className={styles.text} style={{
        color: textColor || ColorsEnum.GRAY_BLACK
      }}>
        {text}
      </div>
      {active ? <div
        style={{width: 16, height: 16, marginLeft: 4}}
      >
        <img src={require('../../../../assets/images/close.png')} alt="close"/>
      </div> : null}
    </div>
  )
}

export default Chip
