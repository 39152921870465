import React, {useEffect} from "react";
import styles from './Complete.module.css'
import ReactPixel from "react-facebook-pixel";
import mixpanel from "mixpanel-browser";
import {useHistory} from "react-router-dom";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../helper/Firebase";

const Complete: React.FC = () => {
  const history = useHistory();

  const downloadApp = () => {
    const link = process.env.REACT_APP_APP_STORE_LINK;
    if (link) {
      ReactPixel.track('AppDownloadClicked')
      mixpanel.track('AppDownloadClicked')
      logEvent(analitics, 'AppDownloadClicked');
      window.open(link, '_blank');
    }
  }

  useEffect(() => {
    const isAppleDevice = /(iPhone|iPad|iPod|Macintosh)/.test(window.navigator.userAgent);
    const isAppleMobile = /(iPhone|iPad|iPod)/.test(window.navigator.userAgent);
    localStorage.setItem('isVisited', 'false');
    // mixpanel.reset();
    if (isAppleDevice) {
      setTimeout(() => {
        const link = process.env.REACT_APP_APP_STORE_LINK;
        if (link) {
          if (isAppleMobile) {
            window.location.href = link
          } else {
            window.open(link, '_blank');
          }
        }
      }, 2000)
    }
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.rightCol}>
              <div className={styles.imageContainer}>
                <a className={styles.logo} href="/">
                  <img className={styles.logo} src={require('../../assets/images/logo.png')} alt=""/>
                </a>
              </div>
              <div className={styles.textInfo}>
                <div className={styles.text}>
                  <h1 className={styles.title}>Thank you!</h1>
                  <h2 className={styles.description}>All your information has been saved</h2>
                  <p className={styles.styledText}>Discover personalized meal plans with delicious recipes in Eatr app</p>
                </div>
                <div className={styles.download}>
                  <div className={styles.appstore}>
                    <span className={styles.styledDownloadText}>Download for free</span>
                    <div onClick={() => downloadApp()}>
                      <img style={{
                        width: 168,
                        height: 'auto',
                        cursor: 'pointer'
                      }} src={require('../../assets/images/home/appstore.png')} alt=""/>
                    </div>
                  </div>
                  <div
                    onClick={() => downloadApp()}>
                    <img style={{
                      cursor: 'pointer'
                    }} src={require('../../assets/images/home/QR-code.png')} alt=""/>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.imgs}>
              <div className={[styles.images, styles.relative].join(' ')}>
                <img className={styles.phones} src={require('../../assets/images/home/complete.png')} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Complete
