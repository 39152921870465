import React, {useEffect} from "react";
import styles from './Home.module.css'
import {useHistory} from "react-router-dom";
import {useAppDispatch} from "../../store/types";
import {setClearOnboarding, setRouteName} from "../../store/OnBoarding/OnBoardingSlice";
import {OnBoardingRoutesEnum} from "../../enums/onBoardingEnum";
import mixpanel from "mixpanel-browser";
import {clearProducts} from "../../store/Products/ProductsSlice";
import Helmet from "react-helmet";
import {useScreenHeight} from "../../context/ScreenHeightContext";

const Home: React.FC = () => {
  const history = useHistory();
  const {screenHeight, isWebView} = useScreenHeight()
  const dispatch = useAppDispatch();
  const start = () => {
    dispatch(setClearOnboarding())
    dispatch(clearProducts())
    mixpanel.reset();
    mixpanel.track('web_survey_opened')
    localStorage.setItem('isVisited', 'true');
    history.push(OnBoardingRoutesEnum.WELCOME_PAGE)
  }
  useEffect(() => {
    dispatch(setRouteName('home'))
  }, []);
  return (
    <div className={styles.page} style={{
      // height: screenHeight ? screenHeight - 75 : screenHeight,
      // minHeight: window.screen.width <= 1024 ? (isWebView ? screenHeight - 75 : screenHeight) : 'auto',
      // maxHeight: window.screen.width <= 1024 ? (isWebView ? screenHeight - 75 : screenHeight) : 'auto',
    }}>
      <Helmet>
        <link rel="preload" as="image" href={require('../../assets/images/welcome-image.webp')}/>
        <link rel="preload" as="image" href={require('../../assets/images/home/appstore.png')}/>
        <link rel="preload" as="image" href={require('../../assets/images/home/QR-code.png')}/>
        <link rel="preload" as="image" href={require('../../assets/images/home/complete.png')}/>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.rightCol}>
              <div className={styles.imageContainer}>
                <a className={styles.logo} href="/">
                  <img className={styles.logo} src={require('../../assets/images/logo.png')} alt=""/>
                </a>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textList}>
                  <div className={styles.text}>
                    <p className={styles.styledText}>Customize your <span className={styles.varOne}>meals</span></p>
                    <p className={styles.styledText}>to <span className={styles.varTwo}>match</span> your <span
                      className={styles.underline}>goals</span></p>
                    <p className={styles.styledText}>and <span className={styles.underline}>preferences</span></p>
                  </div>
                  <div className={styles.mobileText}>
                    <p className={[styles.styledText, styles.p1].join(' ')}>
                      <p className={styles.styledText}>Customize</p>
                      <p className={styles.styledText}>your <span className={styles.varOne}>meals</span></p>
                      <p className={styles.styledText}>to <span className={styles.varTwo}>match</span></p>
                      <p className={styles.styledText}>your goals and</p>
                      <p className={styles.styledText}><span className={styles.underline}>preferences</span></p>
                    </p>
                  </div>
                </div>
                <div onClick={start} className={styles.nextButton} style={{
                  // bottom: window.screen.width <= 1024 ? isWebView ? 92 : 24 : 24
                }}>
                  Get started
                </div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.imgs}>
              <div className={[styles.images, styles.relative].join(' ')}>
                <img className={styles.phones} src={require('../../assets/images/home/phones.png')} alt=""/>
                <div className={[styles.loseWeight, styles.iconContainer].join(' ')}>
                  <img src={require('../../assets/images/home/lose-weight-people.png')} alt=""/>
                  <span>Lose weight</span>
                </div>
                <div className={[styles.eat, styles.iconContainer].join(' ')}>
                  <img src={require('../../assets/images/home/eat-people.png')} alt=""/>
                  <span>Eat healthy</span>
                </div>
                <div className={[styles.saveTime, styles.iconContainer].join(' ')}>
                  <img src={require('../../assets/images/home/saveTime-people.png')} alt=""/>
                  <span>Save time</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
