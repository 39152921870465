import React from 'react';
import styles from './SpeedButton.module.css'

interface SpeedButtonProps {
  active: boolean
  Icon: React.FC
  onPress: () => void
}

const SpeedButton: React.FC<SpeedButtonProps> = ({
  active,
  Icon,
  onPress
}) => {
  return (
    <div onClick={onPress} className={[styles.SpeedButton, active ? styles.active : null].join(' ')}>
      <div className={styles.iconContainer}>
        <Icon />
      </div>
    </div>
  )
}

export default SpeedButton
