import React from "react";
import {IconProps} from "../types/types";
const GradualSpeedIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg viewBox="0 0 69 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3011_34002)">
        <path
          d="M44.7166 3.11303C43.9476 1.77558 42.018 1.77552 41.249 3.11292L18.3047 43.0137H67.6575L44.7166 3.11303Z"
          fill="#E6E7E6"
        />
        <path
          d="M43.8651 2.49021C43.4907 1.8326 42.5498 1.81301 42.1483 2.45446L37.5203 9.84942C37.1035 10.5154 37.5823 11.3799 38.368 11.3799H43.0387H47.2061C47.9732 11.3799 48.4547 10.5518 48.0751 9.88517L43.8651 2.49021Z"
          fill="white"
        />
        <path
          d="M67.6587 43.0137L43.8509 1.60531C43.4664 0.936588 42.5016 0.936558 42.1171 1.60526L22.6484 35.4618"
          stroke="#B5B6B5"
          strokeWidth="1.5"
          strokeMiterlimit="9.57"
          strokeLinecap="round"
        />
        <path
          d="M21.3389 8.22192L1.33594 43.0134H21.3407H41.3454L21.3389 8.22192Z"
          fill="#F3F3F3"
        />
        <path
          d="M22.452 9.78328C22.0803 9.11169 21.1218 9.092 20.7228 9.74776L16.8778 16.067C16.4724 16.7334 16.9521 17.5868 17.7321 17.5868H21.6198H25.0748C25.8366 17.5868 26.3187 16.7691 25.9498 16.1026L22.452 9.78328Z"
          fill="white"
          stroke="#9D9E9C"
          strokeWidth="2"
          strokeMiterlimit="9.57"
        />
        <path
          d="M1.33594 43.0134L20.4832 9.71028C20.8652 9.04587 21.8219 9.04047 22.2113 9.70053L34.4948 30.5179"
          stroke="#B5B6B5"
          strokeWidth="1.5"
          strokeMiterlimit="9.57"
          strokeLinecap="round"
        />
        <path
          d="M11.5833 58L14.8333 53.5556M21.3333 58L19.1667 53.5556L15.9167 50.2222L17 43.5556M17 43.5556L12.6667 44.6667L10.5 48M17 43.5556L20.25 46.8889L23.5 48M17 39.1111C17 39.4058 17.1141 39.6884 17.3173 39.8968C17.5205 40.1052 17.796 40.2222 18.0833 40.2222C18.3707 40.2222 18.6462 40.1052 18.8494 39.8968C19.0525 39.6884 19.1667 39.4058 19.1667 39.1111C19.1667 38.8164 19.0525 38.5338 18.8494 38.3254C18.6462 38.1171 18.3707 38 18.0833 38C17.796 38 17.5205 38.1171 17.3173 38.3254C17.1141 38.5338 17 38.8164 17 39.1111Z"
          stroke="#9D9E9C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.0616 58.9804C61.0668 58.9804 63.5 56.3623 63.5 53.1287C63.5 49.895 61.0668 47.2769 58.0616 47.2769H31.5934C28.5882 47.2769 26.1459 44.6587 26.1459 41.4251C26.1459 39.8132 26.7542 38.3478 27.7438 37.2829C28.7335 36.2181 30.0953 35.5636 31.5934 35.5636H47.1667C48.6647 35.5636 50.0266 34.909 51.0162 33.854C51.9968 32.7891 52.6051 31.3335 52.6051 29.7118C52.6051 26.4782 50.1719 23.86 47.1667 23.86H42.4092C39.404 23.86 36.9617 21.2321 36.9617 17.9985C36.9617 16.3865 37.57 14.9212 38.5597 13.8563C39.1498 13.2213 39.8852 12.7328 40.6933 12.4495C40.9929 12.3421 41.3016 12.2541 41.6193 12.2151C47.1667 11 43 3.99994 43 1.49994M6.5 59H58.7244"
          stroke="#8DCC6F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3011_34002">
          <rect
            width="68"
            height="60"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GradualSpeedIcon
