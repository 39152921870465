import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {useHistory} from "react-router-dom";
import styles from './TargetWeight.module.css'
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import NumberField from "../../NumberField/NumberField";
import {setTargetWeight, setTargetWeightImperial} from "../../../store/OnBoarding/OnBoardingSlice";
import mixpanel from "mixpanel-browser";
import ChangeMetricButton from "../../ChangeMetricButton/ChangeMetricButton";
import {lb, useConvert} from "../../../hooks/useConvert";
import {MayorSystem} from "../../../store/Options/OptionsSlice";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";

const TargetWeight: React.FC = () => {
  const history = useHistory()
  const {mayorSystem} = useAppSelector(state => state.settings);
  const [onBoardingTargetWeight, setOnBoardingTargetWeight] =
    useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const {weight, targetWeight, imperialTargetWeight} = useAppSelector(state => state.onBoarding);
  const dispatch = useAppDispatch();
  const [onBoardingImperialTargetWeight, setOnBoardingImperialTargetWeight] =
    useState<string>('');
  const [errorImperial, setErrorImperial] = useState('');
  const {lbToKg} = useConvert();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const maxLength = e.target.maxLength;
    if (e.target.value.length <= maxLength) {
      let value = e.target.value.slice(0, 3); // Truncate to 3 characters
      setOnBoardingTargetWeight(value);
      setErrorText('')
      setErrorImperial('')
      const gInLb = Math.round((Number(value) * 1000) / lb);
      setOnBoardingImperialTargetWeight(gInLb > 0 ? gInLb.toString() : '0');
    }
  };

  const onChangeImperialWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const maxLength = e.target.maxLength;
    if (e.target.value.length <= maxLength) {
      let value = e.target.value.slice(0, 3); // Truncate to 3 characters
      setErrorText('')
      setErrorImperial('')
      setOnBoardingImperialTargetWeight(value)
      const gInLb = lbToKg(Number(value));
      setOnBoardingTargetWeight(Math.round(gInLb) > 0 ? Math.round(gInLb).toString() : '0');
    }
  };

  const onPressNext = () => {
    const valid = validate(onBoardingTargetWeight);
    const validImperial = validateWeightImperial(onBoardingImperialTargetWeight);
    if (typeof valid !== 'string' && typeof validImperial !== 'string') {
      dispatch(setTargetWeight(Number(onBoardingTargetWeight)));
      dispatch(setTargetWeightImperial(Number(onBoardingImperialTargetWeight?.replace(',', '.'))))
      const eventParams = {
        current_weight:
          mayorSystem === MayorSystem.METRIC
            ? onBoardingTargetWeight
            : onBoardingImperialTargetWeight,
        weight_metric: onBoardingTargetWeight,
        unit: mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb',
      };
      mixpanel.track('target_weight_input_submitted', eventParams);
      logEvent(analitics, 'target_weight_input_submitted', eventParams);
      if (weight && weight > Number(onBoardingTargetWeight)) {
        history.push(OnBoardingRoutesEnum.MESSAGE_PAGE);
      } else {
        history.push(OnBoardingRoutesEnum.ACTIVITY);
      }
      setErrorText('');
    } else {
      if (typeof valid === 'string' && typeof validImperial === 'string') {
        setErrorText(valid);
        setErrorImperial(validImperial);
      }
      dispatch(setTargetWeight(null));
    }
  }

  function validate(weight: string): boolean | string {
    if (weight.length === 0) {
      return 'Please enter your weight.';
    }
    if (Math.floor(Number(weight)) < 25) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight less than 25 kg.';
    }
    if (Math.floor(Number(weight)) > 400) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight over 400 kg.';
    }
    return true;
  }

  function validateWeightImperial(value: string): boolean | string {
    const gInLb = lbToKg(Number(value));
    if (value.length === 0) {
      return 'Please enter your height.';
    }
    if (Math.round(gInLb) < 25) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight less than 55 Lb.';
    }
    if (Math.round(gInLb) > 400) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight over 882 Lb.';
    }
    return true;
  }

  useEffect(() => {
    if (targetWeight) {
      setOnBoardingTargetWeight(targetWeight.toString());
    }
    if (imperialTargetWeight) {
      setOnBoardingImperialTargetWeight(imperialTargetWeight.toString())
    }
  }, [targetWeight, imperialTargetWeight]);

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
      <div className={styles.containerData}>
        <StepInfo title={"Goal weight"} description={"What is your goal weight?"}/>
        <div className={styles.listContainer}>
          <div className={styles.inputContainer}>
            {
              mayorSystem === MayorSystem.METRIC ? (
                <NumberField
                  value={onBoardingTargetWeight}
                  label={"kg"}
                  onChange={onChange}
                  maxLength={3}
                  autoFocus={true}
                  showError={false}
                  error={errorText}
                  onPressEnter={onPressNext}
                />
              ) : (
                <NumberField
                  value={onBoardingImperialTargetWeight}
                  label={"lb"}
                  onChange={onChangeImperialWeight}
                  maxLength={3}
                  autoFocus={true}
                  showError={false}
                  error={errorText}
                  onPressEnter={onPressNext}
                />
              )
            }
          </div>
          {mayorSystem === MayorSystem.METRIC ? (
            <div style={{
              paddingLeft: 24,
              paddingRight: 24,
              textAlign: 'center',
              color: ColorsEnum.ERROR_TEXT,
              fontFamily: 'Nunito Sans, sans-serif',
            }}>
              {errorText}
            </div>
          ) : (
            <div style={{
              paddingLeft: 24,
              paddingRight: 24,
              textAlign: 'center',
              color: ColorsEnum.ERROR_TEXT,
              fontFamily: 'Nunito Sans, sans-serif',
            }}>
              {errorImperial}
            </div>
          )}
          <ChangeMetricButton metricLabel={'Kg'} imperialLabel={'lbs'}/>
        </div>
      </div>
      <NextButton fixed={true} disabled={onBoardingTargetWeight.length === 0 || errorText.length > 0 || errorImperial.length > 0}
                  onClick={onPressNext}/>
    </div>
  )
}

export default TargetWeight;
