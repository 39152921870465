import {IconProps} from "../types/types";
import React from "react";

const MealPlanAllMealIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5588 17.3704C28.8466 16.2955 29 15.1657 29 14C29 6.8203 23.1797 1 16 1C8.8203 1 3 6.8203 3 14C3 15.1657 3.15342 16.2955 3.44116 17.3704"
        stroke="#8DCC6F"
        strokeWidth="1.5"
      />
      <path
        d="M6 14C6 8.47715 10.4772 4 16 4C21.5228 4 26 8.47715 26 14C26 15.1688 25.7995 16.2907 25.431 17.3333"
        stroke="#8DCC6F"
        strokeWidth="1.5"
      />
      <path
        d="M16 8C16.0812 8 16.1619 8.00161 16.2423 8.0048M19.1704 8.90505C19.6549 9.20718 20.0932 9.57651 20.4722 10C21.4223 11.0615 22 12.4633 22 14C22 14.7013 21.8797 15.3744 21.6586 16"
        stroke="#8DCC6F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13 18H16H29C29.2652 18 29.5196 18.1054 29.7071 18.2929C29.8946 18.4804 30 18.7348 30 19V19.5C30 21 27.483 25.073 26 26V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28H17C16.7348 28 16.4804 27.8946 16.2929 27.7071C16.1054 27.5196 16 27.2652 16 27V26C14.313 24.946 12 21 12 19.5V19C12 18.7348 12.1054 18.4804 12.2929 18.2929C12.4804 18.1054 12.7348 18 13 18Z"
        stroke="#8DCC6F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 18V15C16 14.4477 15.5523 14 15 14H6C5.44771 14 5 14.4477 5 15V25C5 26.6569 6.34315 28 8 28H13C14.6569 28 16 26.6569 16 25V25"
        stroke="#8DCC6F"
        strokeWidth="1.5"
      />
      <path
        d="M5 18H3C2.44772 18 2 18.4477 2 19V23C2 23.5523 2.44772 24 3 24H5V18Z"
        stroke="#8DCC6F"
        strokeWidth="1.5"
      />
    </svg>

  )
}

export default MealPlanAllMealIcon
