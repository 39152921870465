import React, {useMemo} from 'react';
import styles from './PaymentInformation.module.css';
import {PRIVACY_LINK, TERMS_LINK} from "../../OnBoarding/Email/Email";
import {useAppSelector} from "../../../store/types";
import moment from "moment";
import {useSubscriptionData} from "../../../hooks/useSubscriptionData";

interface PaymentInformationProps {
  price: number;
}

const PaymentInformation: React.FC<PaymentInformationProps> = ({price}) => {
  const {selectedProduct, couponIndex} = useAppSelector(state => state.products);
  const {couponsList} = useSubscriptionData()
  function getCurrencySymbol(currencyCode: string): string {
    return (0).toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).replace(/\d/g, '').trim();
  }
  const date = useMemo(() => {
    if (!selectedProduct)
    return ''
    const currentDate = moment();
    let interval = 7
    let type: 'days' | 'month' | 'months' = 'days';
    if (couponIndex === 1) {
      interval = 1;
      type = 'month'
    }
    if (couponIndex === 2) {
      interval = 3;
      type = 'months'
    }
    currentDate.add(interval, type)
    return currentDate.format('MMM DD, YYYY hh:mm A')
  }, [couponIndex])
  if (!selectedProduct) return null;
  return (
    <div className={styles.container}>
      <ul>
        <li>
          You will only be charged {getCurrencySymbol(selectedProduct?.currency || 'usd')}{(selectedProduct.price - (couponsList[couponIndex || 1].price || 0)).toFixed(2)} today for your first month (details below)
        </li>
        <li>
          Your introductory period will last until {date}.
        </li>
        <li>
          You may cancel at any time before {date}, and you will not be charged.
        </li>

        <li>
          If you don't cancel, Eater will automatically continue your subscription and
          charge {getCurrencySymbol(selectedProduct?.currency || 'usd')}{selectedProduct?.price}/{selectedProduct?.interval} until
          you
          cancel
        </li>
        <li>
          No partial refunds. You can cancel your subscription anytime on your Subscription Management page.
        </li>
        <li>
          Your subscription will be bound by our <a href={TERMS_LINK} target="_blank" rel="noreferrer">Terms of
          Service</a> and <a href={PRIVACY_LINK} target="_blank" rel="noreferrer">Privacy Policy</a>
        </li>
      </ul>
    </div>
  )
}

export default PaymentInformation
