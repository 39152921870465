import {TreeNodeType} from "../types/onBoardingTypes";

export function useProductsTree(){
  const selectNode = (node: TreeNodeType, isSelected: boolean): TreeNodeType => {
    node.selected = isSelected;
    node.partial = false;
    if (node.children) {
      node.children = node.children.map(child =>
        selectNode({...child}, isSelected),
      );
    }
    return node;
  };

  const updateNodeSelection = (
    node: TreeNodeType,
    nodeId: number,
    isSelected: boolean,
  ): TreeNodeType => {
    if (node.id === nodeId) {
      return selectNode({...node}, isSelected);
    } else if (node.children && node.children.length > 0) {
      node.children = node.children.map(child =>
        updateNodeSelection(child, nodeId, isSelected),
      );
      const allChildrenSelected = node.children.every(child => child.selected);
      const anyChildSelected = node.children.some(
        child => child.selected || child.partial,
      );
      node.selected = allChildrenSelected;
      node.partial = !allChildrenSelected && anyChildSelected;
    }
    return node;
  };

  return {
    updateNodeSelection,
  }
}
