import React, {useEffect, useRef, useState} from "react";
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import styles from './Email.module.css'
import ReactPixel from "react-facebook-pixel";
import mixpanel from "mixpanel-browser";
import {useAppDispatch} from "../../../store/types";
import {authSetEmail} from "../../../store/UserSlice/UserSlice";
import {saveOnBoardingData} from "../../../store/OnBoarding/OnBoardingSlice";
import {useHistory} from "react-router-dom";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {clearState} from "../../../store/Subscription/SubscriptionSlice";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

export const PRIVACY_LINK = 'https://eatr.com/privacy';
export const TERMS_LINK = 'https://eatr.com/terms_of_service';


const Email: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch();
  const [userEmail, setUserEmail] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  function onChangeText(e: React.ChangeEvent<HTMLInputElement>) {
    setError('')
    const value = e.target.value.toLowerCase();
    const filteredValue = value.replace(/[^a-zA-Z0-9._@+-]/g, '');
    setUserEmail(filteredValue)
  }

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function onClickNext(){
    const id = window.crypto.randomUUID ? window.crypto.randomUUID() : new Date().getTime().toString();
    const validEmail = validateEmail(userEmail)
    if (validEmail !== null) {
      setLoader(true)
      dispatch(authSetEmail(userEmail))
      ReactPixel.track('OnboardingFinished')
      mixpanel.track('OnboardingFinished')
      logEvent(analitics, 'OnboardingFinished')
      dispatch(saveOnBoardingData(id)).then(redux => {
        if (redux.meta.requestStatus === 'rejected') {
          setError('A user with this mail already exists')
          setLoader(false)
        }
        if (redux.meta.requestStatus === 'fulfilled') {
          // dispatch(setClearOnboarding())
          ReactPixel.track('SuccessfulLogin')
          mixpanel.track('SuccessfulLogin')
          logEvent(analitics, 'SuccessfulLogin')
          history.push(OnBoardingRoutesEnum.LOADER)
          setLoader(false)
        }
      })
    } else {
      setError('Please enter a valid email.')
    }
  }

  const onClose = () => {
    if (!error) {
      dispatch(clearState())
      history.push('/onboarding/payment')
    }
    setLoader(false)
  }

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      minHeight: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 60 : 'calc(100dvh - 60px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <StepInfo title={'Almost ready'}
                  description={'Enter your email to save your goals and preferences'}/>
        <div className={styles.listContainer}>
          <div className={styles.listData}>
            <input ref={inputRef} className={styles.inputField}
                   style={{
                     backgroundColor: error.length > 0 ? ColorsEnum.ERROR_BG : ColorsEnum.NORMAL_80,
                     borderColor: error.length > 0 ? ColorsEnum.ERROR_TEXT + ' !important' : 'transparent'
                   }}
                   type="text" onChange={onChangeText} value={userEmail} placeholder="Email"/>
          </div>
          <div className={styles.error}>{error}</div>
          <div style={{
            paddingTop: 12,
            paddingBottom: 20
          }}>
            <div className={styles.info}>
              By submitting your email address, you accept our
            </div>
            <div className={styles.info}>
              <a href={TERMS_LINK} target="_blank" rel="noreferrer">Terms of Service</a> and <a href={PRIVACY_LINK}
                                                                                                target="_blank"
                                                                                                rel="noreferrer">Privacy
              Policy</a>
            </div>
          </div>
        </div>
      </div>
      <NextButton loading={loader} onClick={onClickNext} text={'Finish'}
                  disabled={userEmail.length === 0 || error.length > 0}/>
    </div>
  )
}

export default Email
