import React, {useEffect, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {fetchDietsList, selectDietById, setUserDiet} from "../../../store/OnBoarding/OnBoardingSlice";
import PieChartComponent from "./PieChartComponent";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {roundToNearest50, useTDEE} from "../../../hooks/useTDEE";
import NextButton from "../../NextButton/NextButton";
import styles from './SingleDiet.module.css'
import ChartLegendElement from "./ChartLegendElement/ChartLegendElement";
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";
import {getImageLinkWithResize} from "../../../helper/appHelper";
import {ImageSizeEnum} from "../../../hooks/appEnum";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

const SingleDiet: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{id: string}>();
  const {weight, targetWeight, mode} = useAppSelector(
    state => state.onBoarding,
  );
  const diets = useAppSelector(state => state.onBoarding.diets);
  const diet = useAppSelector(state => selectDietById(state, params ? Number(params.id) : 0))
  const {calories, tdee} = useTDEE(mode);
  const history = useHistory()

  useEffect(() => {
    if (diets.length === 0) {
      dispatch(fetchDietsList())
    }
  }, [diets.length, dispatch]);

  const onClickSelect = () => {
    if (diet) {
      dispatch(setUserDiet(diet.name));
      const eventParams = {
        Diet_selected: diet.name,
      };
      mixpanel.track('user_diet_preference_set', eventParams);
      logEvent(analitics, 'user_diet_preference_set', eventParams);
      history.replace(OnBoardingRoutesEnum.PLAN_FEATURES_PAGE)
    }
  }

  const base = window.location.hostname.includes("eatr.com") ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_DEV
  const prefix = !window.location.hostname.includes("eatr.com") ? 'dev-' : ''

  const {screenHeight, isWebView} = useScreenHeight()


  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);

  if (!diet) {
    return null;
  }
  const data = [
    {name: 'Group A', value: diet.proteins, color: ColorsEnum.PROTEINS},
    {name: 'Group B', value: diet.fats, color: ColorsEnum.FATS},
    {name: 'Group C', value: diet.carbohydrates, color: ColorsEnum.CABS},
  ];
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 15 : 'calc(100dvh - 15px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={getImageLinkWithResize(diet.imageUrl, ImageSizeEnum.M)} alt=""/>
          <span className={styles.backButton} onClick={() => history.goBack()}>
            <ArrowLeftIcon stroke={ColorsEnum.GRAY_BLACK}/>
          </span>
        </div>
        <div className={styles.dietContainer}>
          <div className={styles.dietName}>{diet.name} Diet</div>
          <div className={styles.chartData}>
            <div className={styles.chart}>
              <div className={styles.relative}>
                <PieChartComponent data={data}/>
                <div className={styles.chartInfoPosition}>
                <span className={styles.calories}>
                  {weight !== targetWeight
                    ? roundToNearest50(calories)
                    : roundToNearest50(tdee)}
                </span>
                  <span className={styles.desc}>kcal/day</span>
                </div>
              </div>
            </div>
            <div className={styles.chartLegend}>
              <ChartLegendElement
                name={'protein'}
                value={diet.proteins}
                color={ColorsEnum.PROTEINS}
              />
              <ChartLegendElement
                name={'fat'}
                value={diet.fats}
                color={ColorsEnum.FATS}
              />
              <ChartLegendElement
                name={'carb'}
                value={diet.carbohydrates}
                color={ColorsEnum.CABS}
              />
            </div>
          </div>
          <div className={styles.dietDescription}>
            {diet.description}
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} text={'Select'} onClick={onClickSelect}/>
    </div>
  )
}

export default SingleDiet
