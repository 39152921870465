import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from './Subscription.module.css'
import Table from "../Payment/Table";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {useTDEE} from "../../hooks/useTDEE";
import {useAppDispatch, useAppSelector} from "../../store/types";
import {MayorSystem} from "../../store/Options/OptionsSlice";
import BenefitsInformationList from "../Payment/BenefitstInformation/BenefitsInformationList";
import PaymentInformation from "../Payment/PaymentInformation/PaymentInformation";
import {fetchProductsByIds} from "../../store/Products/ProductsSlice";
import MoneyBack from "./MoneyBack/MoneyBack";
import SubInfoBlockWithCounter from "../Payment/SubInfoBlockWithCounter/SubInfoBlockWithCounter";
import SpecialOfferButton from "../Payment/SpecialOfferButton/SpecialOfferButton";
import ProductsList from "../Payment/ProductsList/ProductsList";
import ScrollButton from "../Payment/ScrollButton/ScrollButton";
import PromoCode from "../Payment/PromoCode/PromoCode";
import AppReview from "../Payment/AppReview/AppReview";
import PaymentMethods from "../Payment/PaymentMethods/PaymentMethods";
import {createCustomer} from "../../store/Subscription/SubscriptionSlice";
import {useSubscriptionData} from "../../hooks/useSubscriptionData";

const SubscriptionPage: React.FC = () => {
  const {couponsList, productsIds} = useSubscriptionData()
  const dispatch = useAppDispatch();
  const mayorSystem = useAppSelector(styles => styles.settings.mayorSystem)
  const {selectedProduct, couponIndex} = useAppSelector(state => state.products);
  const {clientSecret} = useAppSelector(state => state.subscription);
  const {mode, targetWeight, weight, imperialTargetWeight} = useAppSelector(state => state.onBoarding)
  const containerRef = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState<number>(300)
  const [price] = useState<number>(2.99)
  const {month} = useTDEE(mode);

  const [stripePromise, setStripePromise] = useState<Stripe | null>(null)

  useEffect(() => {
    if (process.env.REACT_APP_STRIPE_KEY_PROD && process.env.REACT_APP_STRIPE_KEY) {
      const key = window.location.hostname.includes("eatr.com") ? process.env.REACT_APP_STRIPE_KEY_PROD : process.env.REACT_APP_STRIPE_KEY
      if (key) {
        loadStripe(key, {}).then(stripe => {
          setStripePromise(stripe)
        })
      }
    }
  }, []);

  useEffect(() => {
    // dispatch(fetchProducts());
    dispatch(fetchProductsByIds(productsIds))
    dispatch(createCustomer());
    if (containerRef.current) {
      setTableWidth(containerRef.current.clientWidth)
    }
  }, []);
  const paymentRef = useRef<HTMLDivElement>(null);
  const scrollBottom = () => {
    if (paymentRef.current) {
      paymentRef.current.scrollIntoView({behavior: "smooth"});
    }
  }
  const amount = useMemo(() => {
    return selectedProduct && selectedProduct.price > couponsList[couponIndex || 1].price ? (Number((selectedProduct.price - couponsList[couponIndex || 1].price).toFixed(2)) * 100) : 0
  }, [selectedProduct, couponIndex])

  return (
    <div ref={containerRef} className={[styles.goalsContainer, styles.container].join(' ')}>
      <div className={styles.containerData}>
        <div className={styles.content}>
          <div className={styles.contentData}>
            <div className={styles.titleBlock}>
              <div className={styles.title}>
                {weight === targetWeight ? 'Follow your goal' : 'You can reach your goal of'}
              </div>
              <div className={styles.subtitle}>
                {
                  weight === targetWeight ? (
                    <>
                      Eat
                      <span className={styles.varOne} style={{
                        fontWeight: 700
                      }}>
                    tasty
                  </span>
                      and stay
                      <span className={[styles.varTwo, styles.normal].join(' ')}>
                    fit
                  </span>
                    </>
                  ) : (
                    <>
                  <span className={styles.varOne}>
                    {mayorSystem === MayorSystem.METRIC ? `${targetWeight} kg` : `${imperialTargetWeight} lb`}
                  </span>
                      in
                      <span className={styles.varTwo}>
                    {month}
                  </span>
                      months</>
                  )
                }
              </div>
            </div>
            {weight !== targetWeight ? (
              <Table width={tableWidth}/>
            ) : null}
            <BenefitsInformationList/>
            <SubInfoBlockWithCounter/>
            <SpecialOfferButton/>
            <ProductsList/>
            <ScrollButton onClick={scrollBottom}/>
            <PromoCode/>
            <AppReview/>
            <div style={{paddingTop: 20, paddingBottom: 8}}>
              <ScrollButton onClick={scrollBottom}/>
            </div>
            <MoneyBack/>
            <div ref={paymentRef}>
              {
                clientSecret && stripePromise && selectedProduct ? (
                  <Elements stripe={stripePromise} options={{
                    locale: 'en',
                    mode: 'subscription',
                    currency: selectedProduct.currency,
                    amount: selectedProduct.price - (couponsList[couponIndex || 0]?.price || 0) > 0 ? (Number((selectedProduct.price - (couponsList[couponIndex || 0]?.price || 0)).toFixed(2)) * 100) : 0,
                    paymentMethodTypes: ['card'],
                  }}>
                    <PaymentMethods clientSecret={clientSecret}/>
                  </Elements>
                ) : null
              }
            </div>
            <PaymentInformation price={price}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPage
