import {IconProps} from "../types/types";
import React from "react";

const MealPlanDinnerIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="14" stroke="#8DCC6F" strokeWidth="1.5" />
      <circle cx="16" cy="16" r="11" stroke="#8DCC6F" strokeWidth="1.5" />
      <path
        d="M16 9C16.0947 9 16.1889 9.00181 16.2827 9.0054M19.6988 10.0182C20.264 10.3581 20.7754 10.7736 21.2176 11.25C22.326 12.4442 23 14.0212 23 15.75C23 16.5389 22.8596 17.2962 22.6017 18"
        stroke="#8DCC6F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default MealPlanDinnerIcon
