import React from "react";
import styles from './GlobalReview.module.css'
import {ReactComponent as LeafOne} from '../../../../assets/icons/leaf-1.svg'
import {ReactComponent as LeafTwo} from '../../../../assets/icons/leaf-2.svg'
import {ReactComponent as AppleLogo} from '../../../../assets/icons/apple-logo.svg'
import {ReactComponent as Star} from '../../../../assets/icons/star.svg'

const GlobalReview: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.titleBlock}>
        <LeafOne />
        <div className={styles.titleInfo}>
          <div className={styles.title}>Thousands</div>
          <div className={styles.subtitle}>of users improved their health with us</div>
        </div>
        <LeafTwo />
      </div>
      <div className={styles.rating}>
        <div className={styles.starsBlock}>
          <div className={styles.stars}>
            {Array.from(Array(5).keys()).map(el => <Star key={el} />)}
          </div>
          <div className={styles.starsInfo}>
            <span className={styles.starsInfoCurrent}>4.8</span> out of 5
          </div>
        </div>
        <AppleLogo />
      </div>
    </div>
  )
}

export default GlobalReview
