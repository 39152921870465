import React, {useEffect, useRef, useState} from "react";
import {setActivity} from "../../../store/OnBoarding/OnBoardingSlice";
import {Activities, activitiesList, OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import styles from './Activity.module.css'
import StepInfo from "../StepInfo";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {useHistory} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";

const Activity: React.FC = () => {
  const history = useHistory()
  const [selectedActivity, setSelectedActivity] = useState('');
  const {activity, weight, targetWeight} = useAppSelector(state => state.onBoarding);
  const dispatch = useAppDispatch();
  const onPress = (selected: Activities) => {

    dispatch(setActivity(selected));
    const eventParams = {
      activity_level: selected,
    };
    mixpanel.track('activity_level_selected', eventParams);
    logEvent(analitics, 'activity_level_selected', eventParams)
    setSelectedActivity(selected);
    setTimeout(() => nextPage(), 200);
  };

  function nextPage() {
    if (weight !== targetWeight) {
      history.push(OnBoardingRoutesEnum.SPEED);
    } else {
      history.push(OnBoardingRoutesEnum.SUMMARY_PAGE);
    }
  }

  useEffect(() => {
    if (activity) {
      setSelectedActivity(activity)
    }
  }, [activity]);

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
      <div className={styles.containerData}>
      <StepInfo
        title="Activity"
        description="What is your physical activity level?"
      />
      <div className={styles.listContainer}>
        <div className={styles.genderContainer}>
          {activitiesList.map((el, index) => {
            const Icon = el.icon.icon;
            return (
              <div
                className={[styles.activityItemContainer, el.name === selectedActivity ? styles.selected : ''].join(' ')}
                onClick={() => onPress(el.name)}
                key={index}>
                <div className={styles.activityItem}>
                  <div className={styles.iconContainer}>
                    <div style={{
                      marginRight: 8,
                      width: el.icon.width,
                      height: el.icon.height,
                    }}>
                      <Icon fill={ColorsEnum.DARK_20}/>
                    </div>
                  </div>
                  <div className={styles.activityInfo}>
                    <div className={styles.title}>{el.name}</div>
                    <div className={styles.description}>{el.description}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      </div>
    </div>
  )
}

export default Activity;
