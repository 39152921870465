import React from "react";
import styles from './SearchedIngredientItem.module.css'
import {ColorsEnum} from "../../../../enums/ColorsEnum";
import DisableIcon from "../../../../icons/DisableIcon";

interface IngredientItemProps {
  onPress: (name: string) => void;
  selected: boolean;
  name: string
}

const SearchedIngredientItem: React.FC<IngredientItemProps> = ({
  onPress,
  selected,
  name
}) => {
  return (
    <div onClick={() => onPress(name)} className={styles.container}>
      <div className={styles.ingredient}>
        <div className={styles.name}>
          {name}
        </div>
        <DisableIcon fill={selected ? ColorsEnum.ERROR_TEXT : ColorsEnum.GRAY_SCALE}/>
      </div>
    </div>
  )
}

export default SearchedIngredientItem
