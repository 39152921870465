import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from "./MessagePage.module.css";
import NextButton from "../../NextButton/NextButton";
import {useAppSelector} from "../../../store/types";
import {MayorSystem} from "../../../store/Options/OptionsSlice";
import MessageImage from "./MessageImage/MessageImage";
import {useHistory} from "react-router-dom";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

const calculatePercentageLoss = (firstNumber: number, secondNumber: number): number => {
  const difference = firstNumber - secondNumber;
  return Math.round((difference / firstNumber) * 100);
}

const MessagePage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {mayorSystem} = useAppSelector(state => state.settings)
  const {weight, targetWeight, imperialWeight, imperialTargetWeight} = useAppSelector(state => state.onBoarding)
  const difference = useMemo(() => {
    if (mayorSystem === MayorSystem.METRIC) {
      return Number(weight) - Number(targetWeight)
    } else {
      return Number(imperialWeight) - Number(imperialTargetWeight)
    }
  }, [mayorSystem, weight, targetWeight, imperialWeight, imperialTargetWeight]);
  const percent = useMemo(() => calculatePercentageLoss(Number(weight), Number(targetWeight)), [weight, targetWeight]);
  const [imageContainerWidth, setImageContainerWidth] = useState(540)
  const history = useHistory()
  const nextPage = () => {
    history.push(OnBoardingRoutesEnum.ACTIVITY)
  }
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setImageContainerWidth(containerRef.current.clientWidth)
      }
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      setImageContainerWidth(containerRef.current.clientWidth)
    }
  }, [containerRef.current]);

  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 68 : 'calc(100dvh - 68px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.listData}>
          <div ref={containerRef} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            // paddingTop: 100,
            // paddingBottom: 100
          }}>
            <MessageImage containerWidth={imageContainerWidth}/>
          </div>
          <div className={styles.title}>
            {percent < 10 ? (
              <div className={styles.text}>
                Losing <span
                className={styles.value}>{difference} {mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'}</span> is an
                achievable goal. You can do
                it!
              </div>
            ) : (
              <>
                <div className={styles.text}>Losing <span
                  className={styles.value}>{difference} {mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'}</span> is an
                  ambitious goal.
                </div>
                <div className={styles.text}>
                  You've got this!
                </div>
              </>
            )}
          </div>
          <div className={styles.description}>
            91% of users see evident results with Eatr’s plan and find it easy to sustain their progress.
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextPage}/>
    </div>
  )
}

export default MessagePage
