export interface SkillModel {
  name: string;
  description: string;
  activeDescription: string;
  image: string;
  level: SkillLevel;
}

export enum SkillLevel {
  UNKNOWN = 0,
  NOVICE = 1,
  BASIC = 2,
  INTERMEDIATE = 3,
  ADVANCED = 4,
}

export const SkillsList: SkillModel[] = [
  {
    name: 'Novice',
    description: '“I can cook sandwich”',
    activeDescription: 'We will start with simple but tasty recipes!',
    image: require('../../../assets/images/skills/skill.webp'),
    level: SkillLevel.NOVICE,
  },
  {
    name: 'Basic',
    description: '“I cook only simple recipes”',
    activeDescription: 'Nice to go! Our users develop their skills rapidly',
    image: require('../../../assets/images/skills/skill-1.webp'),
    level: SkillLevel.BASIC,
  },
  {
    name: 'Intermediate',
    description: '“I regularly try new recipes”',
    activeDescription:
      'Nicely skilled! We could suggest you something original',
    image: require('../../../assets/images/skills/skill-2.webp'),
    level: SkillLevel.INTERMEDIATE,
  },
  {
    name: 'Advanced',
    description: '“I can cook any recipe”',
    activeDescription: 'We can offer you something unusual and sophisticated!',
    image: require('../../../assets/images/skills/skill-3.webp'),
    level: SkillLevel.ADVANCED,
  },
];
