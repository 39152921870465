import React from "react";
import styles from './Item.module.css'
import PaymentInformationIcon from "../../../../icons/PaymentInformationIcon";

interface IItemProps {
  title: string;
}

const Item: React.FC<IItemProps> = ({title}) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <PaymentInformationIcon />
      </div>
      <div className={styles.title}>
        {title}
      </div>
    </div>
  )
}

export default Item
