import React, {useEffect, useRef, useState} from "react";
import styles from './EatrUsersPage.module.css'
import NextButton from "../../NextButton/NextButton";
import {useAppSelector} from "../../../store/types";
import {Genders, OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {useHistory} from "react-router-dom";
import {menNotUnder30, menUnder30, womanNotUnder30, womanUnder30} from "./usersList";
import {SwitchTransition, CSSTransition} from "react-transition-group";
import './styles.css'
import {useScreenHeight} from "../../../context/ScreenHeightContext";


const getElemnents = (arr: any[], from: number, count: number) => {
  const shuffled = [...arr]; // Создаем копию массива
  return shuffled.slice(from, count); // Возвращаем 'count' элементов
};

const EatrUsersPage: React.FC = () => {
  const history = useHistory();
  const {gender, age} = useAppSelector(state => state.onBoarding)
  const [page, setPage] = useState(0)
  const [image1, setImage1] = useState<any>()
  const [image2, setImage2] = useState<any>()
  const [image3, setImage3] = useState<any>()
  const [variantsList, setVariantsList] = useState<any[]>([])

  const changePageImage = (newImage: any, index: number) => {
    switch (index) {
      case 0:
        setImage1(newImage)
        break;
      case 1:
        setImage2(newImage)
        break;
      case 2:
        setImage3(newImage)
        break;
    }
  }

  useEffect(() => {
    let variants = [];
    if (gender && age) {
      switch (gender) {
        case Genders.MALE:
          variants = age < 30 ? menNotUnder30 : menUnder30;
          break;
        case Genders.FEMALE:
          variants = age < 30 ? womanNotUnder30 : womanUnder30;
          break;
        default:
          variants = age < 30 ? menNotUnder30 : menUnder30;
      }
      const images = getElemnents(variants, page * 3, 3);
      setVariantsList(variants);
      setImage1(images[0])
      setImage2(images[1])
      setImage3(images[2])
    }
  }, []);

  const changeList = () => {
    if (variantsList.length > 0) {
      let p = page + 1;
      if (p > 4) {
        p = 0
      }
      setPage(p)
      const newImages = getElemnents(variantsList, p * 3, (p * 3) + 3);
      for (let i = 0; i < 3; i++) {
        setTimeout(() => {
          changePageImage(newImages[i], i)
        }, ((1 + i) * 2500))
      }
    }
  }

  useEffect(() => {
    if (variantsList) {
      changeList()
    }
  }, [variantsList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (variantsList.length > 0) {
        let p = page + 1;
        if (p > 4) {
          p = 0
        }
        setPage(p)
        const newImages = getElemnents(variantsList, p * 3, (p * 3) + 3);
        for (let i = 0; i < 3; i++) {
          setTimeout(() => {
            changePageImage(newImages[i], i)
          }, ((1 + i) * 2500))
        }
      }
    }, 7500)
    return () => clearInterval(interval)
  }, [variantsList, page]);

  const nextPage = () => {
    history.push(OnBoardingRoutesEnum.EMAIL)
  }

  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 60 : 'calc(100dvh - 60px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.listData}>
          <div className={styles.title}>
            Eatr was made for people
            <div>just like you!</div>
          </div>
          <div className={styles.containerEl}>
            <div className={styles.gradContainer}>
              <img className={styles.gradOne} src={require('../../../assets/images/rangeOne.png')} alt=""/>
              <img className={styles.gradTwo} src={require('../../../assets/images/rangeTwo.png')} alt=""/>
              <div className={styles.imagesContainer}>
                <SwitchTransition>
                  <CSSTransition
                    key={image1}
                    timeout={500}
                    classNames="fade">
                    <img className={[styles.image].join(' ')} src={image1} alt="" style={{
                      zIndex: 5,
                      // left: i !== 0 ? -(10 * i) : 0
                      left: 0
                    }}/>
                  </CSSTransition>
                </SwitchTransition>
                <SwitchTransition>
                  <CSSTransition
                    key={image2}
                    timeout={500}
                    classNames="fade">
                    <img className={[styles.image, styles.fadeIn].join(' ')} src={image2} alt="" style={{
                      zIndex: 6,
                      left: -10
                    }}/>
                  </CSSTransition>
                </SwitchTransition>
                <SwitchTransition>
                  <CSSTransition
                    key={image3}
                    timeout={500}
                    classNames="fade">
                    <img className={[styles.image, styles.fadeIn].join(' ')} src={image3} alt="" style={{
                      zIndex: 7,
                      left: -20
                    }}/>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </div>
          <div className={styles.text}>
            1,000,000+ Users
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.infoTitle}>
              <span style={{
                color: ColorsEnum.DARK_20
              }}>83%</span> of Eatr users
            </div>
            <div className={styles.infoDescription}>
              state that our plan is easy to follow and helps them stay on track effortlessly.
            </div>
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextPage}/>
    </div>
  )
}

export default EatrUsersPage
