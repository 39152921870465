import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {useHistory} from "react-router-dom";
import styles from './Height.module.css'
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import NumberField from "../../NumberField/NumberField";
import {setHeight, setHeightFtImperial, setHeightInchImperial} from "../../../store/OnBoarding/OnBoardingSlice";
import mixpanel from "mixpanel-browser";
import ChangeMetricButton from "../../ChangeMetricButton/ChangeMetricButton";
import {useConvert} from "../../../hooks/useConvert";
import {MayorSystem} from "../../../store/Options/OptionsSlice";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";

const Height: React.FC = () => {
  const history = useHistory()
  const [onBoardingHeight, setOnBoardingHeight] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const {mayorSystem} = useAppSelector(state => state.settings);
  const {height, imperialHeightFt, imperialHeightInch} = useAppSelector(state => state.onBoarding);
  const dispatch = useAppDispatch();
  const [onBoardingImperialHeightFt, setOnBoardingImperialHeightFt] =
    useState<string>('');
  const [onBoardingImperialHeightIn, setOnBoardingImperialHeightIn] =
    useState<string>('');
  const [errorImperial, setErrorImperial] = useState('');
  const {cmToAmericanUnits, ftToCm} = useConvert();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const maxLength = e.target.maxLength;
    if (e.target.value.length <= maxLength) {
      let value = e.target.value.slice(0, 3); // Truncate to 3 characters
      setOnBoardingHeight(value);
      setErrorText('')
      setErrorImperial('')
      const {feet, inches} = cmToAmericanUnits(Number(value));
      setOnBoardingImperialHeightFt(feet > 0 ? feet.toString() : '0');
      setOnBoardingImperialHeightIn(inches > 0 ? inches.toString() : '0');
    }
  };
  const onChangeHeightFt = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const maxLength = e.target.maxLength;
    if (e.target.value.length <= maxLength) {
      let value = e.target.value.slice(0, 3); // Truncate to 3 characters
      setErrorText('')
      setErrorImperial('')
      setOnBoardingImperialHeightFt(value);
      const inCm = ftToCm(
        Number(value),
        onBoardingImperialHeightIn.length > 0
          ? Number(onBoardingImperialHeightIn)
          : 0,
      );
      setOnBoardingHeight(Math.floor(inCm) > 0 ? Math.floor(inCm).toString() : '0');
    }

  };

  const onChangeHeightInch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (Number(e.target.value) <= 11) {
      let value = e.target.value.slice(0, 3); // Truncate to 3 characters
      setErrorText('')
      setErrorImperial('')
      setOnBoardingImperialHeightIn(value);
      const inCm = ftToCm(
        onBoardingImperialHeightFt.length > 0
          ? Number(onBoardingImperialHeightFt)
          : 0,
        Number(value),
      );
      setOnBoardingHeight(Math.floor(inCm) > 0 ? Math.floor(inCm).toString() : '0');
    }
  };

  const onPressNext = () => {
    const valid = validate(onBoardingHeight);
    const validAgeImperial = validateHeightImperial(
      onBoardingImperialHeightFt,
      onBoardingImperialHeightIn,
    );
    if (typeof valid !== 'string' && typeof validAgeImperial !== 'string') {
      dispatch(setHeight(Number(onBoardingHeight)));
      dispatch(
        setHeightFtImperial(
          Number(onBoardingImperialHeightFt?.replace(',', '.')),
        ),
      );
      dispatch(
        setHeightInchImperial(
          Number(onBoardingImperialHeightIn?.replace(',', '.')),
        ),
      );
      const eventParams = {
        height:
          mayorSystem === MayorSystem.METRIC
            ? onBoardingHeight
            : `${onBoardingImperialHeightFt}ft / ${onBoardingImperialHeightIn}in`,
        height_metric: onBoardingHeight,
        unit: mayorSystem === MayorSystem.METRIC ? 'cm' : 'ft/in',
      };
      mixpanel.track('height_input_submitted', eventParams);
      logEvent(analitics, 'height_input_submitted', eventParams);
      history.push('/onboarding/weight');
      setErrorText('');
      setErrorImperial('');
    } else {
      if (typeof valid === 'string' && typeof validAgeImperial === 'string') {
        setErrorText(valid);
        setErrorImperial(validAgeImperial);
      }
      dispatch(setHeight(null));
    }
  }

  function validate(height: string): boolean | string {
    if (height.length === 0) {
      return 'Please enter your height.';
    }
    if (Number(height) < 40) {
      return 'We cannot calculate the nutritional balance for this height. Height less than 40 cm.';
    }
    if (Number(height) > 280) {
      return 'We cannot calculate the nutritional balance for this height. Height over 280 cm.';
    }
    return true;
  }

  function validateHeightImperial(ft: string, inch: string): boolean | string {
    const hInFt = Math.floor(
      ftToCm(
        ft.length > 0 ? Number(ft) : 0,
        inch.length > 0 ? Number(inch) : 0,
      ),
    );
    if (ft.length === 0 && inch.length === 0) {
      return 'Please enter your height.';
    }
    if (Number(hInFt) < 40) {
      return 'We cannot calculate the nutritional balance for this height. Height must be less than 1 Ft 4 Inch.';
    }
    if (Number(hInFt) > 280) {
      return 'We cannot calculate the nutritional balance for this height. Height must be over 9 ft 2 Inch.';
    }
    return true;
  }

  useEffect(() => {
    if (height && imperialHeightFt && imperialHeightInch) {
      setOnBoardingHeight(height.toString());
      setOnBoardingImperialHeightFt(imperialHeightFt.toString());
      setOnBoardingImperialHeightIn(imperialHeightInch.toString());
    }
  }, [height, imperialHeightFt, imperialHeightInch]);

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
      <div className={styles.containerData}>
        <StepInfo title={"Height"} description={"How tall are you?"}/>
        <div className={styles.inputContainer}>
          {mayorSystem === MayorSystem.METRIC ? (
            <NumberField
              value={onBoardingHeight}
              label={"cm"}
              onChange={onChange}
              maxLength={3}
              showError={false}
              autoFocus={true}
              error={errorText}
              onPressEnter={onPressNext}
            />
          ) : (
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              width: '100%',
              gap: 8
            }}>
              <NumberField
                label={'ft'}
                value={onBoardingImperialHeightFt}
                onChange={onChangeHeightFt}
                maxLength={1}
                showError={false}
                autoFocus={true}
                full={true}
                error={errorImperial}
                onPressEnter={onPressNext}
              />
              <NumberField
                label={'in'}
                value={onBoardingImperialHeightIn}
                onChange={onChangeHeightInch}
                maxLength={2}
                full={true}
                autoFocus={false}
                error={errorImperial}
                showError={false}
                onPressEnter={onPressNext}
              />
            </div>
          )}
        </div>
        {mayorSystem === MayorSystem.METRIC ? (
          <div style={{
            paddingLeft: 24,
            paddingRight: 24,
            textAlign: 'center',
            color: ColorsEnum.ERROR_TEXT,
            fontFamily: 'Nunito Sans, sans-serif',
          }}>
            {errorText}
          </div>
        ) : (
          <div style={{
            paddingLeft: 24,
            paddingRight: 24,
            textAlign: 'center',
            color: ColorsEnum.ERROR_TEXT,
            fontFamily: 'Nunito Sans, sans-serif',
          }}>
            {errorImperial}
          </div>
        )}
        <ChangeMetricButton metricLabel={'Cm'} imperialLabel={'Ft/In'}/>
      </div>
      <NextButton fixed={true} disabled={onBoardingHeight.length === 0 || errorText.length > 0} onClick={onPressNext}/>
    </div>
  )
}

export default Height;
