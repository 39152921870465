import {IconProps} from "../types/types";
import React from "react";

const ExpIcon: React.FC<IconProps> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3803_2620)">
      <circle cx="12" cy="12" r="10" fill="#8DCC6F"/>
      <path
        d="M11.912 15.056V14.592C11.912 14.048 12.0133 13.5413 12.216 13.072C12.4187 12.592 12.776 12.0533 13.288 11.456C13.6933 10.9867 13.976 10.5867 14.136 10.256C14.296 9.92533 14.376 9.57333 14.376 9.2C14.376 8.752 14.216 8.4 13.896 8.144C13.576 7.87733 13.1333 7.744 12.568 7.744C11.9813 7.744 11.4373 7.85067 10.936 8.064C10.4347 8.27733 9.96 8.59733 9.512 9.024L9.032 8C9.448 7.57333 9.976 7.232 10.616 6.976C11.256 6.70933 11.928 6.576 12.632 6.576C13.5493 6.576 14.2907 6.81067 14.856 7.28C15.4213 7.73867 15.704 8.34667 15.704 9.104C15.704 9.60533 15.592 10.0747 15.368 10.512C15.1547 10.9493 14.776 11.456 14.232 12.032C13.7307 12.5653 13.3733 13.0347 13.16 13.44C12.9573 13.8347 12.8293 14.24 12.776 14.656L12.744 15.056H11.912ZM11.512 18V16.368H13.144V18H11.512Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_3803_2620">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default ExpIcon
