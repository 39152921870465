import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {defHttp} from "../../api";
interface SubscriptionPriceInfo {price: number, currency: string, trialPeriodDays: number}

interface SubscriptionResponse {
  subscriptionId: string;
  clientSecret: string;
  client: string;
  items: SubscriptionPriceInfo[]
  subscriptionName: string;
}
interface CustomerResponse extends SubscriptionResponse{
  client_secret: string;
}

interface InitialStateProps {
  subscriptionId: string | null;
  clientSecret: string | null;
  subscriptionName: string | null;
  items: SubscriptionPriceInfo[];
  loading: boolean;
  error: string | null;
}

const initialState: InitialStateProps = {
  subscriptionId: null,
  clientSecret: null,
  subscriptionName: null,
  items: [],
  loading: false,
  error: null
};

export const createSubscription = createAsyncThunk<
  SubscriptionResponse,
  {
    priceId: string;
    paymentMethodId?: string;
    couponId: string;
  }, {
  rejectValue: string
}>(
  'subscription/createSubscription',
  async ({priceId, paymentMethodId, couponId}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await defHttp.post('/api/v2/create-subscription', {
        priceId,
        paymentMethodId,
        couponId
      })
      if (response.status !== 400) {
        return fulfillWithValue(response.data);
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message)
    }
  }
)
export const createCustomer = createAsyncThunk<
  CustomerResponse,
  void,
  {
  rejectValue: string
}>(
  'subscription/createCustomer',
  async (_, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await defHttp.post('/api/v2/create-customer-session', {
        "isBuyButton": true,
        "isPricingTable": false
      })
      if (response.status !== 400) {
        return fulfillWithValue(response.data);
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    clearState: state => {
      state.subscriptionId = null;
      state.clientSecret = null;
      state.subscriptionName = null;
      state.items = []
    }
  },
  extraReducers: builder => {
    builder.addCase(createSubscription.pending, (state) => {
      state.loading = true;
      // state.subscriptionId = null
      // state.clientSecret = null
      state.error = null;
      state.subscriptionName = null;
      state.items = []
    })
    builder.addCase(createSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.subscriptionId = action.payload.subscriptionId
      // state.clientSecret = action.payload.clientSecret
      // state.subscriptionName = action.payload.subscriptionName;
      state.items = action.payload.items
      state.error = null;
    })
    builder.addCase(createSubscription.rejected, (state, action) => {
      state.loading = false;
      state.subscriptionId = null
      // state.clientSecret = null
      // state.subscriptionName = null;
      state.items = []
      if (action.payload)
        state.error = action.payload;
    })
    builder.addCase(createCustomer.pending, (state) => {
      state.loading = true;
      state.error = null
    })
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.clientSecret = action.payload.client_secret
      state.error = null;
    })
    builder.addCase(createCustomer.rejected, (state, action) => {
      state.loading = false;
      state.error = null;
    })
  }
})

export const {clearState} = SubscriptionSlice.actions
export default SubscriptionSlice.reducer;
