import React, {useEffect} from "react";

declare global {
  interface Window {
    MSStream: any;
  }
}
const appStoreUrl = 'https://apps.apple.com/us/app/eatr-recipes-nutrition/id6479693198'; // Replace with your app's App Store URL

const Install: React.FC = () => {

  useEffect(() => {
    redirectToAppStore()
  }, []);

  const redirectToAppStore = () => {
    // Redirect the user to the appropriate app store URL
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);

    if (isIOS) {
      window.location.href = appStoreUrl; // Replace with your iOS app store URL
    } else {
      window.location.href = 'YOUR_ANDROID_APP_STORE_URL_HERE'; // Replace with your Android app store URL
    }
  };

  return null
}

export default Install
