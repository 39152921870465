import React, {useEffect} from 'react';
import styles from './CustomerReviews.module.css'
import {useCustomerReviews} from "../../../../hooks/useCustomerReviews";
import ReviewItem from "./ReviewItem/ReviewItem";

const CustomerReviews: React.FC = () => {
  const {currentData, next, hasMore, fetchList} = useCustomerReviews(3)
  useEffect(() => {
    fetchList().then()
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Customer reviews
      </div>
      <div className={styles.reviewList}>
        {currentData().map((el, index) => <ReviewItem key={el.id + '-' + index} color={el.color} rating={el.rating} name={el.name} text={el.text} />)}
      </div>
      {hasMore ? (
        <button className={styles.loadMore} onClick={next}>
          View more
        </button>
      ) : null}
    </div>
  )
}

export default CustomerReviews;
