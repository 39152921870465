import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {useHistory} from "react-router-dom";
import styles from './Age.module.css'
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import NumberField from "../../NumberField/NumberField";
import {setAge} from "../../../store/OnBoarding/OnBoardingSlice";
import mixpanel from "mixpanel-browser";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";

const Age: React.FC = () => {
  const history = useHistory()
  const [onBoardingAge, setOnBoardingAge] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const {age: userAge} = useAppSelector(state => state.onBoarding);
  const dispatch = useAppDispatch();

  const onChangeAge = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const maxLength = e.target.maxLength;
    if (e.target.value.length <= maxLength) {
      let value = e.target.value.slice(0, 3);
      setOnBoardingAge(value);
      setErrorText('')
    }
  };

  const onPressNext = () => {
    const validAge = validateAge(onBoardingAge);
    if (typeof validAge !== 'string') {
      dispatch(setAge(Number(onBoardingAge)));
      const eventParams = {
        age: onBoardingAge,
      };
      mixpanel.track('age_input_submitted', eventParams);
      logEvent(analitics, 'age_input_submitted', eventParams)
      // next();
      history.push('/onboarding/height');
      setErrorText('');
    } else {
      setErrorText(validAge);
      dispatch(setAge(null));
    }
  }

  function validateAge(age: string): boolean | string {
    if (age.length === 0) {
      return 'Please enter your age.';
    }
    if (Number(age) < 13) {
      return 'We cannot calculate the nutritional balance for this age. Age less than 13.';
    }
    if (Number(age) > 130) {
      return 'We cannot calculate the nutritional balance for this age. Over 130 years old.';
    }
    return true;
  }

  useEffect(() => {
    if (userAge) {
      setOnBoardingAge(userAge.toString());
    }
  }, [userAge]);

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
      <div className={styles.containerData}>
        <StepInfo title={"Age"} description={"How old are you?"} />
        <div className={styles.listContainer}>
          <div className={styles.inputContainer}>
            <NumberField
              value={onBoardingAge}
              autoFocus={true}
              label={"years old"}
              onChange={onChangeAge}
              maxLength={3}
              error={errorText}
              showError={false}
              onPressEnter={onPressNext}
            />
          </div>
          {errorText ? (
            <div style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingBottom: 24,
              textAlign: 'center',
              color: ColorsEnum.ERROR_TEXT,
              fontFamily: 'Nunito Sans, sans-serif',
            }}>
              {errorText}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <NextButton fixed={true} disabled={onBoardingAge.length === 0 || errorText.length > 0} onClick={onPressNext} />
      </div>
    </div>
  )
}

export default Age;
