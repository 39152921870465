import React, {useEffect, useMemo, useRef, useState} from "react";
import {Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9} from "../../../icons/GoalsIconsList";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {fetchDietsList, setGoals} from "../../../store/OnBoarding/OnBoardingSlice";
import styles from './Goals.module.css'
import GoalItem from "./GoalItem/GoalItem";
import goals from './goals.json';
import NextButton from "../../NextButton/NextButton";
import mixpanel from "mixpanel-browser";
import {useHistory} from "react-router-dom";
import StepInfo from "../StepInfo";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";

const GoalsIcons: any = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
];

type OptionsGoals = {
  [key: string]: string | number
}

const Goals: React.FC = () => {
  const history = useHistory()
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  const userGoals = useAppSelector(state => state.onBoarding.goals);
  const dispatch = useAppDispatch();

  const disableButton = useMemo(() => {
    return userGoals.length === 0;
  }, [userGoals]);

  useEffect(() => {
    if (userGoals) {
      setSelectedGoals(userGoals);
    }
  }, [userGoals]);

  function selectGoal(value: string) {
    let list = [...selectedGoals];
    if (list.includes(value)) {
      list = list.filter(el => el !== value);
    } else {
      if (value === 'Lose weight' && list.includes('Gain weight')) {
        list = list.filter(el => el !== 'Gain weight');
      } else if (value === 'Gain weight' && list.includes('Lose weight')) {
        list = list.filter(el => el !== 'Lose weight');
      }
      list.push(value);
    }
    dispatch(setGoals(list));
  }

  function nextPage() {
    const options: OptionsGoals = {
      Goals_amount: userGoals.length,
    };
    goals.forEach(el => {
      const name = el.name.replace(/ /gi, '_')
      options[name] = userGoals.includes(el.name)
        ? 'selected'
        : 'not_selected';
    });
    if (userGoals.includes('Lose weight')) {
      history.push(OnBoardingRoutesEnum.GRAPH);
    } else {
      history.push(OnBoardingRoutesEnum.GENDER);
    }

    mixpanel.track('goals_selected', options);
    logEvent(analitics, 'goals_selected', options);
  }

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={[styles.goalsContainer, styles.container].join(' ')}>
      <div className={styles.containerData}>
        <StepInfo
          title="Goals"
          description="What goals we could help you accomplish? We will personalize our
          recommendations to help you achieve them"
        />
        <div className={styles.listData}>
          {goals.map((icon, index) => (
            <GoalItem key={icon.id}
                      selected={selectedGoals.includes(icon.name)}
                      item={icon}
                      Icon={GoalsIcons[index]}
                      onClick={selectGoal}
            />
          ))}
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} disabled={disableButton} onClick={nextPage}/>
    </div>
  )
}

export default Goals
