import React from "react";
import styles from "./MealPlanItem.module.css";
import {ColorsEnum} from "../../../../enums/ColorsEnum";

interface MealPlanItemProps {
  name: string,
  description: string,
  selected: boolean,
  onClick: () => void,
  icon: any,
}

const MealPlanItem: React.FC<MealPlanItemProps> = ({
  name,
  description,
  selected,
  onClick,
  icon
}) => {
  const PlanIcon = icon
  return (
    <div className={styles.container} style={{
      borderColor: selected ? ColorsEnum.DARK_20 : ColorsEnum.GRAY_SCALE_10,
    }} onClick={onClick}>
      <div className={styles.formatContainer}>
        <div className={styles.iconContainer}>
          <PlanIcon fill={ColorsEnum.DARK_20} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title}>
            {name}
          </div>
          <div className={styles.description}>
            {description}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MealPlanItem
