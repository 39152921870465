import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../store/types";
import {ExpressCheckoutElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useSubscriptionData} from "../../../../hooks/useSubscriptionData";
import useSubscribe from "../../../../hooks/useSubscribe";
import {createSubscription} from "../../../../store/Subscription/SubscriptionSlice";
import {setClearOnboarding} from "../../../../store/OnBoarding/OnBoardingSlice";
import {logoutUser} from "../../../../store/UserSlice/UserSlice";
import {StripeExpressCheckoutElementClickEvent, StripeExpressCheckoutElementReadyEvent} from "@stripe/stripe-js";
import {useHistory} from "react-router-dom";
import styles from "./styles.module.css";
import mixpanel from "mixpanel-browser";

const ApplePay: React.FC<{ appleShow?: boolean, setShowApple?: (status: boolean) => void }> = (
  {
    appleShow,
    setShowApple
  }
) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {selectedProduct, couponIndex} = useAppSelector(state => state.products)
  const {clientSecret, subscriptionName, items} = useAppSelector(state => state.subscription)
  const stripe = useStripe();
  const elements = useElements();
  const {couponsList} = useSubscriptionData()
  const {errorPay, successPay, sendEventPaymentStartWeb, errorMessage} = useSubscribe()
  const [paymentLoading, setPaymentLoading] = useState(true)
  const [showOr, setShowOr] = useState(false)
  const handleApplePay = async (ev: any) => {
    if (!stripe || !elements) {
      return;
    }
    const {error: submitError} = await elements.submit();
    if (submitError) {
      return;
    }
    if (stripe && clientSecret && selectedProduct) {
      dispatch(createSubscription({
        priceId: selectedProduct.priceId,
        // paymentMethodId: paymentMethod.id,
        couponId: couponsList[couponIndex].id
      })).then(async (redux) => {
        if (redux.meta.requestStatus === 'fulfilled' && redux.payload) {
          const response = redux.payload
          if (typeof response !== 'string') {
            let {error} = await stripe.confirmPayment({
              elements,
              clientSecret: response.client,
              confirmParams: {
                return_url: window.location.origin + '/complete'
              },
              redirect: 'if_required'
            });
            mixpanel.track('payment_start_mail')
            if (error && error.message) {
              errorPay(error.message)
              return;
            }
            if (!error) {
              successPay().then(() => {
                mixpanel.track('payment_complete_mail')
                dispatch(setClearOnboarding())
                dispatch(logoutUser())
                history.push('/complete')
              })
            }
          }
        }

      })
    }
  }
  const onClick = ({resolve, expressPaymentType, ...event}: StripeExpressCheckoutElementClickEvent) => {
    let options = {}
    if (items && items.length > 0) {
      const item = items.length > 0 ? items[0] : null;
      if (item) {
        if (expressPaymentType === 'apple_pay') {
          sendEventPaymentStartWeb('Apple pay')
          options = {
            emailRequired: true,
            applePay: {
              recurringPaymentRequest: {
                paymentDescription: 'Eatr PRO:' + subscriptionName,
                managementURL: window.location.origin + '/billing',
                regularBilling: {
                  amount: 0,
                  label: subscriptionName,
                  recurringPaymentIntervalUnit: 'day',
                  recurringPaymentIntervalCount: item.trialPeriodDays,
                },
              }
            }
          };
        }
      } else if (expressPaymentType === 'paypal') {
        sendEventPaymentStartWeb('PayPal')
      }
    }

    resolve(options);
  };
  const onReady = ({availablePaymentMethods}: StripeExpressCheckoutElementReadyEvent) => {
    if (!availablePaymentMethods) {
      if (setShowApple) {
        setShowApple(false)
      }
    } else {
      if (setShowApple) {
        setShowApple(true)
      }
    }
    setShowOr(true)
    setPaymentLoading(false)
  };
  return (
    <div>
      {paymentLoading && (
        <div>
          <div className={styles.fakeAppleButton}>Pay</div>
          <div className={styles.or}>OR</div>
        </div>
      )}
      <div style={{
        display: appleShow ? 'block' : 'none'
      }}>
        <ExpressCheckoutElement
          onClick={onClick}
          onConfirm={handleApplePay}
          onReady={onReady}
        />
        {errorMessage && <div className={styles.error} style={{
          paddingBottom: 8,
          textAlign: 'center'
        }}>{errorMessage}</div>}
        <div className={styles.or}  style={{
          display: showOr ? 'block' : 'none'
        }}>OR</div>
      </div>
    </div>
  )
}

export default ApplePay
