import React from "react";
import {IconProps} from "../types/types";

const DislikeIcon: React.FC<IconProps> = ({fill, stroke}) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill={stroke}>
      <path
        d="M5 9.99999V1.99999C5 1.73478 4.89464 1.48042 4.70711 1.29289C4.51957 1.10535 4.26522 0.999994 4 0.999994H2C1.73478 0.999994 1.48043 1.10535 1.29289 1.29289C1.10536 1.48042 1 1.73478 1 1.99999V8.99999C1 9.26521 1.10536 9.51956 1.29289 9.7071C1.48043 9.89464 1.73478 9.99999 2 9.99999H5ZM5 9.99999C6.06087 9.99999 7.07828 10.4214 7.82843 11.1716C8.57857 11.9217 9 12.9391 9 14V15C9 15.5304 9.21071 16.0391 9.58579 16.4142C9.96086 16.7893 10.4696 17 11 17C11.5304 17 12.0391 16.7893 12.4142 16.4142C12.7893 16.0391 13 15.5304 13 15V9.99999H16C16.5304 9.99999 17.0391 9.78928 17.4142 9.41421C17.7893 9.03913 18 8.53043 18 7.99999L17 2.99999C16.8562 2.38652 16.5834 1.85975 16.2227 1.49903C15.8619 1.13831 15.4328 0.96317 15 0.999994H8C7.20435 0.999994 6.44129 1.31606 5.87868 1.87867C5.31607 2.44128 5 3.20434 5 3.99999"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DislikeIcon;
