import React from 'react';
import styles from './SubInfoBlockWithCounter.module.css';

const SubInfoBlockWithCounter: React.FC = () => {
  return (
    <div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Grab your Personal Plan before it’s gone!</div>
      </div>
    </div>
  )
}
export default SubInfoBlockWithCounter;
