export interface ProductListElement {
  name: string;
  image: string;
}

export const productsList: ProductListElement[] = [
  {
    name: 'Avocado',
    image: require('../../../assets/images/ingredients/avocado.webp'),
  },
  {
    name: 'Balsamic vinegar',
    image: require('../../../assets/images/ingredients/balsamic_vinegar.webp'),
  },
  {
    name: 'Beef',
    image: require('../../../assets/images/ingredients/beef.webp'),
  },
  {
    name: 'Red beet',
    image: require('../../../assets/images/ingredients/beet.webp'),
  },
  {
    name: 'Bell pepper',
    image: require('../../../assets/images/ingredients/bell_pepper.webp'),
  },
  {
    name: 'Brussel sprout',
    image: require('../../../assets/images/ingredients/brussel_sprout.webp'),
  },
  {
    name: 'Egg',
    image: require('../../../assets/images/ingredients/egg.webp'),
  },
  {
    name: 'Chicken',
    image: require('../../../assets/images/ingredients/chicken.webp'),
  },
  {
    name: 'Ginger',
    image: require('../../../assets/images/ingredients/ginger.webp'),
  },
  {
    name: 'Honey',
    image: require('../../../assets/images/ingredients/honey.webp'),
  },
  {
    name: 'Kale',
    image: require('../../../assets/images/ingredients/kale.webp'),
  },
  {
    name: 'Lamb',
    image: require('../../../assets/images/ingredients/lamb.webp'),
  },
  {
    name: 'Mustard',
    image: require('../../../assets/images/ingredients/mustard.webp'),
  },
  {
    name: 'Milk',
    image: require('../../../assets/images/ingredients/milk.webp'),
  },
  {
    name: 'Olives',
    image: require('../../../assets/images/ingredients/olives.webp'),
  },
  {
    name: 'Peanuts',
    image: require('../../../assets/images/ingredients/peanut.webp'),
  },
  {
    name: 'Pickles',
    image: require('../../../assets/images/ingredients/pickles.webp'),
  },
  {
    name: 'Pork',
    image: require('../../../assets/images/ingredients/pork.webp'),
  },
  {
    name: 'Salmon',
    image: require('../../../assets/images/ingredients/salmon.webp'),
  },
  {
    name: 'Sesame',
    image: require('../../../assets/images/ingredients/sesame.webp'),
  },
  {
    name: 'Shrimp',
    image: require('../../../assets/images/ingredients/shrimp.webp'),
  },
  {
    name: 'Tuna',
    image: require('../../../assets/images/ingredients/tuna.webp'),
  },
  {
    name: 'Turnips',
    image: require('../../../assets/images/ingredients/turnip.webp'),
  },
];

export const commonProductsList: ProductListElement[] = [
  {
    name: 'Alcohol',
    image: require('../../../assets/images/productsCategories/Alcohol.webp'),
  },
  {
    name: 'Beans & plants',
    image: require('../../../assets/images/productsCategories/Beans_&_plants.webp'),
  },
  {
    name: 'Berries',
    image: require('../../../assets/images/productsCategories/Berries.webp'),
  },
  {
    name: 'Breads',
    image: require('../../../assets/images/productsCategories/Breads.webp'),
  },
  {
    name: 'Dairy',
    image: require('../../../assets/images/productsCategories/Dairy.webp'),
  },
  {
    name: 'Eggs & cheese',
    image: require('../../../assets/images/productsCategories/Eggs_&_cheese.webp'),
  },
  {
    name: 'Fish & seafood',
    image: require('../../../assets/images/productsCategories/Fish_&_seafood.webp'),
  },
  {
    name: 'Fruits & vegetables',
    image: require('../../../assets/images/productsCategories/Fruits_&_vegetables.webp'),
  },
  {
    name: 'Meat',
    image: require('../../../assets/images/productsCategories/Meat.webp'),
  },
  {
    name: 'Mushroom',
    image: require('../../../assets/images/productsCategories/Mushroom.webp'),
  },
  {
    name: 'Non-dairy (vegan)',
    image: require('../../../assets/images/productsCategories/Non-dairy_vegan.webp'),
  },
  {
    name: 'Nuts',
    image: require('../../../assets/images/productsCategories/Nuts.webp'),
  },
  {
    name: 'Oil',
    image: require('../../../assets/images/productsCategories/Oil.webp'),
  },
  {
    name: 'Pantry staples',
    image: require('../../../assets/images/productsCategories/Pantry_staples.webp'),
  },
  {
    name: 'Sweets & sugar',
    image: require('../../../assets/images/productsCategories/Sweets_&_sugar.webp'),
  },
];
