import React, {useEffect, useRef} from "react";
import styles from "./MealPlanFormatScreen.module.css";
import StepInfo from "../StepInfo";
import MealPlanItem from "./MealPlanItem/MealPlanItem";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {MealPlanFormatTypes, MealTime, MealTimeNames, OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import MealPlanDinnerIcon from "../../../icons/MealPlanDinnerIcon";
import MealPlanLunchAndDinnerIcon from "../../../icons/MealPlanLunchAndDinnerIcon";
import MealPlanCustomIcon from "../../../icons/MealPlanCustomIcon";
import MealPlanAllMealIcon from "../../../icons/MealPlanAllMealIcon";
import {clearCustomMealPlanFormat, setCustomMealPlan, setFormatType} from "../../../store/OnBoarding/OnBoardingSlice";
import {useHistory} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";

const MealPlanFormatScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory()
  const {format} = useAppSelector(state => state.onBoarding);
  const selectFormat = (selectedFormat: MealPlanFormatTypes) => {
    dispatch(setFormatType(selectedFormat));
    if (selectedFormat === MealPlanFormatTypes.CUSTOM) {
      history.push(OnBoardingRoutesEnum.CUSTOM_MEAL_PLAN)
    } else {
      const names: string[] = []
      dispatch(clearCustomMealPlanFormat());
      switch (selectedFormat) {
        case MealPlanFormatTypes.DINNER:
          dispatch(setCustomMealPlan(MealTime.DINNER));
          names.push(MealTimeNames[MealTime.DINNER - 1]);
          break;
        case MealPlanFormatTypes.LUNCH_AND_DINNER:
          dispatch(setCustomMealPlan(MealTime.DINNER));
          dispatch(setCustomMealPlan(MealTime.LUNCH));
          names.push(MealTimeNames[MealTime.DINNER - 1], MealTimeNames[MealTime.LUNCH - 1]);
          break;
        case MealPlanFormatTypes.ALL_MEALS:
          dispatch(setCustomMealPlan(MealTime.DINNER));
          dispatch(setCustomMealPlan(MealTime.LUNCH));
          dispatch(setCustomMealPlan(MealTime.BREAKFAST));
          names.push(MealTimeNames[MealTime.DINNER - 1], MealTimeNames[MealTime.LUNCH - 1], MealTimeNames[MealTime.BREAKFAST - 1]);
          break;
      }

      const eventParams = {
        Meal_plan_custom_list: names.join(' '),
      };
      mixpanel.track('meal_plan_set', eventParams);
      logEvent(analitics, 'meal_plan_set', eventParams)
      history.push(OnBoardingRoutesEnum.PRIORITIES_PAGE)
    }
  }

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
      <div className={styles.containerData}>
        <StepInfo title="Format"
                  description="What meals would you like to plan?"/>
        <div className={styles.listContainer}>
          <div className={styles.listData}>
            <MealPlanItem
              name={'Dinner'}
              description={'Make only dinner '}
              selected={format === MealPlanFormatTypes.DINNER}
              onClick={() => selectFormat(MealPlanFormatTypes.DINNER)}
              icon={MealPlanDinnerIcon}
            />
            <MealPlanItem
              name={'Lunches and dinners'}
              description={'Make lunch and dinner every day'}
              selected={format === MealPlanFormatTypes.LUNCH_AND_DINNER}
              onClick={() => selectFormat(MealPlanFormatTypes.LUNCH_AND_DINNER)}
              icon={MealPlanLunchAndDinnerIcon}
            />
            <MealPlanItem
              name={'Every meal'}
              description={'Always make breakfast, lunch and dinner'}
              selected={format === MealPlanFormatTypes.ALL_MEALS}
              onClick={() => selectFormat(MealPlanFormatTypes.ALL_MEALS)}
              icon={MealPlanAllMealIcon}
            />
            <MealPlanItem
              name={'Custom'}
              description={'Manually choose the meal format'}
              selected={format === MealPlanFormatTypes.CUSTOM}
              onClick={() => selectFormat(MealPlanFormatTypes.CUSTOM)}
              icon={MealPlanCustomIcon}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MealPlanFormatScreen
