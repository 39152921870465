import React, {useEffect, useState} from 'react';
import {ColorsEnum} from "../../../../enums/ColorsEnum";
import styles from './ColorfulBlock.module.css'

const points = [15, 16, 18.5, 25, 30, 35, 40];
const colors = ['#8B90FF', '#8BCEFF', '#8BFFEA', '#B0FF8B', '#FFD260', '#FF5353'];

const ColorfulBlock: React.FC<{targetValue: number, blockWidth: number}> = ({targetValue, blockWidth}) => {
  const [sliderValue, setSliderValue] = useState(points[0])
  const [sliderColor, setSliderColor] = useState(colors[0])
  const lengths = [];
  for (let i = 1; i < points.length; i++) {
    lengths.push(points[i] - points[i - 1]);
  }
  const totalLength = lengths.reduce((sum, length) => sum + length, 0);
  const percentages = lengths.map(length => (length / totalLength) * 100);

  useEffect(() => {
    let sliderPercentage = ((targetValue) / (totalLength))  * 100;
    console.log(sliderPercentage)
    if (sliderPercentage > 100) {
      sliderPercentage = 100
    } else {
      if (targetValue < 0) {
        sliderPercentage = 0
      } else if (targetValue >= 40) {
        sliderPercentage = 100
      }
    }

    setSliderValue((sliderPercentage / 100) * blockWidth)
    let currentColor = colors[colors.length - 1];
    let cumulativePercentage = 0;
    let colorIndex = 0
    percentages.forEach((el, index) => {
      if (sliderPercentage <= cumulativePercentage) {
        currentColor = colors[colorIndex];
      } else {
        cumulativePercentage += el;
        colorIndex = index
      }
    })
    setSliderColor(currentColor)
  }, [blockWidth, percentages]);




  return (
    <div className={styles.container}>
      <div className={styles.rowElement}>
        {percentages.map((percentage, index) => (
          <div
            key={index}
            className={styles.element}
            style={{
              width: `${percentage}%`,
              backgroundColor: colors[index],
              borderTopRightRadius: index === points.length - 2 ? 10 : 0,
              borderBottomRightRadius: index === points.length - 2 ? 10 : 0,
              borderTopLeftRadius: index === 0 ? 10 : 0,
              borderBottomLeftRadius: index === 0 ? 10 : 0,
            }}
          >
          <span className={styles.elementTitle} style={{
            left: (index === 1 && window.screen.width <= 540) ? 10 : 0
          }}>
            {points[index]}
          </span>
            {index === percentages.length - 1 ? (
              <span className={[styles.elementTitle, styles.elementTitleEnd].join(' ')}>
                40
              </span>
            ) : null}
          </div>
        ))}
      </div>
      <div className={styles.slider} style={{
        borderColor: sliderColor,
        backgroundColor: ColorsEnum.GRAY_SCALE_WHITE,
        transform: `translate(${sliderValue}px, -25%)`,
      }}>
      </div>
    </div>
  );
};

export default ColorfulBlock;
