import {createSlice} from '@reduxjs/toolkit';

export enum MayorSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export const MayorSystemList = [
  {
    value: MayorSystem.IMPERIAL,
    title: 'American',
    description: 'pounds, ounces, cups',
  },
  {
    value: MayorSystem.METRIC,
    title: 'Metric',
    description: 'grams, kilograms, milliliters, liters',
  },
];

interface InitialState {
  mayorSystem: MayorSystem;
  keyboard: boolean
}

const initialState: InitialState = {
  mayorSystem: MayorSystem.IMPERIAL,
  keyboard: false
};

const OptionsSlice = createSlice({
  name: 'Options',
  initialState,
  reducers: {
    setMayorSystem: (state, action: {payload: MayorSystem}) => {
      state.mayorSystem = action.payload;
      localStorage.setItem('mayorSystem', action.payload);
    },
    setKeyboard: (state, {payload}: {payload: boolean}) => {
      state.keyboard = payload
    }
  },
});

export const {setMayorSystem, setKeyboard} = OptionsSlice.actions;

export default OptionsSlice.reducer;
