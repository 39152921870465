import React, {useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {fetchDietsList} from "../../../store/OnBoarding/OnBoardingSlice";
import StepInfo from "../StepInfo";
import styles from './Diets.module.css'
import {useHistory} from "react-router-dom";
import CheckIcon from "../../../icons/CheckIcon";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {getImageLinkWithResize} from "../../../helper/appHelper";
import {ImageSizeEnum} from "../../../hooks/appEnum";

const Diets: React.FC = () => {
  const dispatch = useAppDispatch();
  const {diets, diet} = useAppSelector(state => state.onBoarding)
  const history = useHistory()

  const goToDiet = (id: number) => {
    history.push('/onboarding/diet/' + id);
  }

  useEffect(() => {
    if (diets.length === 0) {
      dispatch(fetchDietsList())
    }
  }, [diets, dispatch]);


  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
      <div className={styles.containerData}>
        <StepInfo title={"Diet"}
                  description={"Which diet best suits with your personal tastes and preferences?"}/>
        <div className={styles.listContainer}>
            {diets.map((item, index) => (
              <div onClick={() => goToDiet(item.id)} className={styles.item} style={{
                borderColor: item.name === diet ? ColorsEnum.DARK_20 : ColorsEnum.TRANSPARENT
              }} key={index}>
                <img className={styles.img} src={getImageLinkWithResize(item.imageUrl, ImageSizeEnum.M)} alt=""/>
                <div className={styles.gradient}>
                  <div className={styles.text}>{item.name}</div>
                  <div className={styles.desc}>{item.shortDescription}</div>
                </div>
                {item.name === diet ? (
                  <div className={[styles.topGradient].join(' ')}>
                    <div className={styles.selectedIcon}>
                      <CheckIcon fill={ColorsEnum.GRAY_SCALE_WHITE}/>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Diets
