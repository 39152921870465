import React, {useEffect, useState} from "react";
import styles from "../../layout/styles.module.css";
import {useHistory, useLocation} from "react-router-dom";
import currentStyles from './styles.module.css'
import {PRIVACY_LINK, TERMS_LINK} from "../../components/OnBoarding/Email/Email";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {fetchProductsByIds, setCouponIndex, setSelectedProduct} from "../../store/Products/ProductsSlice";
import {useAppDispatch, useAppSelector} from "../../store/types";
import {clearState, createCustomer} from "../../store/Subscription/SubscriptionSlice";
import {Elements} from "@stripe/react-stripe-js";
import {useEvent} from "./hook/useEvent";
import PaymentMethods from "./component/PaymentMethods";
import mixpanel from "mixpanel-browser";
import {setRouteName} from "../../store/OnBoarding/OnBoardingSlice";
import {useSubscriptionData} from "../../hooks/useSubscriptionData";

let subscriptionId: string = 'prod_QqEZiqvVqMUAU6';
let priceId = 'price_1PySvSL3YI1aTntiXKB6cl8W'
if (window.location.hostname.includes("eatr.com")) {
  subscriptionId = 'prod_QrJwUyFY9rArn8'
  priceId = 'price_1PzbIjEL8jvWyZp4MqgbvWMI'
} else {
  subscriptionId = 'prod_QqEZiqvVqMUAU6'
  priceId = 'price_1PyY6KEL8jvWyZp4aRo3VvnH'
}

const EventSubscribe: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  let {search} = useLocation();
  const {clientSecret} = useAppSelector(state => state.subscription);
  const {products} = useAppSelector(state => state.products);
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null)
  const {errorMessage, loading, error, user, getUserData} = useEvent()
  const [load, setLoad] = useState(false)
  const {productsIds} = useSubscriptionData()
  useEffect(() => {
    dispatch(setRouteName('subscription'))
    setLoad(false)
    if (params?.email && !Array.isArray(params?.email) && !user) {
      dispatch(clearState())
      localStorage.removeItem('token')
      getUserData(params.email, priceId).then(() => {
        mixpanel.track('link_clicked')
        if (process.env.REACT_APP_STRIPE_KEY_PROD && process.env.REACT_APP_STRIPE_KEY) {
          const key = window.location.hostname.includes("eatr.com") ? process.env.REACT_APP_STRIPE_KEY_PROD : process.env.REACT_APP_STRIPE_KEY
          if (key) {
            loadStripe(key, {}).then(stripe => {
              setStripePromise(stripe)
            })
          }
        }

        dispatch(fetchProductsByIds(productsIds)).then()
        dispatch(createCustomer());
        setLoad(true)
      })
    }
  }, [params.email]);

  useEffect(() => {
    products.map(el => {
      if (el.id === subscriptionId) {
        dispatch(setSelectedProduct(el))
        dispatch(setCouponIndex(3))
      }
    })
  }, [products]);


  return (
    <div className={[styles.container].join(' ')}>
      <div className={styles.row}>
        <div style={{cursor: 'pointer'}} onClick={() => history.push('/')} className={styles.logo}>
          <img src="/logo.png" alt=""/>
        </div>
        <div className={[styles.onboardingContainer, currentStyles.cont].join(' ')}>
          <div className={currentStyles.content}>
            {load && (<div className={currentStyles.contentData}>
              {error ? (
                <div className={currentStyles.title} style={{
                  paddingTop: 25
                }}>
                  {load ? errorMessage ? errorMessage : 'Invalid link' : null}
                </div>
              ) : (
                <>
                  <div className={currentStyles.title}>
                    <div>Your special offer!</div>
                    <div>Get 66% off - Today only!</div>
                  </div>
                  <div className={currentStyles.driver}></div>
                  <div className={currentStyles.checkoutContainer}>
                    <div className={currentStyles.text}>
                      Offer will be applied to the Eatr connected to {user?.email || params?.email}
                    </div>
                    <div className={currentStyles.infoRowContainer}>
                      <div className={currentStyles.label}>
                        ORDER SUMMARY
                      </div>
                      <div className={currentStyles.infoRow}>
                        <div className={currentStyles.titleForRow}>1 year Eatr PRO (before discount)</div>
                        <div>$59.99</div>
                      </div>
                      <div className={currentStyles.infoRow}>
                        <div className={currentStyles.titleForRow}>Discount (66% off)</div>
                        <div>-$40.00</div>
                      </div>
                    </div>
                  </div>
                  <div className={currentStyles.driver}></div>
                  <div className={currentStyles.totalContainer}>
                    <div className={currentStyles.infoRow}>
                      <div>Total after discount:</div>
                      <div>$19.99</div>
                    </div>
                  </div>
                  <div className={currentStyles.driver}></div>
                  <div className={currentStyles.paymentContainer}>
                    <div className={currentStyles.paymentLabel}>
                      Payment details
                    </div>
                    <div className={currentStyles.paymentBlock}>
                      {
                        clientSecret && stripePromise ? (
                          <Elements stripe={stripePromise} options={{
                            locale: 'en',
                            mode: 'subscription',
                            currency: 'usd',
                            amount: 1999,
                            paymentMethodTypes: ['card'],
                          }}>
                            <PaymentMethods/>
                          </Elements>
                        ) : (!loading && error ?
                          <div className={currentStyles.title} style={{paddingBottom: 0}}>Invalid link</div> : null)
                      }
                    </div>
                  </div>
                  <div className={currentStyles.termsContainer}>
                    <a href={TERMS_LINK}>Terms of Service </a>
                    <a href={PRIVACY_LINK}>Privacy Policy</a>
                  </div>
                </>
              )}
            </div>)}

          </div>
        </div>
      </div>
    </div>
  )
}

export default EventSubscribe;
