export interface CuisinesModel {
  name: string;
  image: string;
}

export const CuisinesList: CuisinesModel[] = [
  {
    name: 'American',
    image: require('../../../assets/images/cuisines/Rectangle-230.webp'),
  },
  {
    name: 'Asian',
    image: require('../../../assets/images/cuisines/Rectangle-230-1.webp'),
  },
  {
    name: 'Chinese',
    image: require('../../../assets/images/cuisines/Rectangle-230-2.webp'),
  },
  {
    name: 'Indian',
    image: require('../../../assets/images/cuisines/Rectangle-230-3.webp'),
  },
  {
    name: 'Italian',
    image: require('../../../assets/images/cuisines/Rectangle-230-4.webp'),
  },
  {
    name: 'Japanese',
    image: require('../../../assets/images/cuisines/Rectangle-230-5.webp'),
  },
  {
    name: 'Mediterranean',
    image: require('../../../assets/images/cuisines/Rectangle-230-6.webp'),
  },
  {
    name: 'Mexican',
    image: require('../../../assets/images/cuisines/Rectangle-230-7.webp'),
  },
  {
    name: 'Thai',
    image: require('../../../assets/images/cuisines/Rectangle-230-8.webp'),
  },
];
