import React from "react";
import {IconProps} from "../types/types";

const LikeIcon: React.FC<IconProps> = ({fill, stroke}) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill={stroke}>
      <path
        d="M14 7.99518L14 15.9952C14 16.2604 14.1054 16.5147 14.2929 16.7023C14.4804 16.8898 14.7348 16.9952 15 16.9952H17C17.2652 16.9952 17.5196 16.8898 17.7071 16.7023C17.8946 16.5148 18 16.2604 18 15.9952V8.99518C18 8.72996 17.8946 8.47561 17.7071 8.28807C17.5196 8.10054 17.2652 7.99518 17 7.99518L14 7.99518ZM14 7.99518C12.9391 7.99518 11.9217 7.57375 11.1716 6.82361C10.4214 6.07346 10 5.05605 10 3.99518V2.99518C10 2.46475 9.78929 1.95604 9.41421 1.58097C9.03914 1.20589 8.53043 0.995178 8 0.995178C7.46957 0.995178 6.96086 1.20589 6.58579 1.58097C6.21071 1.95604 6 2.46475 6 2.99518L6 7.99518L3 7.99518C2.46957 7.99518 1.96086 8.20589 1.58579 8.58097C1.21071 8.95604 1 9.46475 1 9.99518L2 14.9952C2.14381 15.6087 2.41663 16.1354 2.77735 16.4961C3.13807 16.8569 3.56716 17.032 4 16.9952L11 16.9952C11.7956 16.9952 12.5587 16.6791 13.1213 16.1165C13.6839 15.5539 14 14.7908 14 13.9952"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LikeIcon;
