import React from "react";
import {IconProps} from "../types/types";

const RunIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 30" fill="none">
      <path
        d="M2.99158 14.5583C3.16737 16.5439 5.88372 17.6331 8.77289 19.6061C13.2889 22.6896 12.3295 23.9067 16.4313 26.2615C20.5739 28.6395 23.18 28.3323 24.0897 28.1666C24.8873 28.0213 26.5488 27.7141 27.4367 26.3361C27.5738 26.1239 28.2263 25.056 27.9184 23.8863C27.4785 22.2138 25.6033 22.3543 23.3657 20.2873C22.9088 19.8658 20.9989 18.0982 20.3365 15.7706C20.0117 14.631 19.4943 13.5466 19.2043 12.3973C19.1218 12.0707 19.0632 11.8043 18.8279 11.5436C18.1763 10.8207 17.2427 11.3314 15.899 10.8468C14.6962 10.4127 13.9523 9.46594 13.6425 9.06572C12.8161 7.99686 13.0108 7.47552 12.4 7.11116C11.4823 6.56364 10.1524 7.20806 9.00231 7.7546C8.21969 8.12672 7.009 8.70233 6.15387 9.93496C5.73574 10.5387 5.68807 10.8885 5.12195 11.3924C4.42474 12.0136 3.95199 11.968 3.4991 12.4806C2.87041 13.1919 2.96079 14.2065 2.99158 14.5593V14.5583Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.71094 11.7034C5.09331 12.7441 5.40914 13.5106 6.00605 14.2365C6.75788 15.1503 7.13529 15.0059 9.35307 16.4381C10.7544 17.3432 11.7029 17.9566 12.7517 19.0362C13.8512 20.1699 14.8225 21.5024 14.8225 21.5024C15.2774 22.1264 15.4393 22.4152 15.9041 22.9375C16.2457 23.3193 16.8407 23.9773 17.7633 24.5704C18.972 25.3456 20.0983 25.5966 20.7727 25.741C21.6496 25.929 22.3002 25.9484 23.5963 25.9891C24.4067 26.0133 25.1029 26.0346 26.0137 25.9561C26.8191 25.8854 27.3445 25.7759 27.7647 25.6819"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.0069 1L12.8672 6.75517"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.3821 5.72351L17.5781 11.1037"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.4609 10.6578C15.6109 12.2296 16.0012 13.0785 17.1742 16.4217C17.8873 18.4567 17.9389 18.7 18.3233 18.9955C19.4168 19.8337 21.1102 19.4781 22.2225 19.105"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <line
        x1="4.75"
        y1="22.25"
        x2="8.25"
        y2="22.25"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="5.69531"
        y1="28.25"
        x2="9.14313"
        y2="28.25"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="25.3535"
        x2="6.17694"
        y2="25.3535"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default RunIcon
