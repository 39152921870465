import React, {useMemo} from 'react';
import './style.css'
let containerHeight = 280;
let iconHeightWidth = 256;

const MessageImage: React.FC<{containerWidth: number}> = ({containerWidth}) => {
  const iconWidth = useMemo(() => {
     if (window.screen.width <= 540) {
      return 256
    } else if (window.screen.width <= 768) {
      return 208
    }
    else {
      return 256
    }
  }, [window.screen.width])
  console.log(iconWidth)
  const y = (containerHeight / 2) - (iconHeightWidth / 3)
  const x = ((containerWidth / 2) - (window.screen.width <= 540 ? (iconWidth / 4) : (iconWidth / 4)))

  return (
    <svg className='value' width={window.screen.width <= 540 ? containerWidth : 540} height={containerHeight} viewBox={`0 0 ${window.screen.width <= 540 ? containerWidth : 540} ${containerHeight}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon" style={{
        transform: `translate(${x}px, ${y}px)`
      }}>
        <path
          className={'animated'}
          id="group-1"
          d="M50 55C50 57.21 48.21 59 46 59C43.79 59 42 57.21 42 55C42 52.79 43.79 51 46 51C48.21 51 50 52.79 50 55Z"
          fill="#8BFFEA"/>
        <path className={'animated'} id="element-2"
              d="M126.83 34.9776L125.016 27.1861L119.172 28.5469L120.986 36.3384L126.83 34.9776Z"
              fill="#8BFF97"/>
        <path className={'animated'} id="element-3"
              d="M14.521 134.001L8 138.635L11.4757 143.526L17.9967 138.891L14.521 134.001Z"
              fill="#FF5353"/>
        <path className={'animated'} id="element-4"
              d="M49 16L52.28 19.33L56.94 19.01L53.62 22.29L53.94 26.95L50.66 23.62L46 23.94L49.32 20.66L49 16Z"
              fill="#FF5353"/>
        <path className={'animated'} id="element-5"
              d="M155 133L156.62 137.38L161 139L156.62 140.62L155 145L153.38 140.62L149 139L153.38 137.38L155 133Z"
              fill="#8B90FF"/>
        <path className={'animated'} id="element-6" d="M128 157.37L133.05 154L137.87 156.66L132.83 160.03L128 157.37Z"
              fill="#8BFFEA"/>
        <path
          className={'animated'}
          id="element-7"
          d="M82.5789 31.18L88.4889 32.08C89.4089 31.75 90.0589 31.09 90.0589 30.34C90.0589 29.47 89.3389 28.63 88.6689 27.84C87.1589 26.07 85.7289 24.21 85.3289 22.24C84.9289 20.28 85.7489 18.19 88.0289 17.22C88.7489 16.91 89.5889 16.72 90.2889 16.4C90.9889 16.07 91.5789 15.55 91.4989 14.93C91.3989 14.11 90.2389 13.49 89.4389 12.81C87.5589 11.23 87.6789 8.79 89.6889 7.61C90.3789 7.21 91.2989 6.96 92.2389 6.95L85.9889 6C85.2689 6.08 84.5989 6.3 84.0589 6.62C82.0489 7.8 81.9289 10.24 83.8089 11.82C84.6089 12.49 85.7689 13.11 85.8689 13.93C85.9489 14.55 85.3589 15.08 84.6589 15.4C83.9589 15.73 83.1189 15.92 82.3989 16.22C80.1189 17.2 79.2989 19.29 79.6989 21.25C80.0989 23.21 81.5289 25.07 83.0389 26.85C83.7089 27.64 84.4289 28.48 84.4289 29.35C84.4289 30.18 83.6489 30.89 82.5789 31.18Z"
          fill="#8BCEFF"/>
        <path
          className={'animated'}
          id="element-8"
          d="M8.98931 40.02L4.17931 43.56C3.83931 44.55 3.97931 45.64 4.62931 46.35C5.38931 47.16 6.60931 47.41 7.75931 47.65C10.3293 48.18 12.9393 48.86 14.9193 50.39C16.9093 51.92 18.1693 54.48 17.4693 57.08C17.2493 57.9 16.8393 58.7 16.6493 59.52C16.4593 60.35 16.5093 61.27 17.1093 61.79C17.8993 62.48 19.2293 62.2 20.3593 62.24C23.0293 62.32 25.0793 64.68 24.7293 67.27C24.6093 68.16 24.1993 69.07 23.5693 69.77L28.6593 66.02C29.0693 65.41 29.3393 64.7 29.4393 64.01C29.7793 61.42 27.7293 59.06 25.0593 58.97C23.9293 58.93 22.5993 59.22 21.8093 58.53C21.2193 58.01 21.1493 57.09 21.3493 56.26C21.5393 55.43 21.9493 54.64 22.1693 53.81C22.8693 51.21 21.5993 48.65 19.6193 47.12C17.6293 45.59 15.0393 44.91 12.4593 44.38C11.3093 44.14 10.0893 43.89 9.32931 43.08C8.60931 42.3 8.51931 41.06 8.98931 40V40.02Z"
          fill="#FFD260"/>
        <path
          className={'animated'}
          id="element-9"
          d="M135.968 65.04L131.158 61.5C130.838 60.49 130.998 59.36 131.688 58.61C132.478 57.76 133.728 57.47 134.898 57.2C137.528 56.59 140.188 55.83 142.248 54.2C144.298 52.58 145.648 49.91 144.998 47.24C144.798 46.4 144.398 45.59 144.218 44.74C144.038 43.89 144.118 42.94 144.738 42.39C145.558 41.66 146.898 41.92 148.058 41.85C150.768 41.7 152.918 39.22 152.628 36.55C152.528 35.64 152.128 34.71 151.508 34L156.598 37.75C157.008 38.37 157.268 39.09 157.338 39.8C157.628 42.47 155.478 44.95 152.768 45.1C151.618 45.16 150.268 44.9 149.448 45.63C148.828 46.18 148.748 47.13 148.928 47.98C149.108 48.83 149.498 49.64 149.708 50.48C150.358 53.15 149.008 55.81 146.948 57.44C144.898 59.06 142.238 59.83 139.598 60.44C138.428 60.71 137.178 61 136.388 61.85C135.628 62.67 135.518 63.95 135.968 65.04Z"
          fill="#F1A2EE"/>
        <path
          className={'animated'}
          id="element-10"
          d="M147 80C148.657 80 150 78.6569 150 77C150 75.3431 148.657 74 147 74C145.343 74 144 75.3431 144 77C144 78.6569 145.343 80 147 80Z"
          fill="#8BCEFF"/>
        <path
          className={'animated'}
          id="element-11" cx="50%" cy="50%"
          d="M42.4853 155.633C44.0474 156.535 44.0474 157.997 42.4853 158.898C40.9232 159.8 38.3905 159.8 36.8284 158.898C35.2663 157.997 35.2663 156.535 36.8284 155.633C38.3905 154.731 40.9232 154.731 42.4853 155.633Z"
          fill="#FFD260"/>
        <g id="hand">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M88.0128 61.2915C87.902 60.9569 87.8265 60.6085 87.7817 60.2462C87.7723 60.1793 87.7487 60.1193 87.7369 60.0524H87.7605C87.744 59.8747 87.7133 59.7086 87.711 59.5332C87.6992 56.5843 89.859 54.1868 92.5352 54.1753C94.7516 54.1684 96.6096 55.809 97.1944 58.0403C97.765 55.802 99.6065 54.1476 101.823 54.1407C104.501 54.1291 106.68 56.5127 106.692 59.4593C106.692 59.6393 106.661 59.8055 106.647 59.9785H106.671C106.661 60.0477 106.638 60.11 106.628 60.177C106.583 60.5392 106.51 60.8877 106.407 61.2222C106.4 61.2499 106.392 61.2753 106.385 61.303C106.343 61.4253 106.303 61.5522 106.253 61.6722C104.346 67.4316 97.6424 72.0719 97.2698 72.2265V72.2403C97.2698 72.2403 97.2533 72.2334 97.2533 72.2311C97.251 72.2311 97.2321 72.2403 97.2321 72.2403V72.2242C96.8596 72.0719 90.1207 67.4824 88.1684 61.7368C88.1165 61.6145 88.0788 61.4922 88.034 61.3676C88.0293 61.3422 88.0199 61.3145 88.0128 61.2869V61.2915Z"
                fill="#FF5353"/>
          <path
            d="M115.472 102.761C115.134 102.036 115.457 101.172 116.187 100.846C117.805 100.121 119.408 99.3518 121.019 98.6164L130.165 94.4381C131.265 93.9359 132.432 94.9681 132.053 96.1155C130.851 99.7537 129.555 103.363 128.372 107.005C128.146 107.7 128.499 108.449 129.177 108.72C131.159 109.513 133.147 110.289 135.142 111.048C136.162 111.441 137.185 111.84 138.212 112.226C139.449 112.691 139.514 114.567 138.314 115.121C137.412 115.538 136.515 115.963 135.619 116.375L126.424 120.6C125.721 120.923 125.414 121.756 125.739 122.459L130.313 132.326C130.859 133.504 129.62 134.78 128.407 134.319C127.256 133.881 126.099 133.46 124.955 133.022C121.88 131.825 118.796 130.653 115.702 129.506C114.973 129.236 114.599 128.423 114.857 127.689C114.892 127.592 114.926 127.495 114.96 127.398C115.209 126.693 115.98 126.333 116.683 126.589C118.093 127.104 119.493 127.661 120.894 128.188C121.324 128.349 121.757 128.506 122.191 128.664C123.434 129.117 124.753 127.751 124.19 126.554L121.484 120.798C121.153 120.095 121.459 119.258 122.164 118.934C125.008 117.627 127.856 116.329 130.709 115.042C131.684 114.602 131.621 112.956 130.621 112.579L125.416 110.597C124.706 110.326 124.342 109.538 124.593 108.821C125.146 107.234 125.684 105.642 126.204 104.044C126.368 103.533 126.527 103.015 126.688 102.497C127.068 101.271 125.729 100.051 124.557 100.573C122.21 101.618 119.841 102.62 117.526 103.73C116.806 104.075 115.937 103.765 115.601 103.04C115.558 102.947 115.515 102.854 115.472 102.761Z"
            fill="#FFD260"/>
          <path
            d="M46.2969 81.7V96.9L41.9969 103.2L41.3969 104.3L37.9969 109.1L26.6469 126.03L26.4969 125.7C26.4969 125.6 26.4969 125.5 26.3969 125.4C26.2969 125.1 26.1969 124.8 26.0969 124.4C25.8969 123.7 25.4969 122.9 25.3969 122.4C24.7969 120.4 24.2969 118.4 23.7969 116.4C23.6969 116 23.6969 115.7 23.5969 115.4C23.4969 115 23.4969 114.7 23.3969 114.3C23.2969 113.9 23.2969 113.6 23.1969 113.2C23.0969 112.9 23.0969 112.5 22.9969 112.1C22.8969 111.8 22.8969 111.4 22.7969 111C22.7969 110.6 22.6969 110.3 22.6969 109.9C22.6969 109.5 22.5969 109.2 22.5969 108.8C22.5969 108.4 22.4969 108.1 22.4969 107.7C22.4969 107.3 22.3969 107 22.3969 106.6C22.3969 106.25 22.3969 105.98 22.3269 105.65C22.3069 104.87 22.2969 104.09 22.2969 103.3V103.24C22.2969 95.58 23.3069 88.29 25.1069 81.7H45.5969C45.5969 81.7 46.0969 81.7 46.2969 81.7Z"
            fill="#B0FF8B"/>
          <path
            d="M70.3316 139.86H46.1016V81.66L50.8016 79.66C51.5016 79.26 52.1016 78.86 52.7016 78.46C52.9016 78.36 53.0016 78.26 53.2016 78.16C53.5016 77.96 53.9016 77.66 54.2016 77.46C54.4016 77.36 54.5016 77.26 54.7016 77.06C54.9616 76.86 55.2216 76.66 55.4916 76.43C53.1716 82.93 51.8316 90.68 51.8316 99C51.8316 119.25 59.7816 136.12 70.3316 139.86Z"
            fill="#FFD260"/>
          <path
            d="M70.3316 139.86H46.1016V81.66L50.8016 79.66C51.5016 79.26 52.1016 78.86 52.7016 78.46C52.9016 78.36 53.0016 78.26 53.2016 78.16C53.5016 77.96 53.9016 77.66 54.2016 77.46C54.4016 77.36 54.5016 77.26 54.7016 77.06C54.9616 76.86 55.2216 76.66 55.4916 76.43C53.1716 82.93 51.8316 90.68 51.8316 99C51.8316 119.25 59.7816 136.12 70.3316 139.86Z"
            fill="white" fillOpacity="0.4"/>
          <path
            d="M70.3316 139.86H46.1016V81.66L50.8016 79.66C51.5016 79.26 52.1016 78.86 52.7016 78.46C52.9016 78.36 53.0016 78.26 53.2016 78.16C53.5016 77.96 53.9016 77.66 54.2016 77.46C54.4016 77.36 54.5016 77.26 54.7016 77.06C54.9616 76.86 55.2216 76.66 55.4916 76.43C53.1716 82.93 51.8316 90.68 51.8316 99C51.8316 119.25 59.7816 136.12 70.3316 139.86Z"
            fill="black" fillOpacity="0.05"/>
          <path
            d="M108.028 115.92C106.078 113.46 103.238 113.07 102.608 113C103.498 112.91 104.208 112.7 104.708 112.52C106.098 112 106.978 111.25 107.208 111.04C107.368 110.9 107.578 110.71 107.818 110.43C108.488 109.63 108.758 108.85 108.908 108.39C109.778 105.76 108.998 103.34 108.998 103.34C108.368 101.38 107.138 100.21 106.578 99.73L106.558 99.75C105.458 98.96 104.258 98.67 102.858 98.38C103.508 98.34 104.588 98.18 105.758 97.59C107.168 96.87 107.978 95.89 108.258 95.53C108.858 94.75 109.178 94 109.358 93.48C110.088 88.37 106.628 83.52 101.258 83.87H75.8581C74.4581 83.95 74.4081 82.57 75.1581 81.71C81.2181 72.75 80.1581 63.35 76.9481 57.35C72.3981 48.86 63.5381 47.17 64.4081 63.11C64.3781 63.41 64.3481 63.71 64.3081 64C63.7581 68.1 61.6785 72.0968 58.9685 75.1768C58.9685 75.1768 57.9681 76.04 57.4881 76.43C55.1681 82.93 53.8281 90.68 53.8281 99C53.8281 119.25 61.7781 136.12 72.3281 139.86L81.4581 139.76C88.3481 139.88 96.9181 140.06 99.7081 139.37C100.338 139.17 101.498 138.71 102.558 137.65C103.248 136.96 103.668 136.25 103.908 135.74C104.198 135.19 104.978 133.53 104.608 131.43C104.288 129.6 103.268 128.41 102.938 128.03C102.668 127.72 102.408 127.48 102.208 127.31C105.958 127.69 109.598 124.33 109.608 120.55V119.57C109.488 118.79 109.148 117.33 108.028 115.92Z"
            fill="#E6E7E6"/>
          <path
            d="M106.028 115.92C104.078 113.46 101.238 113.07 100.608 113C101.498 112.91 102.208 112.7 102.708 112.52C104.098 112 104.978 111.25 105.208 111.04C105.368 110.9 105.578 110.71 105.818 110.43C106.488 109.63 106.758 108.85 106.908 108.39C107.778 105.76 106.998 103.34 106.998 103.34C106.368 101.38 105.138 100.21 104.578 99.73L104.558 99.75C103.458 98.96 102.258 98.67 100.858 98.38C101.508 98.34 102.588 98.18 103.758 97.59C105.168 96.87 105.978 95.89 106.258 95.53C106.858 94.75 107.178 94 107.358 93.48C108.088 88.37 104.628 83.52 99.2581 83.87H73.8581C72.4581 83.95 72.4081 82.57 73.1581 81.71C79.2181 72.75 78.1581 63.35 74.9481 57.35C70.3981 48.86 61.5381 47.17 62.4081 63.11C62.3781 63.41 62.3481 63.71 62.3081 64C61.7581 68.1 59.6785 72.0968 56.9685 75.1768C56.9685 75.1768 55.9681 76.04 55.4881 76.43C53.1681 82.93 51.8281 90.68 51.8281 99C51.8281 119.25 59.7781 136.12 70.3281 139.86L79.4581 139.76C86.3481 139.88 94.9181 140.06 97.7081 139.37C98.3381 139.17 99.4981 138.71 100.558 137.65C101.248 136.96 101.668 136.25 101.908 135.74C102.198 135.19 102.978 133.53 102.608 131.43C102.288 129.6 101.268 128.41 100.938 128.03C100.668 127.72 100.408 127.48 100.208 127.31C103.958 127.69 107.598 124.33 107.608 120.55V119.57C107.488 118.79 107.148 117.33 106.028 115.92Z"
            fill="#FFD260"/>
          <path
            d="M106.028 115.92C104.078 113.46 101.238 113.07 100.608 113C101.498 112.91 102.208 112.7 102.708 112.52C104.098 112 104.978 111.25 105.208 111.04C105.368 110.9 105.578 110.71 105.818 110.43C106.488 109.63 106.758 108.85 106.908 108.39C107.778 105.76 106.998 103.34 106.998 103.34C106.368 101.38 105.138 100.21 104.578 99.73L104.558 99.75C103.458 98.96 102.258 98.67 100.858 98.38C101.508 98.34 102.588 98.18 103.758 97.59C105.168 96.87 105.978 95.89 106.258 95.53C106.858 94.75 107.178 94 107.358 93.48C108.088 88.37 104.628 83.52 99.2581 83.87H73.8581C72.4581 83.95 72.4081 82.57 73.1581 81.71C79.2181 72.75 78.1581 63.35 74.9481 57.35C70.3981 48.86 61.5381 47.17 62.4081 63.11C62.3781 63.41 62.3481 63.71 62.3081 64C61.7581 68.1 59.6785 72.0968 56.9685 75.1768C56.9685 75.1768 55.9681 76.04 55.4881 76.43C53.1681 82.93 51.8281 90.68 51.8281 99C51.8281 119.25 59.7781 136.12 70.3281 139.86L79.4581 139.76C86.3481 139.88 94.9181 140.06 97.7081 139.37C98.3381 139.17 99.4981 138.71 100.558 137.65C101.248 136.96 101.668 136.25 101.908 135.74C102.198 135.19 102.978 133.53 102.608 131.43C102.288 129.6 101.268 128.41 100.938 128.03C100.668 127.72 100.408 127.48 100.208 127.31C103.958 127.69 107.598 124.33 107.608 120.55V119.57C107.488 118.79 107.148 117.33 106.028 115.92Z"
            fill="white" fillOpacity="0.4"/>
          <ellipse cx="38.8047" cy="130.435" rx="3.5" ry="2.5" fill="white"/>
          <path
            d="M41.8031 102.66L41.2031 103.76L37.8031 108.56L26.4531 125.49C27.0931 126.89 27.6531 128.37 28.3031 129.76C28.7031 130.66 29.2031 131.66 29.7031 132.56C30.1031 133.56 30.7031 134.46 31.2031 135.36C31.7031 136.26 32.3031 137.16 32.9031 138.06C33.3031 138.66 33.8031 139.26 34.2031 139.86H46.1031V96.36L41.8031 102.66ZM38.1531 133.66C35.9131 133.66 34.1031 132.14 34.1031 130.27C34.1031 128.4 35.9131 126.88 38.1531 126.88C40.3931 126.88 42.2031 128.4 42.2031 130.27C42.2031 132.14 40.3931 133.66 38.1531 133.66Z"
            fill="#8DCC6F"/>
        </g>
      </g>
    </svg>


  )
}

export default MessageImage
