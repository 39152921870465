import React from 'react';
import styles from './ScrollButton.module.css'

interface ScrollButtonProps {
  onClick: () => void
}

const ScrollButton: React.FC<ScrollButtonProps> = ({onClick}) => {
  return (
    <div className={styles.btnContainer}>
      <button onClick={onClick} className={styles.button}>
        Get my plan
      </button>
    </div>
  )
}

export default ScrollButton
