import React from "react";
import {IconProps} from "../types/types";

const Icon1: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M10.848 18.2062L8.63817 15.9963M21.1606 19.6795L18.2142 16.7329M21.1606 10.8398C21.1606 10.8398 23.5089 7.89318 25.5803 7.89318C28.3205 7.89318 30 10.8398 30 10.8398C30 10.8398 28.3205 13.7864 25.5803 13.7864C22.8401 13.7864 21.1606 10.8398 21.1606 10.8398ZM21.1606 10.8398C21.1606 10.8398 18.2142 9.16021 18.2142 6.41988C18.2142 3.67956 21.1606 2 21.1606 2C21.1606 2 24.1071 3.67956 24.1071 6.41988C24.1071 8.51638 21.1606 10.8398 21.1606 10.8398ZM2.00737 29.9926C2.00737 29.9926 16.4951 24.8523 20.6938 20.652C21.9328 19.4123 22.6288 17.7314 22.6288 15.9787C22.6288 14.226 21.9328 12.545 20.6938 11.3054C20.0805 10.6916 19.3522 10.2047 18.5506 9.87252C17.7491 9.54033 16.8899 9.36935 16.0222 9.36935C15.1545 9.36935 14.2953 9.54033 13.4938 9.87252C12.6922 10.2047 11.9639 10.6916 11.3506 11.3054C7.13715 15.5205 2 30 2 30L2.00737 29.9926Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Icon2: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M2 13.2C2 7.9206 2 5.2802 3.82311 3.6408C5.64467 2 8.57844 2 14.4444 2H17.5556C23.4216 2 26.3553 2 28.1769 3.6408C30 5.2802 30 7.9206 30 13.2V18.8C30 24.0794 30 26.7198 28.1769 28.3592C26.3553 30 23.4216 30 17.5556 30H14.4444C8.57844 30 5.64467 30 3.82311 28.3592C2 26.7198 2 24.0794 2 18.8V13.2Z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M9.10213 27H22.8954M12.8608 13.0611L11.697 9.80908M23.6057 13.1319L24.7523 10.2598C24.9442 9.7805 25.0263 9.26417 24.9926 8.74884C24.959 8.23351 24.8104 7.73231 24.5578 7.28211C24.3052 6.8319 23.955 6.44417 23.533 6.14743C23.1111 5.8507 22.6281 5.65252 22.1195 5.56748L21.3868 5.44659C17.8205 4.85114 14.1805 4.85114 10.6142 5.44659L9.88144 5.56748C9.37298 5.65236 8.88998 5.85035 8.46798 6.14688C8.04598 6.44341 7.69572 6.83094 7.44297 7.28095C7.19022 7.73096 7.0414 8.23201 7.00748 8.74724C6.97355 9.26246 7.05538 9.77874 7.24693 10.2581L8.3935 13.1319C8.8004 14.1509 9.89524 14.7104 10.9573 14.4444C14.2677 13.6155 17.7333 13.6155 21.0436 14.4444C21.5569 14.5726 22.0992 14.5102 22.5702 14.269C23.0412 14.0277 23.409 13.6238 23.6057 13.1319Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
const Icon3: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M16 10C18.2091 10 20 8.20914 20 6C20 3.79086 18.2091 2 16 2C13.7909 2 12 3.79086 12 6C12 8.20914 13.7909 10 16 10Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.26065 10C7.65368 10.0069 7.06513 10.2296 6.58335 10.6346C6.10157 11.0396 5.75255 11.6051 5.58869 12.2462L2.0892 26.1538C1.98316 26.604 1.9715 27.0746 2.05509 27.5304C2.13868 27.9862 2.31534 28.4152 2.57181 28.7854C2.82827 29.1555 3.15788 29.4571 3.53586 29.6675C3.91383 29.8778 4.33036 29.9915 4.75414 30H27.1958C27.6287 29.9998 28.0558 29.8897 28.4435 29.6784C28.8312 29.467 29.169 29.16 29.4305 28.7816C29.692 28.4031 29.8701 27.9634 29.9508 27.4968C30.0315 27.0303 30.0127 26.5496 29.8958 26.0923L26.3542 12.3077C26.1998 11.6514 25.8521 11.0694 25.3651 10.6521C24.8781 10.2348 24.2792 10.0056 23.6612 10H8.26065Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9 23.6522V23.7522H11H11.8792H11.9792V23.6522V21.3863L14.503 23.7255L14.5318 23.7522H14.571H15.689H15.9393L15.7579 23.5797L12.9574 20.9166L15.5303 18.4492L15.7098 18.2771H15.4611H14.3431H14.3029L14.2738 18.3049L11.9792 20.5078V16V15.9H11.8792H11H10.9V16V23.6522ZM19.4776 23.3363C19.706 23.1993 19.8946 23.0294 20.0425 22.827V23.3917C20.0425 23.9124 19.9158 24.2845 19.6801 24.5268C19.4449 24.7685 19.0844 24.8981 18.5795 24.8981C18.2124 24.8981 17.8716 24.8487 17.5566 24.7507C17.2499 24.6531 16.9373 24.492 16.6186 24.2654L16.52 24.1953L16.4697 24.3052L16.1766 24.9456L16.1435 25.0181L16.2067 25.0666C16.5281 25.3133 16.8938 25.4959 17.3028 25.615C17.718 25.7409 18.1582 25.8036 18.6229 25.8036C19.4207 25.8036 20.0402 25.5966 20.4631 25.1662C20.8936 24.7357 21.1 24.098 21.1 23.2723V18.3771V18.2771H21H20.1425H20.0425V18.3771V18.9707C19.8943 18.7727 19.7053 18.6075 19.4766 18.4752C19.1412 18.2771 18.7579 18.1794 18.3299 18.1794C17.8297 18.1794 17.3913 18.2915 17.0186 18.5197C16.6467 18.7474 16.3593 19.0697 16.1561 19.4835L16.1558 19.4842C15.9599 19.891 15.8637 20.3622 15.8637 20.8952C15.8637 21.4283 15.96 21.9029 16.1555 22.3165L16.1554 22.3165L16.1564 22.3184C16.3596 22.7248 16.6467 23.0464 17.0173 23.2808C17.3903 23.5168 17.8291 23.6328 18.3299 23.6328C18.7581 23.6328 19.1415 23.5349 19.477 23.3367L19.4776 23.3363ZM19.6215 19.5708L19.6221 19.5716C19.8987 19.8886 20.0425 20.3259 20.0425 20.8952C20.0425 21.4644 19.8987 21.9059 19.6215 22.2305C19.346 22.553 18.9735 22.7164 18.4927 22.7164C18.0196 22.7164 17.6505 22.5533 17.3748 22.2305C17.0975 21.9059 16.9537 21.4644 16.9537 20.8952C16.9537 20.3259 17.0976 19.8886 17.3741 19.5716L17.3748 19.5708C17.6505 19.248 18.0196 19.0849 18.4927 19.0849C18.9735 19.0849 19.346 19.2483 19.6215 19.5708Z"
        fill={fill}
        strokeWidth="0.2"
      />
    </svg>
  )
}
const Icon4: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M6.87025 23.7918L25.3789 23.7778M15.9994 2C18.998 2 21.5025 4.1 22.1122 6.90156C23.7133 6.47043 25.4209 6.68982 26.8594 7.51146C28.298 8.3331 29.3495 9.68968 29.7828 11.2828C30.2161 12.8759 29.9956 14.575 29.1699 16.0063C28.3441 17.4377 26.9807 18.484 25.3796 18.9151V30H6.61922V18.9151C5.82644 18.7014 5.08372 18.3345 4.43346 17.8352C3.78321 17.3359 3.23815 16.7141 2.82942 16.0052C2.42069 15.2964 2.15628 14.5143 2.0513 13.7038C1.94632 12.8932 2.00281 12.07 2.21756 11.2812C2.43231 10.4924 2.80111 9.75339 3.30289 9.10638C3.80468 8.45938 4.42963 7.91705 5.14206 7.51036C5.85449 7.10367 6.64045 6.84058 7.45507 6.73613C8.26968 6.63167 9.097 6.68788 9.88978 6.90156C10.1928 5.51333 10.9641 4.27007 12.0756 3.37839C13.187 2.48671 14.5717 2.00031 15.9994 2Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Icon5: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M10 25H22V27C22 28.1 21.1 29 20 29H12C10.9 29 10 28.1 10 27V25Z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M3 13H29V15C29 20.5 24.5 25 19 25H13C7.5 25 3 20.5 3 15V13Z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M3 13H29V15C29 20.5 24.5 25 19 25H13C7.5 25 3 20.5 3 15V13Z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M21 12C21 7.6 17.4 4 13 4C8.6 4 5 7.6 5 12"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17 12C17 9.8 15.2 8 13 8C10.8 8 9 9.8 9 12"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 8.2C20.7412 7.42857 21.8118 7 22.8824 7C25.1882 7 27 8.88571 27 11.2857C27 11.5429 27 11.8857 26.9176 12.1429C26.8353 12.4857 26.7529 12.7429 26.6706 13"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21 10.6C21.3529 10.2 21.7941 10 22.2353 10C23.2059 10 24 10.9 24 12C24 12.4 23.9118 12.7 23.7353 13"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
const Icon6: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M10.8571 5.11111H7.42857C6.51926 5.11111 5.64719 5.43889 5.00421 6.02233C4.36122 6.60578 4 7.3971 4 8.22222V26.8889C4 27.714 4.36122 28.5053 5.00421 29.0888C5.64719 29.6722 6.51926 30 7.42857 30H24.5714C25.4807 30 26.3528 29.6722 26.9958 29.0888C27.6388 28.5053 28 27.714 28 26.8889V8.22222C28 7.3971 27.6388 6.60578 26.9958 6.02233C26.3528 5.43889 25.4807 5.11111 24.5714 5.11111H21.1429M10.8571 5.11111C10.8571 4.28599 11.2184 3.49467 11.8613 2.91122C12.5043 2.32778 13.3764 2 14.2857 2H17.7143C18.6236 2 19.4957 2.32778 20.1387 2.91122C20.7816 3.49467 21.1429 4.28599 21.1429 5.11111M10.8571 5.11111C10.8571 5.93623 11.2184 6.72755 11.8613 7.311C12.5043 7.89445 13.3764 8.22222 14.2857 8.22222H17.7143C18.6236 8.22222 19.4957 7.89445 20.1387 7.311C20.7816 6.72755 21.1429 5.93623 21.1429 5.11111"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6275 21.7443C21.173 23.026 20.5638 23.8934 20.1366 24.4138C19.5093 25.1847 19.0183 25.7822 18.2001 25.9557C17.1637 26.1677 16.2727 25.5606 16 25.3582C15.7182 25.5606 14.8363 26.1677 13.7908 25.9557C12.9726 25.7822 12.4907 25.1847 11.8634 24.4138C11.4362 23.8934 10.827 23.026 10.3725 21.7443C10.327 21.6479 9.39061 19.3157 10.6725 17.4172C11.7725 15.7885 14.0363 15.2007 16 16.1933C17.9637 15.2007 20.2275 15.7885 21.3275 17.4172C22.6094 19.3157 21.673 21.6479 21.6275 21.7443Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.8496 13.0327C18.2161 12.8396 18.3566 12.386 18.1635 12.0195C17.9705 11.6531 17.5169 11.5125 17.1504 11.7056L17.8496 13.0327ZM16.0783 14.3524L15.3456 14.1923L15.3451 14.1944L16.0783 14.3524ZM17.1504 11.7056C16.9477 11.8124 15.7152 12.4999 15.3456 14.1923L16.811 14.5124C17.04 13.4641 17.7938 13.0621 17.8496 13.0327L17.1504 11.7056ZM15.3451 14.1944C15.0897 15.3802 15.4179 16.3508 15.5248 16.6391L16.9311 16.1173C16.8612 15.9288 16.6452 15.2822 16.8115 14.5103L15.3451 14.1944Z"
        fill={fill}
      />
      <path
        d="M15.6716 15.1881C15.4764 15.2108 14.032 15.3544 12.9076 14.3034C11.9628 13.4187 11.9004 12.292 11.8926 12.05C12.0331 12.0273 13.5401 11.7476 14.7034 12.8062C15.7341 13.7514 15.6794 15.0066 15.6716 15.1881Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Icon7: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M14.2539 24.3997C15.2627 20.7247 18.7898 18.0997 22.8527 18.5337C26.7207 18.9467 29.7957 22.2297 29.9905 26.1357C30.0392 27.1647 29.9 28.1517 29.6009 29.0687C29.42 29.6287 28.8774 29.9997 28.286 29.9997H7.56696C4.05512 29.9997 1.42123 26.7664 2.10996 23.3014L6.34394 1.99968H14.6922L17.475 6.89968L11.5129 11.1907L9.82239 8.99968M11.5199 11.1907L14.6922 22.9997"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Icon8: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M7.75 8.22222H24.25M16 16C13.6131 16 11.3239 16.9833 9.63604 18.7337C7.94821 20.484 7 22.858 7 25.3333V28.4444C7 28.857 7.15804 29.2527 7.43934 29.5444C7.72064 29.8361 8.10218 30 8.5 30H23.5C23.8978 30 24.2794 29.8361 24.5607 29.5444C24.842 29.2527 25 28.857 25 28.4444V25.3333C25 22.858 24.0518 20.484 22.364 18.7337C20.6761 16.9833 18.3869 16 16 16ZM16 16C13.6131 16 11.3239 15.0167 9.63604 13.2663C7.94821 11.516 7 9.14202 7 6.66667V3.55556C7 3.143 7.15804 2.74733 7.43934 2.45561C7.72064 2.16389 8.10218 2 8.5 2H23.5C23.8978 2 24.2794 2.16389 24.5607 2.45561C24.842 2.74733 25 3.143 25 3.55556V6.66667C25 9.14202 24.0518 11.516 22.364 13.2663C20.6761 15.0167 18.3869 16 16 16Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Icon9: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M20.6687 14.4453V14.4608M5.38433 10.3663C4.7021 9.84196 4.17813 9.1392 3.87033 8.33568C3.56253 7.53217 3.48289 6.65919 3.64021 5.81325C3.79752 4.9673 4.18567 4.18132 4.76176 3.54218C5.33784 2.90304 6.07943 2.43562 6.90455 2.1916C7.72967 1.94757 8.6062 1.93643 9.43725 2.15941C10.2683 2.3824 11.0215 2.83082 11.6137 3.45512C12.2058 4.07941 12.6138 4.85528 12.7926 5.69695C12.9713 6.53863 12.9139 7.41334 12.6266 8.22442M22.2242 3.55699V9.47245C24.1493 10.5864 25.6149 12.3492 26.3586 14.4453H28.4445C28.8571 14.4453 29.2527 14.6092 29.5444 14.9009C29.8361 15.1926 30 15.5882 30 16.0008V19.1117C30 19.5242 29.8361 19.9199 29.5444 20.2116C29.2527 20.5033 28.8571 20.6672 28.4445 20.6672H26.3571C25.8344 22.1449 24.9463 23.467 23.7781 24.5139V27.6668C23.7781 28.2856 23.5323 28.8791 23.0947 29.3166C22.6572 29.7542 22.0637 30 21.4449 30C20.8261 30 20.2326 29.7542 19.7951 29.3166C19.3575 28.8791 19.1117 28.2856 19.1117 27.6668V26.76C18.5977 26.8461 18.0774 26.8893 17.5562 26.8891H11.3343C10.8132 26.8893 10.2929 26.8461 9.77886 26.76V27.6668C9.77886 28.2856 9.53304 28.8791 9.09548 29.3166C8.65792 29.7542 8.06446 30 7.44566 30C6.82685 30 6.23339 29.7542 5.79583 29.3166C5.35827 28.8791 5.11245 28.2856 5.11245 27.6668V24.5139C3.70313 23.2539 2.70967 21.5956 2.26357 19.7586C1.81748 17.9215 1.93977 15.9923 2.61427 14.2263C3.28877 12.4603 4.48367 10.9408 6.04081 9.86883C7.59795 8.79689 9.4439 8.2231 11.3343 8.22341H15.223L22.2226 3.55699H22.2242Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  )
}


export {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9
}
