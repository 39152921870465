import React, {useEffect, useRef} from "react";
import styles from "./CustomMealPlanFormatScreen.module.css";
import StepInfo from "../StepInfo";
import CustomMealPlanItem from "./CustomMealPlanItem/CustomMealPlanItem";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {MealTime, MealTimeNames, OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {setCustomMealPlan} from "../../../store/OnBoarding/OnBoardingSlice";
import NextButton from "../../NextButton/NextButton";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";
import {useHistory} from "react-router-dom";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

const CustomMealPlanFormatScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {customMealTime} = useAppSelector(state => state.onBoarding);

  const selectMeal = (time: MealTime) => {
    dispatch(setCustomMealPlan(time));
  };

  const onPressNext = () => {
    const names: string[] = []
    customMealTime.forEach(el => {
      names.push(MealTimeNames[el - 1]);
    })
    const eventParams = {
      Meal_plan_custom_list: names.join(' '),
    };
    mixpanel.track('custom_meal_plan_set', eventParams);
    logEvent(analitics, 'custom_meal_plan_set', eventParams)
    history.push(OnBoardingRoutesEnum.PRIORITIES_PAGE)
  }

  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 60 : 'calc(100dvh - 60px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <StepInfo title="Meals"
                  description="Select the meals would you like to plan"/>
        <div className={styles.listContainer}>
          <div className={styles.listData}>
            <CustomMealPlanItem
              name={'Breakfast'}
              selected={customMealTime.includes(MealTime.BREAKFAST)}
              onClick={() => selectMeal(MealTime.BREAKFAST)}
              image={require('../../../assets/images/mealPlan/breakfast.webp')}
            />
            <CustomMealPlanItem
              name={'Lunch'}
              selected={customMealTime.includes(MealTime.LUNCH)}
              onClick={() => selectMeal(MealTime.LUNCH)}
              image={require('../../../assets/images/mealPlan/lunch.webp')}
            />
            <CustomMealPlanItem
              name={'Dinner'}
              selected={customMealTime.includes(MealTime.DINNER)}
              onClick={() => selectMeal(MealTime.DINNER)}
              image={require('../../../assets/images/mealPlan/dinner.webp')}
            />
          </div>
        </div>
      </div>
      <div>
        <NextButton fixed={true} withGradient={true} disabled={customMealTime.length === 0} onClick={onPressNext}/>
      </div>
    </div>
  )
}

export default CustomMealPlanFormatScreen
