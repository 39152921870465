import {IconProps} from "../types/types";
import React from "react";

const CloseIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.864 3.19732C14.1569 2.90443 14.1569 2.42956 13.864 2.13666C13.5711 1.84377 13.0962 1.84377 12.8033 2.13666L13.864 3.19732ZM2.13666 12.8033C1.84377 13.0962 1.84377 13.5711 2.13666 13.864C2.42956 14.1569 2.90443 14.1569 3.19732 13.864L2.13666 12.8033ZM3.19732 2.13666C2.90443 1.84377 2.42956 1.84377 2.13666 2.13666C1.84377 2.42956 1.84377 2.90443 2.13666 3.19732L3.19732 2.13666ZM12.8033 13.864C13.0962 14.1569 13.5711 14.1569 13.864 13.864C14.1569 13.5711 14.1569 13.0962 13.864 12.8033L12.8033 13.864ZM12.8033 2.13666L2.13666 12.8033L3.19732 13.864L13.864 3.19732L12.8033 2.13666ZM2.13666 3.19732L12.8033 13.864L13.864 12.8033L3.19732 2.13666L2.13666 3.19732Z"
        fill={fill ? fill : "white"}/>
    </svg>

  )
}

export default CloseIcon
