import React, {forwardRef, RefObject} from "react";
import styles from "./StepInfo.module.css";

interface StepInfoProps {
  title: string;
  description?: string;
  ref?: RefObject<HTMLDivElement>;
  varTwo?: boolean
}

const StepInfo: React.ForwardRefRenderFunction<HTMLDivElement, StepInfoProps> = ({title, varTwo, description}, ref) => {
  return (
    <div className={[styles.stepInfoText, varTwo ? styles.stepInfoText2 : null].join(' ')} ref={ref}>
      <h1 className={[styles.title, varTwo ? styles.title2 : null].join(' ')}>{title}</h1>
      {description ? (
        <h2 className={[styles.description, varTwo ? styles.description2 : null].join(' ')}>
          {description}
        </h2>
      ) : null}
    </div>
  )
}

export default forwardRef(StepInfo)
