import React, {useEffect, useRef, useState} from "react";
import styles from "./NumberField.module.css";
import {useAppDispatch} from "../../store/types";
import {setKeyboard} from "../../store/Options/OptionsSlice";

interface NumberFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  error: string;
  showError?: boolean;
  full?: boolean;
  autoFocus?: boolean;
  onPressEnter?: () => void
}

const NumberField: React.FC<NumberFieldProps> = ({
  label,
  value,
  onChange,
  maxLength,
  full,
  error,
  showError = true,
  onPressEnter,
  autoFocus = true
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  function onLabelPress(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }
  function onKeyPressEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && onPressEnter) {
      onPressEnter()
    }
  }

  const [search, setSearch] = useState(false);
  useEffect(() => {
    if (inputRef.current) {
      if (search) {
        inputRef.current.focus();
      } else {
        inputRef.current.blur();
      }
    }
  }, [search]);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };


  useEffect(() => {
    // Try to focus the input when the component mounts


    // Initial attempt to focus
    setTimeout(() => {
      // setSearch(true);
    }, 500)
  }, []);
  return (
    <>
      <div ref={labelRef} onClick={onLabelPress} className={[styles.NumberFieldLabel, error ? styles.hasError : null].join(' ')}
           style={{
             width: full ? '100%' : 'auto',
           }}
      >
        <input
          ref={inputRef}
          className={[styles.NumberField, error ? styles.hasError : null].join(' ')}
          type="number"
          inputMode="numeric"
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
          onFocus={() => dispatch(setKeyboard(true))}
          onBlur={() => dispatch(setKeyboard(false))}
          max={999}
          maxLength={maxLength}
          onKeyDown={onKeyPressEnter}
        />
        <span className={styles.label}>{label}</span>
      </div>
      {error && showError ? (
        <div className={styles.error}>
          {error}
        </div>
      ) : null}
    </>
  )
}

export default NumberField
