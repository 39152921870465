import React, {useEffect, useRef, useState} from 'react';
import styles from './LoseTwice.module.css'
import {useScreenHeight} from "../../../context/ScreenHeightContext";
import NextButton from "../../NextButton/NextButton";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useHistory} from "react-router-dom";

const LoseTwice: React.FC = () => {
  const {screenHeight, isWebView} = useScreenHeight()
  const [colOneHeight, setColOneHeight] = useState(0)
  const [colTwoHeight, setColTwoHeight] = useState(0)
  const [imageOpacity, setImageOpacity] = useState(0)
  const history = useHistory();
  const nextPage = () => {
    history.push(OnBoardingRoutesEnum.COOKING_SKILL);
  }

  useEffect(() => {
    setColOneHeight(100)
    setColTwoHeight(200)
    const timeout = setTimeout(() => {
      setImageOpacity(1)
    }, 1500)
    return () => clearTimeout(timeout)
  }, []);

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);

  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 60 : 'calc(100dvh - 60px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.listData}>
          <div className={styles.title}>
            {window.screen.width <= 540 ? (
              <>
                <div>Lose twice as much</div>
                <div>weight with Eatr vs.</div>
                <div>doing it on your own</div>
              </>
              ) : (
              <>
                Lose twice as much weight
                <div>with Eatr vs. doing it on your own</div>
              </>
            )}
          </div>
          <div className={styles.animationContainer}>
            <div className={[styles.columnOne, styles.columnStyle].join(' ')} style={{
              height: colOneHeight
            }}>
              <div className={styles.textInfoContainer}>
                <div>Without</div>
                <div>Eatr</div>
              </div>
            </div>
            <div className={styles.col}>
              <img className={styles.kissImage} style={{
                opacity: imageOpacity
              }} src={require('../../../assets/icons/kiss.png')} alt="kiss"/>
              <div className={[styles.columnTwo, styles.columnStyle].join(' ')} style={{
                height: colTwoHeight
              }}>
                <div className={styles.textInfoContainer}>
                  <div>With</div>
                  <div>Eatr</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.description}>
            78% of our users say that Eatr has helped them achieve sustainable weight loss results.
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextPage}/>
    </div>
  )
}

export default LoseTwice
