import React, {useEffect, useRef} from "react";
import styles from './Speed.module.css'
import {useAppDispatch, useAppSelector} from "../../../store/types";
import SpeedButton from "./SpeedButton/SpeedButton";
import {setMode} from "../../../store/OnBoarding/OnBoardingSlice";
import {OnBoardingRoutesEnum, SpeedModeList, SpeedModeListDesc} from "../../../enums/onBoardingEnum";
import GradualSpeedIcon from "../../../icons/GradualSpeedIcon";
import ModerateSpeedIcon from "../../../icons/ModerateSpeedIcon";
import AggressiveSpeedIcon from "../../../icons/AggressiveSpeedIcon";
import {useTDEE} from "../../../hooks/useTDEE";
import Legend from "./Lagend/Legend";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import NextButton from "../../NextButton/NextButton";
import {useHistory} from "react-router-dom";
import {MayorSystem} from "../../../store/Options/OptionsSlice";
import {lb} from "../../../hooks/useConvert";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

const Speed: React.FC = () => {
  const dispatch = useAppDispatch();
  const {mayorSystem} = useAppSelector(state => state.settings);
  const history = useHistory()
  const {targetWeight, mode, imperialTargetWeight} = useAppSelector(state => state.onBoarding);
  const {calories, month, kg, looseWeight} = useTDEE(mode);

  const nextPage = () =>{
    history.push(OnBoardingRoutesEnum.SUMMARY_PAGE);
  }

  const gInLbInMonth = () => {
    return Math.floor((kg * 1000) / lb);
  };

  const gInLb = () => {
    return Math.floor((Number(targetWeight) * 1000) / lb);
  };
  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 68 : 'calc(100dvh - 68px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.pageInfo}>
          <h1 className={styles.title}>Speed</h1>
          <h2 className={styles.description}>
            To reach your goal of <span
            className={styles.weight}>{mayorSystem === MayorSystem.METRIC ? targetWeight : imperialTargetWeight}</span> {mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'},
            please select how quickly you
            would like to proceed.
          </h2>
          <h2 className={styles.description}>
            Don’t worry, you can always adjust your plan later if needed.
          </h2>
        </div>
        <div className={styles.speedButtonsContainer}>
          <SpeedButton
            active={mode === SpeedModeList.GRADUAL}
            Icon={GradualSpeedIcon}
            onPress={() => dispatch(setMode(SpeedModeList.GRADUAL))}/>
          <SpeedButton
            active={mode === SpeedModeList.MODERATE}
            Icon={ModerateSpeedIcon}
            onPress={() => dispatch(setMode(SpeedModeList.MODERATE))}/>
          <SpeedButton
            active={mode === SpeedModeList.AGGRESSIVE}
            Icon={AggressiveSpeedIcon}
            onPress={() => dispatch(setMode(SpeedModeList.AGGRESSIVE))}/>
        </div>
        <div style={{
          paddingLeft: 42,
          paddingRight: 42
        }}>
          <div
            className={styles.mode}>
            {mode}
          </div>
          <div className={styles.modeDescription}>
            {SpeedModeListDesc[mode]}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.text}>
            Given your physical characteristics and activity level:
          </div>
          <Legend
            color={ColorsEnum.PROTEINS}
            info={'kcal'}
            label={'Your target calorie intake'}
            value={calories}
          />
          <Legend
            color={ColorsEnum.FATS}
            info={'months'}
            label={'Reach your goal in'}
            value={month}
          />
          <Legend
            color={ColorsEnum.CABS}
            info={mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb'}
            label={`${looseWeight ? 'Lose' : 'Gain'} every month`}
            value={mayorSystem === MayorSystem.METRIC ? kg : gInLbInMonth()}
          />
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextPage}/>
    </div>
  )
}

export default Speed
