import React, {FormEvent, useEffect, useState} from 'react';
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useAppDispatch, useAppSelector} from "../../../../../store/types";
import useSubscribe from "../../../../../hooks/useSubscribe";
import styles from "./Card.module.css";
import Skeleton from "react-loading-skeleton";
import ExpIcon from "../../../../../icons/ExpIcon";
import {Oval} from "react-loader-spinner";
import {useHistory} from "react-router-dom";
import {setClearOnboarding} from "../../../../../store/OnBoarding/OnBoardingSlice";
import {createSubscription} from "../../../../../store/Subscription/SubscriptionSlice";
import {ReactComponent as CheckboxChecked} from "../../../../../assets/icons/CheckboxChecked.svg";
import {ReactComponent as CheckboxUnchecked} from "../../../../../assets/icons/CheckboxUnchecked.svg";
import {ReactComponent as LockIcon} from "../../../../../assets/icons/LockIcon.svg";
import {logoutUser} from "../../../../../store/UserSlice/UserSlice";
import {useSubscriptionData} from "../../../../../hooks/useSubscriptionData";

const options = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Nunito Sans", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "transparent"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const Card: React.FC = () => {
  const [applyTerms, setApplyTerms] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const {couponsList} = useSubscriptionData()
  const history = useHistory();
  const {selectedProduct, couponIndex} = useAppSelector(state => state.products)
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [isCardNumberLoading, setCardNumberLoading] = useState<boolean>(false)
  const [isCardExpiryLoading, setCardExpiryLoading] = useState<boolean>(false)
  const [isCardCvcLoading, setCardCvcLoading] = useState<boolean>(false)
  const [cardNumberError, setCardNumberError] = useState<string>('')
  const [cardExpiryError, setCardExpiryError] = useState<string>('')
  const [cardCvcError, setCardCvcError] = useState<string>('');
  const [visibility, setVisibility] = useState<boolean>(false);
  const {clientSecret, subscriptionName, items} = useAppSelector(state => state.subscription)
  const [active, setActive] = useState(false);
  const [processing, setProcessing] = useState(false);

  const elements = useElements();
  const stripe = useStripe();
  const {errorPay, successPay, errorMessage, sendEventPaymentStartWeb} = useSubscribe()
  useEffect(() => {
    setApplyTerms(false)
  }, [couponIndex]);
  useEffect(() => {
    if (elements) {
      const elementCvc = elements.getElement('cardCvc');
      const elementExpiry = elements.getElement('cardExpiry');
      const elementNumber = elements.getElement('cardNumber');
      setCardCvcLoading(true)
      setCardExpiryLoading(true)
      setCardNumberLoading(true)
      elementExpiry?.on('ready', () => {
        setCardCvcLoading(false)
      })
      elementCvc?.on('ready', () => {
        setCardExpiryLoading(false)
      })
      elementNumber?.on('ready', () => {
        setCardNumberLoading(false)
      })
    }
  }, [elements]);

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();
    if (!stripe || !elements || !clientSecret) {
      return;
    }
    const cardNumber = elements.getElement("cardNumber")

    if (!cardNumber) {
      return
    }

    setProcessing(true);

    let {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    });

    console.log(paymentMethod)
    if (error && error.message) {
      errorPay(error.message)
      return
    }
    if (paymentMethod && selectedProduct) {
      dispatch(createSubscription({
        priceId: selectedProduct.priceId,
        paymentMethodId: paymentMethod.id,
        couponId: couponsList[couponIndex].id
      })).then( async (redux) => {
        if (redux.meta.requestStatus === 'fulfilled' && paymentMethod && redux.payload) {
          const response = redux.payload
          if (typeof response !== 'string') {
            let {error, paymentIntent} = await stripe.confirmCardPayment(
              response.client,
              {
                payment_method: paymentMethod.id,
              }
            );
            if (error) {
              return;
            }
            if (paymentIntent?.status === 'succeeded') {
              setProcessing(false);
              successPay().then(() => {
                 dispatch(setClearOnboarding())
                 dispatch(logoutUser())
                history.push('/complete')
              })
            } else {
              setProcessing(false);
            }
          }
        }
        if (redux.meta.requestStatus === 'rejected') {
          const response = redux.payload;
          if (typeof response === 'string') {
            errorPay(response)
            setProcessing(false);
          }
        }
      })

    }
    // setPaymentIntent(setupIntent);
    // dispatch(clearState())
  }

  let timeout: NodeJS.Timeout;

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <div>
        <label className={styles.field}>
          <div className={styles.label}>
            Card number
          </div>
          {isCardNumberLoading ? (
            <Skeleton borderRadius={12} height={48}/>
          ) : null}
          <div className={[styles.input, cardNumberError.length > 0 ? styles.error : ''].join(' ')}
               style={{
                 display: isCardNumberLoading ? 'none' : 'block'
               }}
          >
            <CardNumberElement options={options} onChange={(e) => {
              if (!canSubmit) {
                sendEventPaymentStartWeb('Card')
              }
              setCanSubmit(true);
              if (e.error) {
                setCardNumberError(e.error.message);
              } else {
                setCardNumberError('');
              }
            }}/>
          </div>
          <div className={[styles.error, styles.message].join(' ')}>
            {cardNumberError}
          </div>
        </label>
      </div>
      <div className={styles.row}>
        <label className={styles.field}>
          <div className={styles.label}>
            Expiration date
          </div>
          {isCardExpiryLoading ? (
            <Skeleton borderRadius={12} height={48}/>
          ) : null}
          <div className={[styles.input, cardExpiryError.length > 0 ? styles.error : ''].join(' ')}
               style={{
                 display: isCardExpiryLoading ? 'none' : 'block'
               }}
          >
            <CardExpiryElement options={options} onChange={(e) => {
              if (!canSubmit) {
                sendEventPaymentStartWeb('Card')
              }
              setCanSubmit(true);
              if (e.error) {
                setCardExpiryError(e.error.message);
              } else {
                setCardExpiryError('');
              }
            }}/>
          </div>
          <div className={[styles.error, styles.message].join(' ')}>
            {cardExpiryError}
          </div>
        </label>
        <label className={styles.field}>
          <div className={styles.label}>
            Security code
          </div>
          {isCardCvcLoading ? (
            <Skeleton borderRadius={12} height={48}/>
          ) : null}
          <div className={[styles.input, cardCvcError.length > 0 ? styles.error : ''].join(' ')} style={{
            display: isCardCvcLoading ? "none" : "flex",
            padding: '10px 14px'
          }}>
            <div style={{
              width: '100%',
            }}>
              <CardCvcElement options={options} onChange={(e) => {
                if (!canSubmit) {
                  sendEventPaymentStartWeb('Card')
                }
                setCanSubmit(true);
                if (e.error) {
                  setCardCvcError(e.error.message);
                } else {
                  setCardCvcError('');
                }
              }}/>
            </div>
            <div style={{
              position: 'relative'
            }}>
              <div onMouseEnter={showTip}
                   onMouseLeave={hideTip}>
                <ExpIcon/>
              </div>
              {active && (
                <div className={`Tooltip-Tip ${"top"}`}>
                  CVV is the last three digits on the back of your credit card
                </div>
              )}
            </div>
          </div>
          <div className={[styles.error, styles.message].join(' ')}>
            {cardCvcError}
          </div>
        </label>
      </div>
      <div className={styles.formInfo}>
        {errorMessage && <div className={styles.error} style={{
          paddingBottom: 8,
          textAlign: 'center'
        }}>{errorMessage}</div>}
        <button
          disabled={!applyTerms || !canSubmit || processing || !stripe || !elements || cardNumberError.length > 0 || cardExpiryError.length > 0 || cardCvcError.length > 0}
          className={styles.button}>
          {processing ? (
            <Oval
              strokeWidth="4"
              color={'white'}
              secondaryColor={'rgba(255, 255, 255, 0.25)'}
              width="30"
              height="30"
              visible={true}
            />
          ) : <span className={styles.buttonTextContainer}><LockIcon /><span className={styles.buttonText}>Confirm purchase</span></span>}
        </button>
        <div className={styles.checkContainer} onClick={() => setApplyTerms(!applyTerms)}>
          <div className={styles.checkBox}>
            {applyTerms ? <CheckboxChecked /> : <CheckboxUnchecked />}
          </div>
          <div className={styles.checkText}>I give explicit consent for Eatr to store my payment details for seamless future transactions</div>
        </div>
      </div>
      <div>
        <div className={styles.text}>
          Your payment information is secure with SSL/TLS encryption
        </div>
        <div className={styles.formImages}>
          <div>
            <img src={require('../../../../../assets/images/ssl-img-1.webp')} alt=""/>
          </div>
          <div>
            <img src={require('../../../../../assets/images/visasecure-1.webp')} alt=""/>
          </div>
          <div>
            <img src={require('../../../../../assets/images/mastercard-id-check-1.webp')} alt=""/>
          </div>
          <div>
            <img src={require('../../../../../assets/images/mastercardsecurecode-2.webp')} alt=""/>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Card
