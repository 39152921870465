import React, {useEffect, useRef} from "react";
import styles from './Summary.module.css'
import NextButton from "../../NextButton/NextButton";
import {useHistory} from "react-router-dom";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import BMA from "./BMA";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

const Summary: React.FC<{}> = () => {
  const history = useHistory();
  const {screenHeight, isWebView} = useScreenHeight();

  const nextPage = () => {
    history.push(OnBoardingRoutesEnum.DIET)
  }

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 68 : 'calc(100dvh - 68px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.listData}>
          <div className={styles.title}>
            Personal summary based on your answers
          </div>
          <BMA />
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextPage}/>
    </div>
  )
}

export default Summary
