import React from "react";
import {IconProps} from "../types/types";
const DisableIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" stroke={fill ? fill : '#9D9E9C'} strokeWidth="1.5"/>
      <line x1="5.46613" y1="18.4661" x2="18.4661" y2="5.46613" stroke={fill ? fill : '#9D9E9C'} strokeWidth="1.51"/>
    </svg>
  )
}

export default DisableIcon
