import React, {useCallback, useEffect, useRef, useState} from "react";
import StepInfo from "../../OnBoarding/StepInfo";
import NextButton from "../../NextButton/NextButton";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {useHistory} from "react-router-dom";
import {
  loadProductCategories,
  setProductsCategories,
  setUserDislikedIngredients
} from "../../../store/OnBoarding/OnBoardingSlice";
import styles from './Dislikes.module.css'
import {ProductListElement, productsList} from './productsList';
import Chip from "./Chip/Chip";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";
import {cloneDeep} from "lodash";
import {useProductsTree} from "../../../hooks/useProductsTree";
import {ProductCategory, TreeNodeType} from "../../../types/onBoardingTypes";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {defHttp} from "../../../api";
import {useScreenHeight} from "../../../context/ScreenHeightContext";


const Dislikes: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch();
  const [dislikes, setDislikes] = useState<string[]>([]);
  const [commonProducts, setCommonProducts] = useState<ProductCategory[]>([])
  const userDislikes = useAppSelector(
    state => state.onBoarding.dislikedIngredient,
  );
  const [dislikedProductCategories, setDislikedProductCategories] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const { productsCategories, selectedProductsCategory} = useAppSelector(state => state.onBoarding)
  const {updateNodeSelection} = useProductsTree()
  const onPressSearch = () => {
    history.replace({
      pathname: '/onboarding/dislikes/search'
    });
  }

  useEffect(() => {
    const fetchCommonProducts = async () => {
      const response = await defHttp.get<{categories: ProductCategory[]}>('/api/v2/recipe/ingredient-categories', {
        params: {
          isCommon: true
        }
      })
      setCommonProducts(response.data.categories)
    }
    if (productsCategories.length === 0) {
      dispatch(loadProductCategories())
    }
    fetchCommonProducts()
  }, []);

  useEffect(() => {
    if (selectedProductsCategory) {
      const data: {id: number; name: string}[] = [];
      const fetchCategories = (node: TreeNodeType) => {
        if (node.selected) {
          if (node.children) {
            data.push({
              id: node.id,
              name: `All ${node.name.toLowerCase()}`,
            });
          } else {
            data.push({
              id: node.id,
              name: `${node.name}`,
            });
          }
        } else if (node.partial && node.children) {
          node.children.map(child => {
            fetchCategories(child);
          });
        }
      };
      selectedProductsCategory.map(primary => {
        fetchCategories(primary);
      });
      setDislikedProductCategories(data);
    }
  }, [selectedProductsCategory])

  const deselectProductCategory = (id: number) => {
    const selectedListData = cloneDeep(selectedProductsCategory.length > 0 ? selectedProductsCategory : productsCategories);
    const updatedData = selectedListData.map(node =>
      updateNodeSelection(node, id, false),
    );
    dispatch(setProductsCategories(updatedData));
  };

  const selectProductCategory = (id: number) => {
    const selectedListData = cloneDeep(selectedProductsCategory.length > 0 ? selectedProductsCategory : productsCategories);
    const updatedData = selectedListData.map(node =>
      updateNodeSelection(node, id, true),
    );
    dispatch(setProductsCategories(updatedData));
  };

  useEffect(() => {
    if (userDislikes) {
      setDislikes(userDislikes);
    }
  }, [userDislikes]);

  function openProductsCategories() {
    history.push(OnBoardingRoutesEnum.DISLIKES_PRODUCTS)
  }

  function nextStep() {
    const eventParams = {
      Dislikes_amount: dislikedProductCategories.length,
      Disliked_products: dislikedProductCategories,
    };
    mixpanel.track('user_dislikes_set', eventParams);
    logEvent(analitics, 'user_dislikes_set', eventParams)
    history.push(OnBoardingRoutesEnum.CUISINES)
  }

  const getCommonImageByName = (name: string) => {
    const index = productsList.findIndex(el => el.name === name);
    if (index >= 0) {
      return productsList[index].image;
    }
  }

  function isParentSelected(tree: TreeNodeType, id: number): boolean {
    // Helper function to find a node and its parent
    function findNodeAndParent(
      node: TreeNodeType,
      id: number,
      parent: TreeNodeType | null = null,
    ): [TreeNodeType | null, TreeNodeType | null] {
      if (node.id === id) {
        return [node, parent];
      }
      if (node.children) {
        for (let child of node.children) {
          const result = findNodeAndParent(child, id, node);
          if (result[0]) {
            return result;
          }
        }
      }
      return [null, null];
    }

    const [node, parent] = findNodeAndParent(tree, id);
    if (node?.selected) {
      return true;
    }
    if (node && parent) {
      return parent.selected;
    } else {
      return false;
    }
  }

  const showIngredient = (id: number) => {
    let status = true;
    selectedProductsCategory.forEach((node: TreeNodeType) => {
      if (status) {
        const isParent = isParentSelected(node, id);
        if (isParent) {
          status = false;
        }
      }
    });
    return status;
  };

  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      minHeight: window.screen.width <= 540 ? isWebView ? screenHeight : 'calc(100dvh - 75px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div>
          <StepInfo title={'Dislikes'} description={'What products do you dislike or don\'t eat?'}/>
          <div onClick={onPressSearch} className={styles.searchButton}>
            <img className={styles.image} src={require('../../../assets/images/loop.png')} alt=""/>
            <div className={styles.text}>Search</div>
          </div>
          <div className={styles.listData}>
            <div>
              <div className={styles.chipList}>
                {dislikedProductCategories.map(category => {
                  return (
                    <Chip
                      text={category.name}
                      onPress={() => deselectProductCategory(category.id)}
                      active={true}
                      key={category.id}
                      color={ColorsEnum.DARK_20}
                      textColor={ColorsEnum.GRAY_SCALE_WHITE}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.defaultList}>
              <div className={styles.title}>Common products</div>
              <div className={styles.chipList}>
                {commonProducts.map((ingredient: ProductCategory, index: number) => {
                  if (showIngredient(ingredient.id)) {
                    return (
                      <Chip
                        selected={dislikes.includes(ingredient.name)}
                        image={getCommonImageByName(ingredient.name)}
                        text={ingredient.name}
                        onPress={value => selectProductCategory(ingredient.id)}
                        key={index}
                      />
                    );
                  } else {
                    return null
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextStep} subButton={true} subText={'All products'} subOnClick={openProductsCategories}/>
    </div>
  )
}

export default Dislikes
