import {combineReducers} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import onBoardingSlice from "./OnBoarding/OnBoardingSlice";
import userSlice from "./UserSlice/UserSlice";
import OptionsSlice from "./Options/OptionsSlice";
import ProductsSlice from "./Products/ProductsSlice";
import SubscriptionSlice from "./Subscription/SubscriptionSlice";

const reducer = combineReducers({
  onBoarding: onBoardingSlice,
  auth: userSlice,
  settings: OptionsSlice,
  products: ProductsSlice,
  subscription: SubscriptionSlice,
});

const storedState = localStorage.getItem('reduxState');
const persistedState = storedState
  ? JSON.parse(storedState)
  : {}

const store = configureStore({
  reducer,
  preloadedState: persistedState
});

localStorage.removeItem('reduxState')

store.subscribe(()=>{
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store
