import React from "react";
import styles from './CustomMealPlanItem.module.css'
import CheckIcon from "../../../../icons/CheckIcon";
import {ColorsEnum} from "../../../../enums/ColorsEnum";

interface CustomPlanItemProps {
  name: string;
  selected: boolean;
  onClick: () => void;
  image: any;
}

const CustomMealPlanItem: React.FC<CustomPlanItemProps> = ({
  name,
  selected,
  onClick,
  image
}) => {
  return (
    <div onClick={onClick} className={styles.container} style={{
      borderColor: selected ? ColorsEnum.DARK_20 : ColorsEnum.GRAY_SCALE_10
    }}>
      <div className={styles.mealContainer}>
        <img src={image} alt={name} className={styles.image} />
        <div className={styles.text}>
          {name}
        </div>
      </div>
      {selected ? (
        <div className={styles.iconContainer}>
          <CheckIcon fill={ColorsEnum.GRAY_SCALE_WHITE}/>
        </div>
      ) : null}
    </div>
  )
}

export default CustomMealPlanItem
