import React from 'react';
import styles from './ProductsList.module.css'
import {useAppSelector} from "../../../store/types";
import ProductItem from "./ProductItem/ProductItem";
import {ReactComponent as LikeProduct} from "../../../assets/like-product.svg";
import {useSubscriptionData} from "../../../hooks/useSubscriptionData";

const customProducts = [
  {
    oldValue: 2.99,
    perDay: '0.13',
  },
  {
    oldValue: 9.99,
    perDay: '0.10',
  },
  {
    oldValue: 19.99,
    perDay: '0.04',
  }
]

const ProductsList: React.FC = () => {
  const {products} = useAppSelector(state => state.products)
  const {weight, targetWeight} = useAppSelector(state => state.onBoarding)
  const {couponsList} = useSubscriptionData()
  return (
    <div>
      <div>
        {products.map((product, index) => <ProductItem index={index} key={product.id} coupon={couponsList[index]} additional={customProducts[index]}
                                                       product={product} mostPopular={index === products.length - 1}/>)}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.icon}>
          <LikeProduct />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.text}>
            {weight && targetWeight ? (weight > targetWeight ? 'People using Eatr for 3 months lose twice as much weight as for 1 month' : 'People using Eatr for 3 months save more than 16 hours on meal planning') : null}
          </div>
          <div className={styles.description}>
            *According to Eatr user research, 2023
          </div>
        </div>

      </div>
    </div>
  )
}

export default ProductsList
