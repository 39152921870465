import React, {useEffect, useMemo, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/types";
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import {SkillsList} from "./skills";
import styles from './CookingSkills.module.css'
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {setSkillLevel} from "../../../store/OnBoarding/OnBoardingSlice";
import {useHistory} from "react-router-dom";
import CheckIcon from "../../../icons/CheckIcon";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useScreenHeight} from "../../../context/ScreenHeightContext";


const CookingSkills: React.FC = () => {
  const selectedSkill = useAppSelector(state => state.onBoarding.skill);
  const dispatch = useAppDispatch();
  const history = useHistory()
  const disableButton = useMemo(() => {
    return selectedSkill === 0;
  }, [selectedSkill]);
  const nextPage = () => {
    const eventParams = {
      Cooking_skill_level: SkillsList[selectedSkill - 1].name,
    };
    mixpanel.track('user_cooking_skills_set', eventParams);
    logEvent(analitics, 'user_cooking_skills_set', eventParams)
    history.push(OnBoardingRoutesEnum.MEAL_PLAN)
  };

  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      minHeight: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 60 : 'calc(100dvh - 60px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <StepInfo title="Cooking skills"
                  description="How would you describe your cooking skills?"/>
        <div className={styles.listContainer}>
          <div className={styles.listData}>
            {SkillsList.map((el, index) => {
              return (
                <div onClick={() => dispatch(setSkillLevel(el.level))} key={index} className={styles.skill} style={{
                  borderColor: selectedSkill === el.level ? ColorsEnum.DARK_20 : ColorsEnum.GRAY_SCALE_90
                }}>
                  <div className={styles.imageContainer}>
                    <img className={styles.image} src={el.image} alt={el.name}/>
                  </div>
                  <div className={styles.infoContainer}>
                    <div
                      className={[styles.name, selectedSkill === el.level ? styles.selected : null].join(' ')}>{el.name}</div>
                    {selectedSkill === el.level ? (
                      <div className={styles.description}>{el.activeDescription}</div>
                    ) : (
                      <div className={styles.shortDesc}>{el.description}</div>
                    )}
                  </div>
                  {selectedSkill === el.level ? (
                    <div className={styles.selectedIcon}>
                      <CheckIcon fill={ColorsEnum.GRAY_SCALE_WHITE}/>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} disabled={disableButton} onClick={nextPage}/>
    </div>
  );
};

export default CookingSkills;
