import {IconProps} from "../types/types";
import React from "react";

const AngelDownIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9L12 15L18 9"
        stroke="#8DCC6F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}

export default AngelDownIcon
