import React from 'react';
import styles from './styles.module.css';
import {ColorsEnum} from "../../../../enums/ColorsEnum";
import CheckIcon from "../../../../icons/CheckIcon";

interface GoalItemInterface {
  name: string;
  id: number;
}

interface GoalItemProps {
  item: GoalItemInterface;
  selected: boolean;
  onClick: (value: string) => void;
  Icon: any;
}

const GoalItem: React.FC<GoalItemProps> = ({
 item,
 selected,
 onClick,
 Icon,
}) => {
  return (
    <div className={[styles.goalItemContainer, selected ? styles.selected : ''].join(' ')} onClick={() => onClick(item.name)}>
      <div className={styles.goalItem}>
        <div className={styles.iconContainer}>
          <Icon fill={ColorsEnum.DARK_20} />
        </div>
        <div className={styles.itemText}>
          {item.name}
        </div>
      </div>
      {selected ? (
        <div className={styles.checkedContainer}>
          <CheckIcon fill={ColorsEnum.GRAY_SCALE_WHITE} />
        </div>
      ) : null}
    </div>
  )
}

export default GoalItem
