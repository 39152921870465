import React from "react";
import {IconProps} from "../types/types";
const AggressiveSpeedIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg viewBox="0 0 69 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3011_38663)">
        <path
          d="M44.7166 3.11303C43.9476 1.77558 42.018 1.77552 41.249 3.11292L18.3047 43.0137H67.6575L44.7166 3.11303Z"
          fill="#E6E7E6"
        />
        <path
          d="M43.8651 2.49021C43.4907 1.8326 42.5498 1.81301 42.1483 2.45446L37.5203 9.84942C37.1035 10.5154 37.5823 11.3799 38.368 11.3799H43.0387H47.2061C47.9732 11.3799 48.4547 10.5518 48.0751 9.88517L43.8651 2.49021Z"
          fill="white"
        />
        <path
          d="M67.6587 43.0137L43.8509 1.60531C43.4664 0.936588 42.5016 0.936558 42.1171 1.60526L22.6484 35.4618"
          stroke="#B5B6B5"
          strokeWidth="1.5"
          strokeMiterlimit="9.57"
          strokeLinecap="round"
        />
        <path
          d="M21.3389 8.22192L1.33594 43.0134H21.3407H41.3454L21.3389 8.22192Z"
          fill="#F3F3F3"
        />
        <path
          d="M22.452 9.78328C22.0803 9.11169 21.1218 9.092 20.7228 9.74776L16.8778 16.067C16.4724 16.7334 16.9521 17.5868 17.7321 17.5868H21.6198H25.0748C25.8366 17.5868 26.3187 16.7691 25.9498 16.1026L22.452 9.78328Z"
          fill="white"
        />
        <path
          d="M1.33594 43.0134L20.4832 9.71028C20.8652 9.04587 21.8219 9.04047 22.2113 9.70053L34.4948 30.5179"
          stroke="#B5B6B5"
          strokeWidth="1.5"
          strokeMiterlimit="9.57"
          strokeLinecap="round"
        />
        <path
          d="M43.5 2L43.5 59"
          stroke="#8DCC6F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M38.0926 49.2329L37.1568 43.4535L38.9097 42.6565M33.9685 36.4422L38.5581 36.6114L41.8311 41.328L48.7578 40.4345M48.7578 40.4345L47.3988 46.1292L43.9566 46.0023M48.7578 40.4345L45.4425 36.8654L44.4221 33.3808M53.3474 40.6038C53.0431 40.5926 52.7557 40.4609 52.5485 40.2378C52.3412 40.0147 52.2311 39.7184 52.2423 39.4141C52.2535 39.1098 52.3852 38.8224 52.6083 38.6151C52.8314 38.4079 53.1277 38.2978 53.432 38.309C53.7363 38.3202 54.0237 38.4519 54.231 38.675C54.4382 38.8981 54.5483 39.1944 54.5371 39.4987C54.5259 39.803 54.3942 40.0904 54.1711 40.2976C53.948 40.5049 53.6517 40.615 53.3474 40.6038Z"
          stroke="#9D9E9C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3011_38663">
          <rect
            width="68"
            height="60"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AggressiveSpeedIcon
