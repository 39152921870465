import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function CustomHistoryListener() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        if (location.pathname.includes('/onboarding/diet/')) {
          history.replace('/onboarding/diet/');
        }
      }
      // Add custom logic here
    });

  return () => {
    unlisten();
  };
}, [history]);

  return null;
}

export default CustomHistoryListener;
