import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "../Dislikes.module.css";
import SearchedIngredientItem from "../SearchedIngredientItem/SearchedIngredientItem";
import {setProductsCategories} from "../../../../store/OnBoarding/OnBoardingSlice";
import {useAppDispatch, useAppSelector} from "../../../../store/types";
import {defHttp} from "../../../../api";
import {useHistory} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import NextButton from "../../../NextButton/NextButton";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../../helper/Firebase";
import {ProductCategory, TreeNodeType} from "../../../../types/onBoardingTypes";
import {cloneDeep} from "lodash";
import {useProductsTree} from "../../../../hooks/useProductsTree";

const DislikesModal = () => {
  const userDislikes = useAppSelector(
    state => state.onBoarding.dislikedIngredient,
  );
  const {selectedProductsCategory, productsCategories} = useAppSelector(state => state.onBoarding)
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const [ingredients, setIngredients] = useState<ProductCategory[]>([]);
  const searchField = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const [dislikedProductCategories, setDislikedProductCategories] = useState<
    number[]
  >([]);
  const clear = () => {
    setSearch('');
    setIngredients([]);
  };

  const load = useCallback(async (value: string) => {
    const response = await defHttp.get<{categories: ProductCategory[]}>('/api/v2/recipe/ingredient-categories', {
      params: {
        name: value,
      },
    });
    return response.data.categories;
  }, []);

  useEffect(() => {
    if (selectedProductsCategory) {
      const data: number[] = [];
      const fetchCategories = (node: TreeNodeType) => {
        if (node.selected) {
          data.push(node.id);
        }
        if (node.children) {
          node.children.map(child => {
            fetchCategories(child);
          });
        }
      };
      selectedProductsCategory.map(primary => {
        fetchCategories(primary);
      });
      setDislikedProductCategories(data);
    }
  }, [selectedProductsCategory])

  const {updateNodeSelection} = useProductsTree()

  function selectIngredient(value: number) {
    let list = [...dislikedProductCategories];
    const selectedListData = cloneDeep(selectedProductsCategory.length > 0 ? selectedProductsCategory : productsCategories);
    const updatedData = selectedListData.map(node =>
      updateNodeSelection(node, value, !list.includes(value)),
    );
    dispatch(setProductsCategories(updatedData));
    // if (list.includes(value)) {
    //   list = list.filter(el => el !== value);
    // } else {
    //   list.push(value);
    // }
    // dispatch(setUserDislikedIngredients(list));
  }

  function onChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    setSearch(e.target.value);
    if (e.target.value.length >= 1) {
      load(e.target.value).then(data => {
        setIngredients(data);
      });
    } else {
      setIngredients([]);
    }
  }

  function nextStep() {
    const eventParams = {
      Dislikes_amount: userDislikes.length,
      Disliked_products: userDislikes,
    };
    mixpanel.track('user_dislikes_set', eventParams);
    logEvent(analitics, 'user_dislikes_set', eventParams)
    history.push('/onboarding/cuisines')
  }

  const onPressSearch = () => {
    history.replace({
      pathname: '/onboarding/dislikes',
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerData}>
        <div className={styles.searchContainer}>
          <label className={styles.searchButton}>
            <img className={styles.image} src={require('../../../../assets/images/loop.png')} alt=""/>
            <input ref={searchField} value={search} onChange={onChangeSearch} className={styles.inputSearch}
                   placeholder={'Search'}/>
            {search.length > 0 ? (
              <span onClick={() => clear()}>
            <img style={{
              zIndex: 5
            }} className={styles.image}
                 src={require('../../../../assets/images/searchGreen.png')} alt=""/>
              </span>
            ) : null}
          </label>
          <div onClick={onPressSearch} className={styles.done}>
            Done
          </div>
        </div>
        <div className={styles.listContainer}>
          {ingredients.length === 0 ? (
            <div className={styles.notFoundContainer} style={{
              paddingBottom: 25
            }}>
              <div className={styles.title}>No results found</div>
              <div className={styles.description}>Please try using another word or phrase</div>
            </div>
          ) : null}
          <div>
            {ingredients.map(ingredient => {
              return <SearchedIngredientItem
                name={ingredient.isParent ? `All ${ingredient.name.toLowerCase()}` : ingredient.name}
                key={ingredient.id}
                onPress={() => selectIngredient(ingredient.id)}
                selected={dislikedProductCategories.includes(ingredient.id)}
              />
            })}
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextStep}/>
    </div>
  )
}

export default DislikesModal;
