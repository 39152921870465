import React, {useEffect, useState} from "react";
import {ExpressCheckoutElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {useAppDispatch, useAppSelector} from "../../../../../store/types";
import styles from './ApplePay.module.css'
import {ReactComponent as CheckIcon} from "../../../../../assets/icons/CheckIcon.svg";
import {ReactComponent as CheckboxChecked} from "../../../../../assets/icons/CheckboxChecked.svg";
import {ReactComponent as CheckboxUnchecked} from "../../../../../assets/icons/CheckboxUnchecked.svg";
import {createSubscription} from "../../../../../store/Subscription/SubscriptionSlice";
import useSubscribe from "../../../../../hooks/useSubscribe";
import {useHistory} from "react-router-dom";
import {StripeExpressCheckoutElementClickEvent, StripeExpressCheckoutElementReadyEvent} from "@stripe/stripe-js";
import {setClearOnboarding} from "../../../../../store/OnBoarding/OnBoardingSlice";
import {logoutUser} from "../../../../../store/UserSlice/UserSlice";
import {useSubscriptionData} from "../../../../../hooks/useSubscriptionData";

const ApplePay: React.FC<{ appleShow?: boolean, setShowApple?: (status: boolean) => void }> = ({
                                                                                                 appleShow,
                                                                                                 setShowApple
                                                                                               }) => {
  const dispatch = useAppDispatch()
  const history = useHistory();
  const [applyTerms, setApplyTerms] = useState<boolean>(false)
  const {selectedProduct, couponIndex} = useAppSelector(state => state.products)
  const {clientSecret, subscriptionName, items} = useAppSelector(state => state.subscription)
  const [visibility, setVisibility] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const {couponsList} = useSubscriptionData()
  const {errorPay, successPay, sendEventPaymentStartWeb} = useSubscribe()
  useEffect(() => {
    setApplyTerms(false)
  }, [couponIndex]);

  const handleApplePay = async (ev: any) => {
    if (!stripe || !elements) {
      return;
    }
    const {error: submitError} = await elements.submit();
    if (submitError) {
      return;
    }
    if (stripe && clientSecret && selectedProduct) {
      dispatch(createSubscription({
        priceId: selectedProduct.priceId,
        // paymentMethodId: paymentMethod.id,
        couponId: couponsList[couponIndex].id
      })).then(async (redux) => {
        if (redux.meta.requestStatus === 'fulfilled' && redux.payload) {
          const response = redux.payload
          if (typeof response !== 'string') {
            let {error} = await stripe.confirmPayment({
              elements,
              clientSecret: response.client,
              confirmParams: {
                return_url: window.location.origin + '/complete'
              },
              redirect: 'if_required'
            });
            if (error && error.message) {
              errorPay(error.message)
              return;
            }
            if (!error) {
              successPay().then(() => {
                dispatch(setClearOnboarding())
                dispatch(logoutUser())
                history.push('/complete')
              })
            }
          }
        }

      })
    }
  }
  const onClick = ({resolve, expressPaymentType, ...event}: StripeExpressCheckoutElementClickEvent) => {
    let options = {}
    if (items && items.length > 0) {
      const item = items.length > 0 ? items[0] : null;
      if (item) {
        if (expressPaymentType === 'apple_pay') {
          sendEventPaymentStartWeb('Apple pay')
          options = {
            emailRequired: true,
            applePay: {
              recurringPaymentRequest: {
                paymentDescription: 'Eatr PRO:' + subscriptionName,
                managementURL: window.location.origin + '/billing',
                regularBilling: {
                  amount: 0,
                  label: subscriptionName,
                  recurringPaymentIntervalUnit: 'day',
                  recurringPaymentIntervalCount: item.trialPeriodDays,
                },
              }
            }
          };
        }
      } else if (expressPaymentType === 'paypal') {
        sendEventPaymentStartWeb('PayPal')
      }
    }

    resolve(options);
  };
  const onReady = ({availablePaymentMethods}: StripeExpressCheckoutElementReadyEvent) => {
    if (!availablePaymentMethods) {
      setVisibility(false);
      if (setShowApple) {
        setShowApple(false)
      }
    } else {
      setVisibility(true);
      if (setShowApple) {
        setShowApple(true)
      }
    }
  };
  return (
    <div className={styles.container} style={{
      display: appleShow ? 'block' : 'none'
    }}>
      <div className={styles.listContainer}>
        <div className={styles.listElement}>
          <div className={styles.checkIconContainer}>
            <CheckIcon/>
          </div>
          <div className={styles.listElementText}>
            Fast, convenient payment option
          </div>
        </div>
        <div className={styles.listElement}>
          <div className={styles.checkIconContainer}>
            <CheckIcon/>
          </div>
          <div className={styles.listElementText}>
            Keeps your financial info safe with end-to-end encryption
          </div>
        </div>
        <div className={styles.listElement}>
          <div className={styles.checkIconContainer}>
            <CheckIcon/>
          </div>
          <div className={styles.listElementText}>
            Safe with Apple Pay's advanced fraud protection
          </div>
        </div>
      </div>
      <div style={{
        position: 'relative'
      }}>
        {!applyTerms ? (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 52,
            backgroundColor: 'white',
            opacity: 0.75,
            zIndex: 80
          }}>
          </div>
        ) : null}
        <div style={{
          height: 52,
        }}>
          <ExpressCheckoutElement
            onClick={onClick}
            onConfirm={handleApplePay}
            onReady={onReady}
          />
        </div>
      </div>
      <div className={styles.checkContainer} onClick={() => setApplyTerms(!applyTerms)}>
        <div className={styles.checkBox}>
          {applyTerms ? <CheckboxChecked/> : <CheckboxUnchecked/>}
        </div>
        <div className={styles.checkText}>I give explicit consent for Eatr to store my payment details for seamless
          future transactions
        </div>
      </div>
      <div>
        <div className={styles.text}>
          Your payment information is secure with SSL/TLS encryption
        </div>
        <div className={styles.formImages}>
          <div>
            <img src={require('../../../../../assets/images/ssl-img-1.webp')} alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplePay;
