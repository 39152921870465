export enum SourcesLinks {
  BMR = 'https://www.diabetes.co.uk/bmr-calculator.html',
  TDEE = 'https://www.diabetes.co.uk/bmr-calculator.html',
  USDA = 'https://www.dietaryguidelines.gov/sites/default/files/2020-12/Dietary_Guidelines_for_Americans_2020-2025.pdf',
  WHO = 'https://www.who.int/news-room/fact-sheets/detail/healthy-diet',
  CALORIE_DEFICIT = 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8017325/',
  CALCULATION_OF_NUTRITIONAL_TARGETS = 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8017325/',
  FATS = 'https://www.cdc.gov/heartdisease/risk_factors.htm',
  SODIUM = 'https://www.cdc.gov/salt/index.htm',
  CHOLESTEROL = 'https://www.cdc.gov/cholesterol/index.htm',
  PESCATARIAN_DIET = 'https://www.healthline.com/nutrition/pescatarian-diet',
  FLEXITARIAN_DIET = 'https://health.clevelandclinic.org/what-is-the-flexitarian-diet/',
  VEGETARIAN_DIET = 'https://medlineplus.gov/ency/article/002465.htm',
  LOW_CARB_DIET = 'https://www.lipidjournal.com/article/S1933-2874(19)30267-3/fulltext',
  KETO_DIET = 'https://www.healthline.com/nutrition/ketogenic-diet-101',
  MEDITERRANEAN_DIET = 'https://my.clevelandclinic.org/health/articles/16037-mediterranean-diet',
  CLEAN_EATING_DIET = 'https://www.mayoclinichealthsystem.org/hometown-health/speaking-of-health/clean-eating-what-does-that-mean',
  PALEO_DIET = 'https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/paleo-diet/art-20111182',
  VEGAN_DIET = 'https://www.healthline.com/nutrition/vegan-diet-guide',
}
