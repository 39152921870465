import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {setUserAuth} from '../UserSlice/UserSlice';
import {RootState} from "../store";
import {
  InitialStateModel, ProductCategory,
  SaveUserConfigParams,
  SkillLevel,
  TreeNodeType,
  UserConfig, UserConfigNames
} from '../../types/onBoardingTypes';
import {
  Activities,
  Genders, LikedDislikedEnum, MealPlanFormatTypes, MealTime,
  RequestSpeedModeList,
  SpeedModeList,
  UserActivityLevel,
  UserGender
} from "../../enums/onBoardingEnum";
import {defHttp} from "../../api";
import {commonProductsList} from "../../components/OnBoarding/Dislikes/productsList";

const initialState: InitialStateModel = {
  deliciousMeals: 5,
  quickRecipes: 5,
  recipeVariety: 5,
  easyRecipes: 5,
  budgetFriendlyMeals: 5,
  user: null,
  goals: [],
  diet: null,
  dislikedIngredient: [],
  likedCuisines: [],
  dislikedCuisines: [],
  skill: SkillLevel.UNKNOWN,
  mode: SpeedModeList.GRADUAL,
  loading: false,
  age: null,
  weight: null,
  targetWeight: null,
  height: null,
  gender: null,
  activity: null,
  routeName: '',
  diets: [],
  format: null,
  imperialWeight: null,
  imperialTargetWeight: null,
  imperialHeightFt: null,
  imperialHeightInch: null,
  customMealTime: [],
  productsCategories: [],
  loadingProductsCategories: false,
  selectedProductsCategory: [],
  configProductsCategory: [],
  configProductsCategoryInfo: [],
};

export const loadProductCategories = createAsyncThunk<TreeNodeType[]>(
  'onBoarding/loadProductCategories',
  async () => {
    let data: TreeNodeType[] = [];
    const response = await defHttp.get<{categories: ProductCategory[]}>(
      '/api/v2/recipe/ingredient-categories-tree-without-ingredients',
    );
    const list: ProductCategory[] = [];
    [...response.data.categories].forEach(cat => {
      const index = commonProductsList.findIndex(el => el.name === cat.name);
      if (index >= 0) {
        list.push(cat);
      }
    });

    const updateList = (category: ProductCategory) => {
      let data: TreeNodeType = {
        id: category.id,
        name: category.name,
        selected: false,
        partial: false,
      };

      if (category.categoryImageUrl) {
        data.image = category.categoryImageUrl;
      }
      if (category.children) {
        data.children = category.children.map(child => updateList(child));
      }

      return data;
    };
    data = list.map(primary => updateList(primary));
    return data;
  },
);

export const fetchDietsList = createAsyncThunk(
  'onBoarding/fetchDietsList',
  async () => {
    const response = await defHttp.get('/api/v2/recipe/diets');
    return response.data.diets;
  },
);

export const saveOnBoardingData = createAsyncThunk<
  Promise<string[]>,
  string,
  {state: RootState}
>('onBoarding/save', async (userId: string, {getState, dispatch}) => {
  const state = getState();
  const currentState = state.onBoarding;
  const email = state.auth.email || '';
  const params: SaveUserConfigParams = {
    appleId: userId,
    email: email,
    skill: currentState.skill,
    userConfigs: [],
    age: currentState.age || 1,
    currentWeight: currentState.weight || 1,
    targetWeight: currentState.targetWeight || 1,
    height: currentState.height || 1,
  };

  params.userConfigs.push({
    name: 'deliciousMeals',
    item: currentState.deliciousMeals.toString(),
  });

  params.userConfigs.push({
    name: 'quickRecipes',
    item: currentState.quickRecipes.toString(),
  });

  params.userConfigs.push({
    name: 'recipeVariety',
    item: currentState.recipeVariety.toString(),
  });

  params.userConfigs.push({
    name: 'easyRecipes',
    item: currentState.easyRecipes.toString(),
  });

  params.userConfigs.push({
    name: 'budgetFriendlyMeals',
    item: currentState.budgetFriendlyMeals.toString(),
  });

  switch (currentState.activity) {
    case Activities.LOW:
      params.activity = UserActivityLevel.LOW;
      break;
    case Activities.MEDIUM:
      params.activity = UserActivityLevel.MEDIUM;
      break;
    case Activities.HIGH:
      params.activity = UserActivityLevel.HIGH;
      break;
    case Activities.VERY_HIGH:
      params.activity = UserActivityLevel.VERY_HIGH;
      break;
    case Activities.EXTRA:
      params.activity = UserActivityLevel.EXTRA;
      break;
  }
  switch (currentState.gender) {
    case Genders.MALE:
      params.gender = UserGender.MALE;
      break;
    case Genders.FEMALE:
      params.gender = UserGender.FEMALE;
      break;
    case Genders.OTHER:
      params.gender = UserGender.OTHER;
      break;
    case Genders.PREFER_NOT_SAYING:
      params.gender = UserGender.PREFER_NOT_TO_SAY;
      break;
  }
  switch (currentState.mode) {
    case SpeedModeList.GRADUAL:
      params.speed = RequestSpeedModeList.GRADUAL;
      break;
    case SpeedModeList.MODERATE:
      params.speed = RequestSpeedModeList.MODERATE;
      break;
    case SpeedModeList.AGGRESSIVE:
      params.speed = RequestSpeedModeList.AGGRESSIVE;
      break;
  }

  if (currentState.diet) {
    params.userConfigs.push({
      name: UserConfigNames.DIET,
      item: currentState.diet,
    });
  }
  const configProductsCategory: number[] = [];
  if (currentState.selectedProductsCategory.length > 0) {
    const fetchCategories = (node: TreeNodeType) => {
      if (node.selected) {
        params.userConfigs.push({
          name: UserConfigNames.DISLIKED_INGREDIENT_CATEGORY_ID,
          item: node.id.toString(),
        });
        configProductsCategory.push(node.id);
      } else if (node.partial && node.children) {
        node.children.map(child => {
          fetchCategories(child);
        });
      }
    };
    currentState.selectedProductsCategory.map(primary => {
      fetchCategories(primary);
    });
  }
  if (currentState.format) {
    switch (currentState.format) {
      case MealPlanFormatTypes.DINNER:
        params.userConfigs.push({
          name: UserConfigNames.MEAL,
          item: MealTime.DINNER.toString(),
        });
        break;
      case MealPlanFormatTypes.LUNCH_AND_DINNER:
        params.userConfigs.push(
          {
            name: UserConfigNames.MEAL,
            item: MealTime.LUNCH.toString(),
          },
          {
            name: UserConfigNames.MEAL,
            item: MealTime.DINNER.toString(),
          },
        );
        break;
      case MealPlanFormatTypes.ALL_MEALS:
        params.userConfigs.push(
          {
            name: UserConfigNames.MEAL,
            item: MealTime.BREAKFAST.toString(),
          },
          {
            name: UserConfigNames.MEAL,
            item: MealTime.LUNCH.toString(),
          },
          {
            name: UserConfigNames.MEAL,
            item: MealTime.DINNER.toString(),
          },
        );
        break;
      case MealPlanFormatTypes.CUSTOM:
        if (currentState.customMealTime.length > 0) {
          currentState.customMealTime.map(el => {
            params.userConfigs.push({
              name: UserConfigNames.MEAL,
              item: el.toString(),
            });
          });
        }
        break;
    }
  }
  if (currentState.goals.length > 0) {
    currentState.goals.forEach(el => {
      params.userConfigs.push({
        name: UserConfigNames.GOAL,
        item: el,
      });
    });
  }
  if (currentState.dislikedIngredient.length > 0) {
    currentState.dislikedIngredient.forEach(el => {
      params.userConfigs.push({
        name: UserConfigNames.DISLIKED_INGREDIENT,
        item: el,
      });
    });
  }

  if (currentState.likedCuisines.length > 0) {
    currentState.likedCuisines.forEach(el => {
      params.userConfigs.push({
        name: UserConfigNames.LIKED_CUISINE,
        item: el,
      });
    });
  }

  if (currentState.dislikedCuisines.length > 0) {
    currentState.dislikedCuisines.forEach(el => {
      params.userConfigs.push({
        name: UserConfigNames.DISLIKED_CUISINE,
        item: el,
      });
    });
  }

  const response = await defHttp.post('/api/v2/recipe/user', params);

  if (response.data) {
    dispatch(setConfigProductsCategory(configProductsCategory));
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    dispatch(setUserAuth(response.data));
  }
  return response.data;
});

const onBoardingSlice = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: {
    clearCustomMealPlanFormat: state => {
      state.customMealTime = [];
    },
    setCustomMealPlan: (state, action: {payload: MealTime}) => {
      const oldPlan = [...state.customMealTime];
      if (oldPlan.includes(action.payload)) {
        state.customMealTime = oldPlan.filter(el => el !== action.payload);
      } else {
        oldPlan.push(action.payload);
        state.customMealTime = oldPlan;
      }
    },
    setFormatType: (state, action: {payload: MealPlanFormatTypes}) => {
      state.format = action.payload;
    },
    setUserConfig: (state, {payload}: {payload: UserConfig}) => {
      const dislikedCuisines: string[] = [];
      const likedCuisines: string[] = [];
      const dislikedIngredients: string[] = [];
      const goals: string[] = [];
      const customMealTime: number[] = [];
      const configProductsCategory: number[] = [];
      let diet = null;
      payload.userConfigs.forEach(el => {
        if (el.name === UserConfigNames.MEAL) {
          customMealTime.push(Number(el.item));
        }
        if (el.name === UserConfigNames.DISLIKED_INGREDIENT_CATEGORY_ID) {
          configProductsCategory.push(Number(el.item));
        }
        if (el.name === UserConfigNames.DISLIKED_CUISINE) {
          dislikedCuisines.push(el.item);
        }
        if (el.name === UserConfigNames.LIKED_CUISINE) {
          likedCuisines.push(el.item);
        }
        if (el.name === UserConfigNames.DISLIKED_INGREDIENT) {
          dislikedIngredients.push(el.item);
        }
        if (el.name === UserConfigNames.GOAL) {
          goals.push(el.item);
        }
        if (el.name === UserConfigNames.DIET) {
          diet = el.item;
        }
      });
      state.format = MealPlanFormatTypes.CUSTOM;
      state.customMealTime = customMealTime;
      state.configProductsCategory = configProductsCategory;
      state.dislikedIngredient = dislikedIngredients;
      state.dislikedCuisines = dislikedCuisines;
      state.likedCuisines = likedCuisines;
      state.goals = goals;
      state.diet = diet;
      state.skill = payload.skill;
      state.age = payload.age;
      state.weight = payload.currentWeight;
      state.targetWeight = payload.targetWeight;
      state.height = payload.height;
      switch (payload.activity) {
        case UserActivityLevel.LOW:
          state.activity = Activities.LOW;
          break;
        case UserActivityLevel.MEDIUM:
          state.activity = Activities.MEDIUM;
          break;
        case UserActivityLevel.HIGH:
          state.activity = Activities.HIGH;
          break;
        case UserActivityLevel.VERY_HIGH:
          state.activity = Activities.VERY_HIGH;
          break;
        case UserActivityLevel.EXTRA:
          state.activity = Activities.EXTRA;
          break;
      }
      switch (payload.gender) {
        case UserGender.MALE:
          state.gender = Genders.MALE;
          break;
        case UserGender.FEMALE:
          state.gender = Genders.FEMALE;
          break;
        case UserGender.OTHER:
          state.gender = Genders.OTHER;
          break;
        case UserGender.PREFER_NOT_TO_SAY:
          state.gender = Genders.PREFER_NOT_SAYING;
          break;
      }
      switch (payload.speed) {
        case RequestSpeedModeList.GRADUAL:
          state.mode = SpeedModeList.GRADUAL;
          break;
        case RequestSpeedModeList.MODERATE:
          state.mode = SpeedModeList.MODERATE;
          break;
        case RequestSpeedModeList.AGGRESSIVE:
          state.mode = SpeedModeList.AGGRESSIVE;
          break;
        default:
          state.mode = SpeedModeList.GRADUAL;
      }
    },
    setGender: (state, {payload}: {payload: Genders}) => {
      state.gender = payload;
    },
    setActivity: (state, {payload}: {payload: Activities}) => {
      state.activity = payload;
    },
    setGoals: (state, {payload}: {payload: string[]}) => {
      state.goals = payload;
    },
    setUserDiet: (state, {payload}: {payload: string | null}) => {
      state.diet = payload;
    },
    setUserDislikedIngredients: (state, {payload}: {payload: string[]}) => {
      state.dislikedIngredient = payload;
    },
    setRouteName: (state, {payload}: {payload: string}) => {
      state.routeName = payload;
    },
    setMode: (state, {payload}: {payload: SpeedModeList}) => {
      state.mode = payload;
    },
    setCuisines: (
      state,
      {payload}: {payload: {value: string; type: LikedDislikedEnum}},
    ) => {
      let likedList = [...state.likedCuisines];
      let dislikedList = [...state.dislikedCuisines];
      if (payload.type === LikedDislikedEnum.LIKED) {
        if (dislikedList.includes(payload.value)) {
          dislikedList = dislikedList.filter(el => el !== payload.value);
        }
        if (likedList.includes(payload.value)) {
          likedList = likedList.filter(el => el !== payload.value);
        } else {
          likedList.push(payload.value);
        }
      }
      if (payload.type === LikedDislikedEnum.DISLIKED) {
        if (likedList.includes(payload.value)) {
          likedList = likedList.filter(el => el !== payload.value);
        }
        if (dislikedList.includes(payload.value)) {
          dislikedList = dislikedList.filter(el => el !== payload.value);
        } else {
          dislikedList.push(payload.value);
        }
      }
      state.likedCuisines = likedList;
      state.dislikedCuisines = dislikedList;
    },
    setSkillLevel: (state, {payload}: {payload: SkillLevel}) => {
      state.skill = payload;
    },
    setClearOnboarding: state => {
      state.budgetFriendlyMeals = 5;
      state.deliciousMeals = 5;
      state.quickRecipes = 5;
      state.recipeVariety = 5;
      state.easyRecipes = 5;
      state.goals = [];
      state.diet = null;
      state.dislikedIngredient = [];
      state.likedCuisines = [];
      state.dislikedCuisines = [];
      state.skill = SkillLevel.UNKNOWN;
      state.age = null;
      state.gender = null;
      state.weight = null;
      state.targetWeight = null;
      state.mode = SpeedModeList.GRADUAL;
      state.height = null;
      state.activity = null;
      state.loading = false;
      state.selectedProductsCategory = [];
      state.productsCategories = []
      state.format = null;
      state.customMealTime = []
    },
    setAge: (state, {payload}: {payload: number | null}) => {
      state.age = payload;
    },
    setHeight: (state, {payload}: {payload: number | null}) => {
      state.height = payload;
    },
    setWeight: (state, {payload}: {payload: number | null}) => {
      state.weight = payload;
    },
    setTargetWeight: (state, {payload}: {payload: number | null}) => {
      state.targetWeight = payload;
    },
    setWeightImperial: (state, {payload}: {payload: number | null}) => {
      state.imperialWeight = payload;
    },
    setTargetWeightImperial: (state, {payload}: {payload: number | null}) => {
      state.imperialTargetWeight = payload;
    },
    setHeightFtImperial: (state, {payload}: {payload: number | null}) => {
      state.imperialHeightFt = payload;
    },
    setHeightInchImperial: (state, {payload}: {payload: number | null}) => {
      state.imperialHeightInch = payload;
    },
    setProductsCategories: (state, {payload}: {payload: TreeNodeType[]}) => {
      state.selectedProductsCategory = payload;
    },
    setOriginalProductsCategories: (state, {payload}: {payload: TreeNodeType[]}) => {
      state.productsCategories = payload;
    },
    setConfigProductsCategory: (state, {payload}: {payload: number[]}) => {
      state.configProductsCategory = payload;
    },
    setDeliciousMeals: (state, {payload}: {payload: number}) => {
      state.deliciousMeals = payload;
    },
    setQuickRecipes: (state, {payload}: {payload: number}) => {
      state.quickRecipes = payload;
    },
    setRecipeVariety: (state, {payload}: {payload: number}) => {
      state.recipeVariety = payload;
    },
    setEasyRecipes: (state, {payload}: {payload: number}) => {
      state.easyRecipes = payload;
    },
    setBudgetFriendlyMeals: (state, {payload}: {payload: number}) => {
      state.budgetFriendlyMeals = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadProductCategories.pending, state => {
      state.loadingProductsCategories = true;
      state.productsCategories = [];
    });
    builder.addCase(loadProductCategories.fulfilled, (state, action) => {
      state.loadingProductsCategories = false;
      state.productsCategories = action.payload;
    });
    builder.addCase(loadProductCategories.rejected, state => {
      state.loadingProductsCategories = false;
      state.productsCategories = [];
    });
    builder.addCase(saveOnBoardingData.pending, () => {
      // console.log('pending saveOnBoardingData');
    });
    builder.addCase(saveOnBoardingData.fulfilled, () => {
      // console.log('fulfilled saveOnBoardingData');
    });
    builder.addCase(saveOnBoardingData.rejected, () => {
      // console.log('rejected updateOnBoardingData');
    });
    builder.addCase(fetchDietsList.fulfilled, (state, {payload}) => {
      state.diets = payload;
    });
  },
});

export const {
  setUserConfig,
  setCuisines,
  setUserDislikedIngredients,
  setUserDiet,
  setGoals,
  setSkillLevel,
  setClearOnboarding,
  setHeight,
  setWeight,
  setAge,
  setMode,
  setTargetWeight,
  setGender,
  setActivity,
  setRouteName,
  setWeightImperial,
  setTargetWeightImperial,
  setHeightFtImperial,
  setHeightInchImperial,
  setConfigProductsCategory,
  setProductsCategories,
  setCustomMealPlan,
  setFormatType,
  clearCustomMealPlanFormat,
  setOriginalProductsCategories,
  setEasyRecipes,
  setQuickRecipes,
  setRecipeVariety,
  setBudgetFriendlyMeals,
  setDeliciousMeals
} = onBoardingSlice.actions;

const selectDiets = (state: RootState) => state.onBoarding.diets;
export const selectDietById = (state: RootState, itemId: number) => {
  const diets = selectDiets(state);
  return diets.find(diet => diet.id === itemId);
}
// export const selectDietById = createSelector(
//   [getDietById],
//   (diet) => diet
// )

export default onBoardingSlice.reducer;
