import React, {useState} from "react";
import ApplePay from "../ApplePay";
import Card from "../Card";
import {useAppSelector} from "../../../../store/types";

const PaymentMethods: React.FC = () => {
  const {couponIndex} = useAppSelector(state => state.products)
  const [showApple, setShowApple] = useState<boolean>(true)
  return(
    <div>
      <ApplePay appleShow={showApple} setShowApple={setShowApple} />
      <Card />
    </div>
  )
}

export default PaymentMethods
