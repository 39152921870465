import React, {useEffect, useRef} from 'react';
import styles from './WelcomePage.module.css';
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import {useHistory} from "react-router-dom";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {fetchDietsList} from "../../../store/OnBoarding/OnBoardingSlice";
import {useAppDispatch} from "../../../store/types";
import {useScreenHeight} from "../../../context/ScreenHeightContext";
import Lottie from "lottie-react";
import animation from "../../../assets/animation/WelcomeAnimation.json";

const WelcomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onPressNext = () => {
    history.push(OnBoardingRoutesEnum.GOALS)
  }

  useEffect(() => {
    dispatch(fetchDietsList());
  }, [dispatch]);

  const {screenHeight, isWebView} = useScreenHeight();

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight : 'calc(100dvh)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.listData}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={require('../../../assets/images/welcome-image.webp')} alt="Welcome"/>
          </div>
          <div className={styles.infoContainer}>
            <StepInfo varTwo={true} title={"Your meal plan awaits"}
                      description={"We'll learn about your goals and preferences to create your personalized meal plan."}/>
            <div className={styles.iconContainer}>
              <Lottie animationData={animation} loop={true} style={{
                position: 'relative'
              }}>
                <div className={[styles.text, styles.titleOne].join(' ')}>Nutrition</div>
                <div className={[styles.text, styles.titleTwo].join(' ')}>Goals</div>
                <div className={[styles.text, styles.titleThree].join(' ')}>
                  <div>Meal</div>
                  <div>Plan</div>
                </div>
                <div className={[styles.text, styles.titleFore].join(' ')}>Diet</div>
                <div className={[styles.text, styles.titleFive].join(' ')}>Taste</div>
              </Lottie>

            </div>
          </div>
        </div>
      </div>
      <NextButton fixed={true}
                  withPadding={true}
                  withGradient={true}
                  onClick={onPressNext}/>
    </div>
  )
}

export default WelcomePage
