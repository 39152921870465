import {useAppSelector} from "../store/types";
import {MayorSystem} from "../store/Options/OptionsSlice";

export enum ImperialWight {
  OZ = 'oz',
  LB = 'lb',
}
export enum ImperialVolume {
  TSP = 'tsp',
  TBSP = 'tbsp',
  CUP = 'cup',
}

function isImperialWeight(value: any): value is ImperialWight {
  return Object.values(ImperialWight).includes(value);
}

function isImperialVolume(value: any): value is ImperialVolume {
  return Object.values(ImperialVolume).includes(value);
}

const imperialVolumeValues = {
  [ImperialVolume.TSP]: 5,
  [ImperialVolume.TBSP]: 15,
  [ImperialVolume.CUP]: 240,
};

const imperialWeightValues = {
  [ImperialWight.OZ]: 28.349523125,
  [ImperialWight.LB]: 453.59237,
};


export const lb = 453.59237;

export function useConvert() {
  const currentMetric = useAppSelector(state => state.settings.mayorSystem);

  function convertValue(
    value: number,
    unit: ImperialVolume | ImperialWight | 'g' | 'ml' | 'l'
  ): {
    ingredientValue: number | string;
    ingredientUnit: string;
    fullPart: string;
    demical: string;
  } {
    const converted: {
      ingredientValue: string | number;
      ingredientUnit: string;
      fullPart: string;
      demical: string;
    } = {
      ingredientValue: value,
      ingredientUnit: unit,
      fullPart: '',
      demical: '',
    };

    if (currentMetric === MayorSystem.METRIC) {
      if (isImperialVolume(unit)) {
        converted.ingredientUnit = 'ml';
        converted.ingredientValue = value * imperialVolumeValues[unit];
      }
      if (isImperialWeight(unit)) {
        converted.ingredientUnit = 'g';
        converted.ingredientValue = value * imperialWeightValues[unit];
      }
    } else {
      if (unit === 'g') {
        const convertedValue = gramAmericanEquivalent(value);
        converted.ingredientUnit = convertedValue.unit;
        converted.ingredientValue = convertedValue.name;
      }
      if (unit === 'ml') {
        const convertedValue = mlAmericanEquivalent(value);
        converted.ingredientUnit = convertedValue.unit;
        converted.ingredientValue = convertedValue.name;
      }
      if (unit === 'l') {
        const convertedValue = mlAmericanEquivalent(value * 1000);
        converted.ingredientUnit = convertedValue.unit;
        converted.ingredientValue = convertedValue.name;
      }
    }
    if (converted.ingredientValue.toString().includes(' ')) {
      const values = converted.ingredientValue.toString().split(' ');
      converted.fullPart = values[0];
      converted.demical = values[1];
    } else {
      if (!isNaN(Number(converted.ingredientValue.toString()))) {
        converted.fullPart = converted.ingredientValue.toString();
        converted.demical = '';
      } else {
        converted.fullPart = '';
        converted.demical = converted.ingredientValue.toString();
      }
    }
    return converted;
  }
  function mlAmericanEquivalent(volume: number) {
    const tsp = imperialVolumeValues[ImperialVolume.TSP];
    const tbsp = imperialVolumeValues[ImperialVolume.TBSP];
    const cup = imperialVolumeValues[ImperialVolume.CUP];

    const equivalents = [
      {value: 0, name: '⅛', unit: ImperialVolume.TSP},
      {value: 0.125 * tsp, name: '⅛', unit: ImperialVolume.TSP},
      {value: 0.25 * tsp, name: '¼', unit: ImperialVolume.TSP},
      {value: 0.33 * tsp, name: '⅓', unit: ImperialVolume.TSP},
      {value: 0.5 * tsp, name: '½', unit: ImperialVolume.TSP},
      {value: 0.66 * tsp, name: '⅔', unit: ImperialVolume.TSP},
      {value: 0.75 * tsp, name: '¾', unit: ImperialVolume.TSP},
      {value: tsp, name: '1', unit: ImperialVolume.TSP},
      {value: 1.125 * tsp, name: '1 ¼', unit: ImperialVolume.TSP},
      {value: 1.25 * tsp, name: '1 ⅓', unit: ImperialVolume.TSP},
      {value: 0.5 * tbsp, name: '½', unit: ImperialVolume.TBSP},
      {value: 0.66 * tbsp, name: '⅔', unit: ImperialVolume.TBSP},
      {value: 0.75 * tbsp, name: '¾', unit: ImperialVolume.TBSP},
      {value: tbsp, name: '1', unit: ImperialVolume.TBSP},
      {value: 1.25 * tbsp, name: '1 ¼', unit: ImperialVolume.TBSP},
      {value: 1.33 * tbsp, name: '1 ⅓', unit: ImperialVolume.TBSP},
      {value: 1.5 * tbsp, name: '1 ½', unit: ImperialVolume.TBSP},
      {value: 1.66 * tbsp, name: '1 ⅔', unit: ImperialVolume.TBSP},
      {value: 1.75 * tbsp, name: '1 ¾', unit: ImperialVolume.TBSP},
      {value: 2 * tbsp, name: '2', unit: ImperialVolume.TBSP},
      {value: 2.25 * tbsp, name: '2 ¼', unit: ImperialVolume.TBSP},
      {value: 2.33 * tbsp, name: '2 ⅓', unit: ImperialVolume.TBSP},
      {value: 2.5 * tbsp, name: '2 ½', unit: ImperialVolume.TBSP},
      {value: 2.66 * tbsp, name: '2 ⅔', unit: ImperialVolume.TBSP},
      {value: 2.75 * tbsp, name: '2 ¾', unit: ImperialVolume.TBSP},
      {value: 3 * tbsp, name: '3', unit: ImperialVolume.TBSP},
      {value: 3.5 * tbsp, name: '3 ½', unit: ImperialVolume.TBSP},
      {value: 0.25 * cup, name: '¼', unit: ImperialVolume.CUP},
      {value: 0.33 * cup, name: '⅓', unit: ImperialVolume.CUP},
      {value: 6 * tbsp, name: '6', unit: ImperialVolume.TBSP},
      {value: 7 * tbsp, name: '7', unit: ImperialVolume.TBSP},
      {value: 0.5 * cup, name: '½', unit: ImperialVolume.CUP},
      {value: 0.66 * cup, name: '⅔', unit: ImperialVolume.CUP},
      {value: 0.75 * cup, name: '¾', unit: ImperialVolume.CUP},
      {value: cup, name: '1', unit: ImperialVolume.CUP},
      {value: 1.25 * cup, name: '1 ¼', unit: ImperialVolume.CUP + 's'},
      {value: 1.33 * cup, name: '1 ⅓', unit: ImperialVolume.CUP + 's'},
      {value: 1.5 * cup, name: '1 ½', unit: ImperialVolume.CUP + 's'},
      {value: 1.66 * cup, name: '1 ⅔', unit: ImperialVolume.CUP + 's'},
      {value: 1.75 * cup, name: '1 ¾', unit: ImperialVolume.CUP + 's'},
      {value: 2 * cup, name: '2', unit: ImperialVolume.CUP + 's'},
      {value: 2.25 * cup, name: '2 ¼', unit: ImperialVolume.CUP + 's'},
      {value: 2.33 * cup, name: '2 ⅓', unit: ImperialVolume.CUP + 's'},
      {value: 2.5 * cup, name: '2 ½', unit: ImperialVolume.CUP + 's'},
      {value: 2.66 * cup, name: '2 ⅔', unit: ImperialVolume.CUP + 's'},
      {value: 2.75 * cup, name: '2 ¾', unit: ImperialVolume.CUP + 's'},
      {value: 3 * cup, name: '3', unit: ImperialVolume.CUP + 's'},
      {value: 3.5 * cup, name: '3 ½', unit: ImperialVolume.CUP + 's'},
      {value: 4 * cup, name: '4', unit: ImperialVolume.CUP + 's'},
      {value: 4.5 * cup, name: '4 ½', unit: ImperialVolume.CUP + 's'},
      {value: 5 * cup, name: '5', unit: ImperialVolume.CUP + 's'},
    ];
    let closestEquivalent = equivalents[0];
    // Если количество грамм больше или равно 5, добавляем шаг ½
    if (volume >= 5 * cup) {
      const value = volume / cup;
      const unitValue = Math.trunc(value);
      for (let i = unitValue - 5; i < unitValue + 5; i++) {
        equivalents.push(
          {value: i * cup, name: `${i}`, unit: ImperialVolume.CUP + 's'},
          {
            value: (i + 0.5) * cup,
            name: `${i} ½`,
            unit: ImperialVolume.CUP + 's',
          },
        );
      }
    }

    equivalents.forEach(equivalent => {
      if (
        Math.abs(volume - equivalent.value) <
        Math.abs(volume - closestEquivalent.value)
      ) {
        closestEquivalent = equivalent;
      }
    });

    // Найти ближайший эквивалент

    return closestEquivalent;
  }

  function gramAmericanEquivalent(grams: number) {
    const oz = 28.349523125;
    const lb = 453.59237;
    // Список американских эквивалентов в граммах
    const equivalents = [
      {value: 0, name: '⅛', unit: ImperialWight.OZ},
      {value: 0.125 * oz, name: '⅛', unit: ImperialWight.OZ},
      {value: 0.25 * oz, name: '¼', unit: ImperialWight.OZ},
      {value: 0.33 * oz, name: '⅓', unit: ImperialWight.OZ},
      {value: 0.5 * oz, name: '½', unit: ImperialWight.OZ},
      {value: 0.66 * oz, name: '⅔', unit: ImperialWight.OZ},
      {value: 0.75 * oz, name: '¾', unit: ImperialWight.OZ},
      {value: oz, name: '1', unit: ImperialWight.OZ},
      {value: 1.125 * oz, name: '1 ¼', unit: ImperialWight.OZ},
      {value: 1.25 * oz, name: '1 ⅓', unit: ImperialWight.OZ},
      {value: 1.5 * oz, name: '1 ½', unit: ImperialWight.OZ},
      {value: 1.66 * oz, name: '1 ⅔', unit: ImperialWight.OZ},
      {value: 1.75 * oz, name: '1 ¾', unit: ImperialWight.OZ},
      {value: 2 * oz, name: '2', unit: ImperialWight.OZ},
      {value: 2.125 * oz, name: '2 ¼', unit: ImperialWight.OZ},
      {value: 2.25 * oz, name: '2 ⅓', unit: ImperialWight.OZ},
      {value: 2.5 * oz, name: '2 ½', unit: ImperialWight.OZ},
      {value: 2.66 * oz, name: '2 ⅔', unit: ImperialWight.OZ},
      {value: 2.75 * oz, name: '2 ¾', unit: ImperialWight.OZ},
      {value: 3 * oz, name: '3', unit: ImperialWight.OZ},
      {value: 3.5 * oz, name: '3 ½', unit: ImperialWight.OZ},
      {value: 0.25 * lb, name: '¼', unit: ImperialWight.LB},
      {value: 4.5 * oz, name: '4 ½', unit: ImperialWight.OZ},
      {value: 5 * oz, name: '5', unit: ImperialWight.OZ},
      {value: 0.33 * lb, name: '⅓', unit: ImperialWight.LB},
      {value: 6 * oz, name: '6', unit: ImperialWight.OZ},
      {value: 7 * oz, name: '7', unit: ImperialWight.OZ},
      {value: 0.5 * lb, name: '½', unit: ImperialWight.LB},
      {value: 9 * oz, name: '9', unit: ImperialWight.OZ},
      {value: 10 * oz, name: '10', unit: ImperialWight.OZ},
      {value: 0.66 * lb, name: '⅔', unit: ImperialWight.LB},
      {value: 11 * oz, name: '11', unit: ImperialWight.OZ},
      {value: 0.75 * lb, name: '¾', unit: ImperialWight.LB},
      {value: 13 * oz, name: '13', unit: ImperialWight.OZ},
      {value: 14 * oz, name: '14', unit: ImperialWight.OZ},
      {value: 15 * oz, name: '15', unit: ImperialWight.OZ},
      {value: lb, name: '1', unit: ImperialWight.LB},
      {value: 1.125 * lb, name: '1 ¼', unit: ImperialWight.LB},
      {value: 1.25 * lb, name: '1 ⅓', unit: ImperialWight.LB},
      {value: 1.5 * lb, name: '1 ½', unit: ImperialWight.LB},
      {value: 1.66 * lb, name: '1 ⅔', unit: ImperialWight.LB},
      {value: 1.75 * lb, name: '1 ¾', unit: ImperialWight.LB},
      {value: 2 * lb, name: '2', unit: ImperialWight.LB},
      {value: 2.125 * lb, name: '2 ¼', unit: ImperialWight.LB},
      {value: 2.25 * lb, name: '2 ⅓', unit: ImperialWight.LB},
      {value: 2.5 * lb, name: '2 ½', unit: ImperialWight.LB},
      {value: 2.66 * lb, name: '2 ⅔', unit: ImperialWight.LB},
      {value: 2.75 * lb, name: '2 ¾', unit: ImperialWight.LB},
      {value: 3 * lb, name: '3', unit: ImperialWight.LB},
      {value: 3.5 * lb, name: '3 ½', unit: ImperialWight.LB},
      {value: 4 * lb, name: '4', unit: ImperialWight.LB},
      {value: 4.5 * lb, name: '4 ½', unit: ImperialWight.LB},
      {value: 5 * lb, name: '5', unit: ImperialWight.LB},
    ];
    let closestEquivalent = equivalents[0];

    // Если количество грамм больше или равно 5, добавляем шаг ½
    if (grams >= 5 * lb) {
      const value = grams / lb;
      const unitValue = Math.trunc(value);
      for (let i = unitValue - 5; i < unitValue + 5; i++) {
        equivalents.push(
          {value: i * lb, name: `${i}`, unit: ImperialWight.LB},
          {value: (i + 0.5) * lb, name: `${i} ½`, unit: ImperialWight.LB},
        );
      }
    }

    equivalents.forEach(equivalent => {
      if (
        Math.abs(grams - equivalent.value) <
        Math.abs(grams - closestEquivalent.value)
      ) {
        closestEquivalent = equivalent;
      }
    });

    // Найти ближайший эквивалент

    return closestEquivalent;
  }

  function cmToAmericanUnits(centimeters: number) {
    // 1 дюйм = 2.54 см
    // 1 фут = 12 дюймов = 30.48 см
    const inchesPerFoot = 12;
    const centimetersPerInch = 2.54;

    // Вычисление футов
    const feet = Math.round(centimeters / (centimetersPerInch * inchesPerFoot));

    // Вычисление дюймов (остаток после вычисления футов)
    const remainingCentimeters =
      centimeters - feet * centimetersPerInch * inchesPerFoot;
    const inches = Math.round(remainingCentimeters / centimetersPerInch);

    // Возвращаем строку с футами и дюймами
    return {
      feet,
      inches,
    };
  }

  function ftToCm(ft: number, inch: number) {
    const inchesPerFoot = 12;
    const centimetersPerInch = 2.54;
    return (
      ft * (centimetersPerInch * inchesPerFoot) + inch * centimetersPerInch
    );
  }

  function lbToKg(lb: number) {
    return lb * 0.453592;
  }

  return {
    gramAmericanEquivalent,
    cmToAmericanUnits,
    convertValue,
    ftToCm,
    lbToKg,
  };
}

// Пример использования
