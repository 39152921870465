import {IconProps} from "../types/types";
import React from "react";

const MealPlanLunchAndDinnerIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5419 20C28.8407 18.8838 29 17.7105 29 16.5C29 9.04416 22.9558 3 15.5 3C8.04416 3 2 9.04416 2 16.5C2 23.9558 8.04416 30 15.5 30C15.7415 30 16.9816 29.9937 17.22 29.9811"
        stroke="#8DCC6F"
        strokeWidth="1.5"
      />
      <path
        d="M25.4025 20C25.7895 18.9053 26 17.7272 26 16.5C26 10.701 21.299 6 15.5 6C9.70101 6 5 10.701 5 16.5C5 21.9617 9.17007 26.4494 14.5 26.953"
        stroke="#8DCC6F"
        strokeWidth="1.5"
      />
      <path
        d="M16 10C16.0812 10 16.1619 10.0016 16.2423 10.0048M19.1704 10.905C19.6549 11.2072 20.0932 11.5765 20.4722 12C21.4223 13.0615 22 14.4633 22 16C22 16.7013 21.8797 17.3744 21.6586 18"
        stroke="#8DCC6F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13 20H29C29.2652 20 29.5196 20.1054 29.7071 20.2929C29.8946 20.4804 30 20.7348 30 21V21.5C30 23 27.483 27.073 26 28V29C26 29.2652 25.8946 29.5196 25.7071 29.7071C25.5196 29.8946 25.2652 30 25 30H17C16.7348 30 16.4804 29.8946 16.2929 29.7071C16.1054 29.5196 16 29.2652 16 29V28C14.313 26.946 12 23 12 21.5V21C12 20.7348 12.1054 20.4804 12.2929 20.2929C12.4804 20.1054 12.7348 20 13 20Z"
        stroke="#8DCC6F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}

export default MealPlanLunchAndDinnerIcon
