import React from 'react';
import styles from './ChangeMetricButton.module.css'
import {useAppDispatch, useAppSelector} from "../../store/types";
import {MayorSystem, setMayorSystem} from "../../store/Options/OptionsSlice";
import {ColorsEnum} from "../../enums/ColorsEnum";

interface ChangeMetricButtonProps {
  metricLabel: string;
  imperialLabel: string;
}

const ChangeMetricButton: React.FC<ChangeMetricButtonProps> = ({metricLabel, imperialLabel}) => {
  const dispatch = useAppDispatch();
  const {mayorSystem} = useAppSelector(state => state.settings);
  function changeSystem(system: MayorSystem) {
    dispatch(setMayorSystem(system));
  }
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={[styles.button, mayorSystem === MayorSystem.METRIC ? styles.active : null].join(' ')}
             onClick={() => changeSystem(MayorSystem.METRIC)} style={{
          // color: mayorSystem === MayorSystem.METRIC
          //   ? ColorsEnum.DARK_60
          //   : ColorsEnum.DARK_40
        }}>
          {metricLabel}
        </div>
        <div className={[styles.button, mayorSystem === MayorSystem.IMPERIAL ? styles.active : null].join(' ')}
             onClick={() => changeSystem(MayorSystem.IMPERIAL)} style={{
          // color: mayorSystem === MayorSystem.IMPERIAL
          //   ? ColorsEnum.DARK_60
          //   : ColorsEnum.DARK_40
        }}>
          {imperialLabel}
        </div>
      </div>
    </div>
  )
}

export default ChangeMetricButton
