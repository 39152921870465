import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from "../store";
import {defHttp} from "../../api";
import {setUserConfig} from "../OnBoarding/OnBoardingSlice";
import {UserModel} from "../OnBoarding/types";
import mixpanel from "mixpanel-browser";

interface userSliceInitialStateModel {
  id: number | null;
  appleId: string | null;
  email: string | null;
  token: string | null;
  deleteUserLoading: boolean;
  loginCount: number;
  loading: boolean;
  auth: boolean;
  existUser: boolean;
}

const initialState: userSliceInitialStateModel = {
  id: null,
  appleId: null,
  email: null,
  token: null,
  deleteUserLoading: false,
  loading: false,
  loginCount: 1,
  auth: false,
  existUser: false,
};

export interface LoginParams {
  appleId: string;
  email: string;
  token: string;
}

export interface LoginResponse extends LoginParams {
  loginCount: number;
  id: number;
}

export const recipeLogin = createAsyncThunk<
  LoginResponse,
  string,
  {state: RootState; rejectValue: {error: string}}
>(
  'auth/login',
  async (token: string, {dispatch, fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await defHttp.post(
        '/api/v2/recipe/user-login',
        {
          token,
        },
      );
      if (response.data) {
        mixpanel.identify(response.data.email)
        mixpanel.people.set('$email', response.data.email);
        dispatch(setUserConfig(response.data));
      }
      return fulfillWithValue(response.data);
    } catch (e: any) {
      return rejectWithValue({error: e.message});
    }
  },
);

export const deleteUserAccount = createAsyncThunk(
  'auth/deleteUserAccount',
  async (appleId: string) => {
    const response = await defHttp.delete('/api/v2/recipe/user', {
      headers: {
        appleId,
      },
    });
    return response.data;
  },
);

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSetEmail: (state, {payload}: {payload: string}) => {
      state.email = payload;
    },
    setUserAuth: (state, {payload}: {payload: UserModel}) => {
      mixpanel.identify(payload.email)
      mixpanel.people.set('$email', payload.email);
      state.email = payload.email;
      state.appleId = payload.appleId;
      state.id = payload.id;
      state.token = payload.token;
      state.loginCount = payload.loginCount;
      state.auth = true;
    },
    setDeviceId: (state, {payload}: {payload: string}) => {
      state.appleId = payload;
    },
    setAuth: (state, {payload}: {payload: boolean}) => {
      state.auth = payload;
    },
    logoutUser: state => {
      state.email = null;
      state.appleId = null;
      state.id = null;
      state.token = null;
      state.loginCount = 0;
      state.existUser = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(deleteUserAccount.pending, state => {
      state.deleteUserLoading = true;
    });
    builder.addCase(deleteUserAccount.fulfilled, state => {
      state.deleteUserLoading = false;
      state.appleId = null;
      state.id = null;
      state.email = null;
      state.token = null;
      state.existUser = false;
    });
    builder.addCase(deleteUserAccount.rejected, state => {
      state.deleteUserLoading = false;
    });
    builder.addCase(recipeLogin.pending, state => {
      state.loading = true;
    });
    builder.addCase(recipeLogin.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.email = payload.email;
      state.appleId = payload.appleId;
      state.id = payload.id;
      state.token = payload.token;
      state.loginCount = payload.loginCount;
      state.existUser = true;
      state.auth = true;
    });
    builder.addCase(recipeLogin.rejected, state => {
      state.loading = false;
    });
  },
});

export const {authSetEmail, setUserAuth, setAuth, setDeviceId, logoutUser} =
  userSlice.actions;

export default userSlice.reducer;
