import React, {ReactNode} from 'react';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import { RouteComponentProps } from 'react-router-dom';

export type LocationState = {
  key: string;
  pathname: string;
  search: string;
  hash: string;
  state?: any;
};

type SlideTransitionProps = RouteComponentProps<{}, {}, LocationState | unknown> & {
  children: ReactNode;
};

const SlideTransition: React.FC<SlideTransitionProps> = ({ children, location }) => {
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.pathname}
        timeout={500}
        appear={true}
        classNames="fade"
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default SlideTransition;
