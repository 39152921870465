export const menNotUnder30 = [
  require('../../../assets/people/Man_under_30/1.png'),
  require('../../../assets/people/Man_under_30/2.png'),
  require('../../../assets/people/Man_under_30/3.png'),
  require('../../../assets/people/Man_under_30/4.png'),
  require('../../../assets/people/Man_under_30/5.png'),
  require('../../../assets/people/Man_under_30/6.png'),
  require('../../../assets/people/Man_under_30/7.png'),
  require('../../../assets/people/Man_under_30/8.png'),
  require('../../../assets/people/Man_under_30/9.png'),
  require('../../../assets/people/Man_under_30/10.png'),
  require('../../../assets/people/Man_under_30/11.png'),
  require('../../../assets/people/Man_under_30/12.png'),
  require('../../../assets/people/Man_under_30/13.png'),
  require('../../../assets/people/Man_under_30/14.png'),
  require('../../../assets/people/Man_under_30/15.png'),
]

export const menUnder30 = [
  require('../../../assets/people/Man_30+/1.png'),
  require('../../../assets/people/Man_30+/2.png'),
  require('../../../assets/people/Man_30+/3.png'),
  require('../../../assets/people/Man_30+/4.png'),
  require('../../../assets/people/Man_30+/5.png'),
  require('../../../assets/people/Man_30+/6.png'),
  require('../../../assets/people/Man_30+/7.png'),
  require('../../../assets/people/Man_30+/8.png'),
  require('../../../assets/people/Man_30+/9.png'),
  require('../../../assets/people/Man_30+/10.png'),
  require('../../../assets/people/Man_30+/11.png'),
  require('../../../assets/people/Man_30+/12.png'),
  require('../../../assets/people/Man_30+/13.png'),
  require('../../../assets/people/Man_30+/14.png'),
  require('../../../assets/people/Man_30+/15.png'),
]

export const womanNotUnder30 = [
  require('../../../assets/people/Woman_under_30/1.png'),
  require('../../../assets/people/Woman_under_30/2.png'),
  require('../../../assets/people/Woman_under_30/3.png'),
  require('../../../assets/people/Woman_under_30/4.png'),
  require('../../../assets/people/Woman_under_30/5.png'),
  require('../../../assets/people/Woman_under_30/6.png'),
  require('../../../assets/people/Woman_under_30/7.png'),
  require('../../../assets/people/Woman_under_30/8.png'),
  require('../../../assets/people/Woman_under_30/9.png'),
  require('../../../assets/people/Woman_under_30/10.png'),
  require('../../../assets/people/Woman_under_30/11.png'),
  require('../../../assets/people/Woman_under_30/12.png'),
  require('../../../assets/people/Woman_under_30/13.png'),
  require('../../../assets/people/Woman_under_30/14.png'),
  require('../../../assets/people/Woman_under_30/15.png'),
]

export const womanUnder30 = [
  require('../../../assets/people/Woman_30+/1.png'),
  require('../../../assets/people/Woman_30+/2.png'),
  require('../../../assets/people/Woman_30+/3.png'),
  require('../../../assets/people/Woman_30+/4.png'),
  require('../../../assets/people/Woman_30+/5.png'),
  require('../../../assets/people/Woman_30+/6.png'),
  require('../../../assets/people/Woman_30+/7.png'),
  require('../../../assets/people/Woman_30+/8.png'),
  require('../../../assets/people/Woman_30+/9.png'),
  require('../../../assets/people/Woman_30+/10.png'),
  require('../../../assets/people/Woman_30+/11.png'),
  require('../../../assets/people/Woman_30+/12.png'),
  require('../../../assets/people/Woman_30+/13.png'),
  require('../../../assets/people/Woman_30+/14.png'),
  require('../../../assets/people/Woman_30+/15.png'),
]
