import React, { useState, useEffect } from 'react';
import styles from './ProgressBar.module.css'

const ProgressBar = ({ percentage }: {percentage: number}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (percentage > 0 && percentage <= 100) {
      setProgress(percentage);
    }
  }, [percentage]);

  return (
    <div className={styles.bar}>
      <div
        className={styles.progress}
        style={{ width: `${progress}%`}}
      ></div>
    </div>
  );
};

export default ProgressBar;
