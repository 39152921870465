import React from 'react';
import styles from './Legend.module.css'

interface Props {
  color: string;
  value: number;
  info: string;
  label: string;
}

const Legend: React.FC<Props> = ({
  color,
  value,
  info,
  label,
}) => {
  return (
    <div className={styles.Legend}>
      <span className={styles.text}>{label}</span>
      <div className={styles.info}>
        <div style={{backgroundColor: color}} className={styles.value}>{value}</div>
        <div className={styles.infoText}>{info}</div>
      </div>
    </div>
  )
}

export default Legend
