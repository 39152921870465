import ChessIcon from "../icons/ChessIcon";
import MentalIcon from "../icons/MentalIcon";
import RunIcon from "../icons/RunIcon";
import HighIcon from "../icons/HighIcon";
import OlimpIcon from "../icons/OlimpIcon";
import {SkillLevel, SkillModel} from "../types/onBoardingTypes";

export enum OnBoardingRoutesEnum {
  SUMMARY_PAGE = '/onboarding/summary',
  LOSE_TWICE_PAGE = '/onboarding/lose-twice',
  PLAN_FEATURES_PAGE = '/onboarding/plan-features',
  PRIORITIES_PAGE = '/onboarding/priorities',
  WELCOME_PAGE = '/onboarding/welcome',
  USERS_PAGE = '/onboarding/users',
  GRAPH = '/onboarding/graph',
  MESSAGE_PAGE = '/onboarding/message',
  PAYMENT = '/onboarding/payment',
  PAYMENT_SUCCESS = '/onboarding/payment/success',
  GENDER = '/onboarding/gender',
  AGE = '/onboarding/age',
  HEIGHT = '/onboarding/height',
  WEIGHT = '/onboarding/weight',
  TARGET_WEIGHT = '/onboarding/target-weight',
  ACTIVITY = '/onboarding/activity',
  SPEED = '/onboarding/speed',
  SINGLE_DIET = '/onboarding/diet/:id',
  DIET = '/onboarding/diet',
  DISLIKES_SEARCH = '/onboarding/dislikes/search',
  DISLIKES_PRODUCTS = '/onboarding/dislikes/products',
  DISLIKES = '/onboarding/dislikes',
  CUISINES = '/onboarding/cuisines',
  COOKING_SKILL = '/onboarding/cooking-skill',
  MEAL_PLAN = '/onboarding/meal-plan',
  CUSTOM_MEAL_PLAN = '/onboarding/custom-meal-plan',
  EMAIL = '/onboarding/email',
  LOADER = '/onboarding/loader',
  GOALS = '/onboarding/goals',
}

export enum SpeedModeList {
  GRADUAL = 'Gradual',
  MODERATE = 'Moderate',
  AGGRESSIVE = 'Aggressive',
}
export enum UserGender {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
  PREFER_NOT_TO_SAY = 4,
}
export enum RequestSpeedModeList {
  GRADUAL = 1,
  MODERATE = 2,
  AGGRESSIVE = 3,
}

export enum UserActivityLevel {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  VERY_HIGH = 4,
  EXTRA = 5,
}

export enum MealPlanFormatTypes {
  DINNER = 'dinner',
  LUNCH_AND_DINNER = 'lunchAndDinner',
  ALL_MEALS = 'allMeals',
  CUSTOM = 'custom',
}

export const duration = 200

export const SkillsList: SkillModel[] = [
  {
    name: 'Novice',
    description: '“I can cook sandwich”',
    activeDescription: 'We will start with simple but tasty recipes!',
    image: require('../assets/images/skills/skill.webp'),
    level: SkillLevel.NOVICE,
  },
  {
    name: 'Basic',
    description: '“I cook only simple recipes”',
    activeDescription: 'Nice to go! Our users develop their skills rapidly',
    image: require('../assets/images/skills/skill-1.webp'),
    level: SkillLevel.BASIC,
  },
  {
    name: 'Intermediate',
    description: '“I regularly try new recipes”',
    activeDescription:
      'Nicely skilled! We could suggest you something original',
    image: require('../assets/images/skills/skill-2.webp'),
    level: SkillLevel.INTERMEDIATE,
  },
  {
    name: 'Advanced',
    description: '“I can cook any recipe”',
    activeDescription: 'We can offer you something unusual and sophisticated!',
    image: require('../assets/images/skills/skill-3.webp'),
    level: SkillLevel.ADVANCED,
  },
];

export const SpeedModeListDesc = {
  [SpeedModeList.GRADUAL]:
    'Gentle deficit without drastic changes to your lifestyle',
  [SpeedModeList.MODERATE]:
    'A balanced approach - moderate changes, consistent progress',
  [SpeedModeList.AGGRESSIVE]:
    'Intensive plan – rapid results, requires high commitment',
};

export const modesList = [
  {
    name: SpeedModeList.GRADUAL,
    description: 'Gentle proficit without drastic changes to your lifestyle\n',
  },
  {
    name: SpeedModeList.MODERATE,
    description:
      'A balanced approach - moderate changes, ideal for an active lifestyle',
  },
  {
    name: SpeedModeList.AGGRESSIVE,
    description: 'High commitment approach - great with an intensive workout',
  },
];

export enum Genders {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
  PREFER_NOT_SAYING = 'Prefer not to say',
}

export const GendersList = [
  Genders.MALE,
  Genders.FEMALE,
  Genders.OTHER,
  Genders.PREFER_NOT_SAYING,
];

export enum Activities {
  LOW = 'Low activity',
  MEDIUM = 'Medium activity',
  HIGH = 'High activity',
  VERY_HIGH = 'Very high activity',
  EXTRA = 'Extra activity',
}

export const activitiesList = [
  {
    name: Activities.LOW,
    description: 'Sedentary lifestyle, no exercise',
    icon: {
      icon: ChessIcon,
      width: 22,
      height: 30,
    },
  },
  {
    name: Activities.MEDIUM,
    description: 'Light exercise 1-3 days per week',
    icon: {
      icon: MentalIcon,
      width: 30,
      height: 30,
    },
  },
  {
    name: Activities.HIGH,
    description: 'Intense exercise 3-5 days per week',
    icon: {
      icon: RunIcon,
      width: 29,
      height: 30,
    },
  },
  {
    name: Activities.VERY_HIGH,
    description: 'Daily exercise or physical job',
    icon: {
      icon: HighIcon,
      width: 20,
      height: 31,
    },
  },
  {
    name: Activities.EXTRA,
    description: 'Athletes, very hard physical job',
    icon: {
      icon: OlimpIcon,
      width: 30,
      height: 21,
    },
  },
];

export const onBoardingScreensPartMenu = ['message', 'summary', 'plan-features', 'users', 'lose-twice']

export const onBoardingScreens = [
  'graph',
  'gender',
  'age',
  'height',
  'weight',
  'target-weight',
  'activity',
  'speed',
  'diet',
  'dislikes',
  'cuisines',
  'cooking-skill',
  'meal-plan',
  'custom-meal-plan',
  'priorities',
  'email',
];

export interface DietModel {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  imageUrl: string;
  proteins: number;
  fats: number;
  carbohydrates: number;
}

export enum LikedDislikedEnum {
  LIKED = 'liked',
  DISLIKED = 'disliked',
}

export enum MealStatus {
  NOT_SELECTED = 1,
  BY_DIET = 2,
  EATING_OUT = 3,
}

export const MealTimeNames = ['Breakfast', 'Lunch', 'Dinner'];

export enum MealTime {
  BREAKFAST = 1,
  LUNCH = 2,
  DINNER = 3,
}
