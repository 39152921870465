import React from "react";
import styles from './BenefitsInformationList.module.css'
import Item from "./Item/Item";

const information = [
  'No more routine meal planning',
  'Follow your goal and improve your health by eating tasty and healthy food',
  'Customized to your diets and preferences',
  // 'All cooking information, including recipes and ingredients in one place'
]

const BenefitsInformationList: React.FC = () => {
  return (
    <div className={styles.container}>
      {information.map((item, index) => (
        <Item key={`${index}-paymentInfo`} title={item} />
      ))}
    </div>
  )
}

export default BenefitsInformationList;
