import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

// Тип для значения контекста
type ScreenHeightContextType = { screenHeight: number; isWebView: boolean; };

// Создаем контекст с типом
const ScreenHeightContext = createContext<ScreenHeightContextType | undefined>(undefined);

interface ScreenHeightProviderProps {
  children: ReactNode;
}

// Расширяем интерфейс Navigator, чтобы включить нестандартное свойство standalone
interface NavigatorStandalone extends Navigator {
  standalone?: boolean;
}

export const ScreenHeightProvider: React.FC<ScreenHeightProviderProps> = ({ children }) => {
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
  const [isWebView, setIsWebView] = useState(false)

  useEffect(() => {
      const userAgent: string = navigator.userAgent || navigator.vendor || window.opera;

      // Проверка для браузера Safari
      const isSafari: boolean = /^((?!chrome|android).)*safari/i.test(userAgent);
      // Проверка для WebView на iOS и Android
      const isWebView: boolean = /AppleWebKit(?!.*Safari)/i.test(userAgent);

      // Проверка на запуск в режиме standalone (PWA)
      const isStandalone: boolean = window.matchMedia('(display-mode: standalone)').matches;

      // Проверка на PWA в Safari
      const isPWA: boolean = (navigator as NavigatorStandalone).standalone === true;

      // Логическая проверка для определения окружения
      const isSafariBrowser = isSafari && !isWebView && !isStandalone && !isPWA;
      const isInWebView = isWebView || isStandalone || isPWA;


    setIsWebView(isInWebView)

    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateScreenHeight);

    // Удаляем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener('resize', updateScreenHeight);
    };
  }, []);

  return (
    <ScreenHeightContext.Provider value={{screenHeight, isWebView}}>
      {children}
    </ScreenHeightContext.Provider>
  );
};

// Пользовательский хук для использования контекста
export const useScreenHeight = (): ScreenHeightContextType => {
  const context = useContext(ScreenHeightContext);
  if (context === undefined) {
    throw new Error('useScreenHeight must be used within a ScreenHeightProvider');
  }
  return context;
};
