import React from "react";
import {IconProps} from "../types/types";

const HighIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox={`0 0 20 31`}
         fill="none"
    >
      <path
        d="M9.99844 1.39999L9.99844 2.79999M15.5984 6.99999L15.5984 4.19999C15.5984 3.82869 15.4509 3.4726 15.1884 3.21004C14.9258 2.94749 14.5697 2.79999 14.1984 2.79999L5.79844 2.79999C5.42713 2.79999 5.07104 2.94749 4.80849 3.21004C4.54594 3.47259 4.39844 3.82869 4.39844 4.19999L4.39844 6.99999M9.99844 11.2L9.99844 19.6M15.5984 23.8L15.5984 26.6C15.5984 26.9713 15.4509 27.3274 15.1884 27.5899C14.9258 27.8525 14.5697 28 14.1984 28L5.79844 28C5.42713 28 5.07104 27.8525 4.80849 27.5899C4.54594 27.3274 4.39844 26.9713 4.39844 26.6L4.39844 23.8M9.99844 29.4L9.99844 28M16.9984 6.99999L2.99844 6.99999C2.62713 6.99999 2.27104 7.14749 2.00849 7.41004C1.74594 7.67259 1.59844 8.02869 1.59844 8.39999L1.59844 9.79999C1.59844 10.1713 1.74594 10.5274 2.00849 10.7899C2.27104 11.0525 2.62713 11.2 2.99844 11.2L16.9984 11.2C17.3697 11.2 17.7258 11.0525 17.9884 10.7899C18.2509 10.5274 18.3984 10.1713 18.3984 9.79999L18.3984 8.39999C18.3984 8.02869 18.2509 7.6726 17.9884 7.41004C17.7258 7.14749 17.3697 6.99999 16.9984 6.99999ZM16.9984 19.6L2.99844 19.6C2.62713 19.6 2.27104 19.7475 2.00849 20.01C1.74594 20.2726 1.59844 20.6287 1.59844 21L1.59844 22.4C1.59844 22.7713 1.74594 23.1274 2.00849 23.3899C2.27104 23.6525 2.62713 23.8 2.99844 23.8L16.9984 23.8C17.3697 23.8 17.7258 23.6525 17.9884 23.3899C18.2509 23.1274 18.3984 22.7713 18.3984 22.4L18.3984 21C18.3984 20.6287 18.2509 20.2726 17.9884 20.01C17.7258 19.7475 17.3697 19.6 16.9984 19.6Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HighIcon
