import React, {useState} from 'react'
import styles from './PromoCode.module.css'
import PromoCodeModal from "./PromoCodeModal/PromoCodeModal";

const PromoCode: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {setShowModal(false)}
  return (
    <div>
      <button className={styles.codeButton} onClick={() => setShowModal(true)}>I have Promo code</button>
      {showModal && <PromoCodeModal close={handleClose}/>}
    </div>
  )
}

export default PromoCode
