import React from "react";
import {IconProps} from "../types/types";
const CheckIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.56056 3.50173C1.28537 3.19214 0.811314 3.16426 0.501727 3.43945C0.19214 3.71463 0.164255 4.18869 0.439443 4.49828L1.56056 3.50173ZM3.66667 7L3.10611 7.49827C3.24844 7.65839 3.45244 7.75 3.66667 7.75C3.88089 7.75 4.0849 7.65839 4.22722 7.49827L3.66667 7ZM9.56056 1.49827C9.83575 1.18869 9.80786 0.714632 9.49827 0.439443C9.18869 0.164255 8.71463 0.19214 8.43945 0.501727L9.56056 1.49827ZM0.439443 4.49828L3.10611 7.49827L4.22722 6.50173L1.56056 3.50173L0.439443 4.49828ZM4.22722 7.49827L9.56056 1.49827L8.43945 0.501727L3.10611 6.50173L4.22722 7.49827Z"
        fill={fill}/>
    </svg>
  )
}

export default CheckIcon
