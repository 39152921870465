import React from "react";
import {Area, AreaChart, CartesianGrid, ReferenceLine, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {ColorsEnum} from "../../../enums/ColorsEnum";

const data = [
  {
    name: '',
    value: 200,
    value2: 200,
  },
  {
    name: '',
    value: 195,
    value2: 140,
  },
  {
    name: '',
    value: 185,
    value2: 110,
  },
  {
    name: '',
    value: 165,
    value2: 100,
  },
  {
    name: '',
    value: 135,
    value2: 110,
  },
  {
    name: '',
    value: 110,
    value2: 150,
  },
  {
    name: '',
    value: 95,
    value2: 220,
  },
  {
    name: '',
    value: 90,
    value2: 250,
  },
  {
    name: '',
    value: 88,
    value2: 260,
  }
]

const GraphGraph: React.FC = () => {
  const CustomizedDot = (props: any) => {
    const {cx, cy, value} = props;
    const [_, dotValue] = value;

    if (dotValue !== 200 && dotValue !== 88) {
      return <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="transparent" viewBox="0 0 1024 1024"/>
    }
    return (
      <svg x={cx - 5.5} y={cy - 5.5} width={11} height={11} fill={ColorsEnum.DARK_20} viewBox="0 0 30 30">
        <circle  r="14" cx="14" cy="14"/>
      </svg>
    );
  };
  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;

    if (value !== 200 && value !== 88) {
      return null
    }

      if (value === 200) {
        return (
          <text x={x} y={y} dy={-20} dx={50} fill={stroke} fontSize={16} fontFamily={'Nunito Sans'}
                color={ColorsEnum.GRAY_BLACK} fontWeight={700} textAnchor="middle">
            Your weight
          </text>
        )
      }
      return (
        <text x={x} y={y} dy={-20} dx={-40} fill={ColorsEnum.DARK_20} fontSize={16} fontFamily={'Nunito Sans'}
              color={ColorsEnum.DARK_20} fontWeight={700} textAnchor="middle">
          Eatr Plan
        </text>
      );
  }
  const CustomizedAditionalLabel = (props: any) => {
    const { x, y, value, stroke } = props;

    if (value !== 220) {
      return null
    }

    return (
      <>
        <text x={x} y={y} dy={-50} dx={-120} fill={ColorsEnum.GRAPH} fontSize={16} fontFamily={'Nunito Sans'}
              color={ColorsEnum.GRAPH} fontWeight={700} textAnchor="left">
          Traditional diet
        </text>
        <text x={x} y={y} dy={-25} dx={-120} fill={stroke} fontSize={16} fontFamily={'Nunito Sans'}
              color={ColorsEnum.GRAPH} fontWeight={400} textAnchor="left">
          Rebounded after
        </text>
        <text x={x} y={y} dy={-5} dx={-120} fill={stroke} fontSize={16} fontFamily={'Nunito Sans'}
              color={ColorsEnum.GRAPH} fontWeight={400} textAnchor="left">
          short success
        </text>
      </>
    );
  }
  const CustomXAxisLabel = (props: any) => {
    const {x, y, index} = props
    if (index !== 8) {
      return <g transform={`translate(${x},${y})`}></g>
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-14} y={12} fontSize={14} fontFamily={'Nunito Sans'}
              color={ColorsEnum.GRAY_BLACK} fontWeight={700} textAnchor="middle">
          Time
        </text>
      </g>
    )
  }
  return (
    <ResponsiveContainer height={400}>
      <AreaChart
        // width={width}
        height={400}
        data={data}
        syncId="anyId"
        margin={{
          top: 50,
          right: 5,
          left: 5,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="50%" stopColor={ColorsEnum.DARK_20} stopOpacity={0.5}/>
            <stop offset={`100%`} stopColor={ColorsEnum.DARK_20} stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={ColorsEnum.GRAPH} stopOpacity={0.5}/>
            <stop offset={`30%`} stopColor={ColorsEnum.GRAPH} stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid horizontal={false} vertical={false}/>
        <ReferenceLine y={200} strokeDasharray={'5'} stroke={ColorsEnum.GRAY_SCALE_90}/>
        <ReferenceLine y={88} strokeDasharray={'5'} stroke={ColorsEnum.GRAY_SCALE_90}/>
        <XAxis
          name="name"
          dataKey='name'
          tickLine={false}
          stroke={ColorsEnum.GRAY_SCALE_90}
          tick={CustomXAxisLabel}
          interval={0}
        />
        <YAxis
          width={1}
          tick={false}
          tickLine={false}
          stroke={ColorsEnum.GRAY_SCALE_90}
          name="name"
          tickSize={0}
          display={0}
          interval={0}
          domain={
            ['dataMin - 50', 'dataMax + 10']
          }/>
        <Area
          key={new Date().toDateString() + 'asdasd1'}
          label={CustomizedAditionalLabel}
          // dot={CustomizedDot}
          type="monotoneX"
          dataKey="value2"
          strokeWidth={2}
          strokeDasharray={'5'}
          stroke={ColorsEnum.GRAPH}
          fill="url(#colorPv2)"
        />
        <Area
          key={new Date().toDateString()}
          label={CustomizedLabel}
          dot={CustomizedDot}
          type="monotone"
          dataKey="value"
          strokeWidth={2}
          stroke={ColorsEnum.DARK_20}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default GraphGraph
