import {Activities, DietModel, Genders, MealPlanFormatTypes, SpeedModeList} from "../enums/onBoardingEnum";
import {UserModel} from "../store/OnBoarding/types";

export interface SkillModel {
  name: string;
  description: string;
  activeDescription: string;
  image: string;
  level: SkillLevel;
}

export enum SkillLevel {
  UNKNOWN = 0,
  NOVICE = 1,
  BASIC = 2,
  INTERMEDIATE = 3,
  ADVANCED = 4,
}

export interface ProductCategory {
  children: ProductCategory[];
  categoryImageUrl?: string;
  isParent?: boolean;
  name: string;
  id: number;
}

export enum UserConfigNames {
  DIET = 'diet',
  DISLIKED_INGREDIENT = 'dislikedIngredients',
  DISLIKED_CUISINE = 'dislikedCuisines',
  LIKED_CUISINE = 'likedCuisines',
  GOAL = 'goal',
  MEAL = 'meal',
  DISLIKED_INGREDIENT_CATEGORY_ID = 'dislikedIngredientCategoryId',
}

export type ConfigProductsCategoryInfo = {
  id: number;
  name: string;
};

export interface InitialStateModel {
  deliciousMeals: number;
  quickRecipes: number;
  recipeVariety: number;
  easyRecipes: number;
  budgetFriendlyMeals: number;
  user: UserModel | null;
  goals: string[];
  diet: string | null;
  dislikedIngredient: string[];
  likedCuisines: string[];
  dislikedCuisines: string[];
  skill: SkillLevel;
  mode: SpeedModeList;
  loading: boolean;
  age: number | null;
  weight: number | null;
  targetWeight: number | null;
  height: number | null;
  gender: Genders | null;
  activity: Activities | null;
  routeName: string;
  diets: DietModel[];
  imperialWeight: number | null;
  imperialTargetWeight: number | null;
  imperialHeightFt: number | null;
  imperialHeightInch: number | null;
  format: MealPlanFormatTypes | null;
  customMealTime: any[];
  productsCategories: TreeNodeType[];
  loadingProductsCategories: boolean;
  selectedProductsCategory: TreeNodeType[];
  configProductsCategory: number[];
  configProductsCategoryInfo: ConfigProductsCategoryInfo[];
}

export type ParamsConfig = {
  name: string;
  item: string;
};

export interface SaveUserConfigParams {
  appleId: string;
  email: string;
  skill: SkillLevel;
  userConfigs: ParamsConfig[];
  age: number;
  currentWeight: number;
  targetWeight: number;
  height: number;
  activity?: number;
  gender?: number;
  speed?: number;
  deliciousMeals?: number;
  quickRecipes?: number;
  recipeVariety?: number;
  easyRecipes?: number;
  budgetFriendlyMeals?: number;
}

export type ConfigTypeName =
  | UserConfigNames.DIET
  | UserConfigNames.GOAL
  | UserConfigNames.DISLIKED_INGREDIENT
  | UserConfigNames.LIKED_CUISINE
  | UserConfigNames.DISLIKED_CUISINE
  | UserConfigNames.DISLIKED_INGREDIENT_CATEGORY_ID
  | UserConfigNames.MEAL;

export interface UserConfigElement {
  id: number;
  name: ConfigTypeName;
  item: string;
}

export interface UserConfig {
  id: number;
  userDeviceId: string;
  skill: number;
  userConfigs: UserConfigElement[];
  loginCount: number;
  age: number;
  currentWeight: number;
  targetWeight: number;
  height: number;
  activity: number;
  gender: number;
  speed: number;
  appleId: string;
  email: string;
}

// export enum CollectedProductCategoryListType {
//   PRIMARY = 'primary',
//   SUB = 'sub',
//   MAIN = 'main',
// }

export interface TreeNodeType {
  id: number;
  name: string;
  image?: string;
  selected: boolean;
  partial: boolean;
  children?: TreeNodeType[];
}
