import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './BMA.module.css'
import {useAppSelector} from "../../../store/types";
import ColorfulBlock from "./components/ColorfulBlock";
import {useTDEE} from "../../../hooks/useTDEE";
import {ReactComponent as SummaryGoals} from "../../../assets/summary/SummaryGoal.svg";
import {ReactComponent as SummaryTarget} from "../../../assets/summary/SummaryTarget.svg";
import {ReactComponent as SummaryActivity} from "../../../assets/summary/SummaryActivity.svg";
import {ReactComponent as SummaryCalories} from "../../../assets/summary/SummaryCalories.svg";
import {activitiesList, Genders} from "../../../enums/onBoardingEnum";
import {MayorSystem} from "../../../store/Options/OptionsSlice";
import {ColorsEnum} from "../../../enums/ColorsEnum";

const manImage = require("../../../assets/summary/SummaryMale.webp");
const womanImage = require("../../../assets/summary/SummaryFemale.webp");

function calculateBMI(weight: number, heightCm: number): number {
  let heightM = heightCm / 100;
  const bmi = weight / (heightM * heightM);
  return Number(bmi.toFixed(1))
}

const getBmiTitle = (bmi: number): {
  value: string,
  color: string
} => {
  if (bmi <= 16) {
    return {
      value: 'Severe thinness',
      color: '#8B90FF'
    }
  } else if (bmi <= 18.5) {
    return {
      value: 'Underweight',
      color: '#8BCEFF'
    }
  } else if (bmi <= 25) {
    return {
      value: 'Normal weight',
      color: '#8BFFEA'
    }
  } else if (bmi <= 30) {
    return {
      value: 'Overweight',
      color: '#B0FF8B'
    }
  } else if (bmi <= 35) {
    return {
      value: 'Severe thinness',
      color: '#FFD260'
    }
  } else if (bmi <= 40) {
    return {
      value: 'Severely obese',
      color: '#FF5353'
    }
  } else {
    return {
      value: '',
      color: 'transparent'
    }
  }
}


const BMA: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(540)
  const {weight, targetWeight, imperialTargetWeight, height, gender, goals, activity, mode} = useAppSelector(state => state.onBoarding);
  const {mayorSystem} = useAppSelector(state => state.settings)
  const {calories} = useTDEE(mode)
  const bmi = useMemo(() => {
    if (weight && height) {
      return calculateBMI(weight, height)
    }
    return 0
  }, [height, weight]);
  const bmiName = useMemo(() => {
    return getBmiTitle(bmi)
  }, [bmi]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth)
      }
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const foundedActivity = activitiesList.find(el => el.name === activity)
  const UserActivityIcon = foundedActivity ? foundedActivity.icon.icon : SummaryActivity;

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth)
    }
  }, [containerRef.current]);

  // const
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Body Mass Index (BMA)
      </div>
      <div className={styles.titleContainer}>
        <span className={styles.bmiValue}>{bmi}</span>
        <span className={styles.bmiName} style={{
          color: bmiName.color
        }}>{bmiName.value}</span>
      </div>
      <div className={styles.description}>
        Height BMI leads to greater health risks. See <a target="_blank"
        href="https://www.who.int/europe/news-room/fact-sheets/item/a-healthy-lifestyle---who-recommendations" rel="noreferrer">Source of
        recommendations</a>
      </div>
      <div ref={containerRef}>
        <ColorfulBlock targetValue={bmi - 15} blockWidth={containerWidth}/>
      </div>
      <div className={styles.summaryContainer}>
        <div className={styles.summaryInfo}>
          <div className={styles.summaryInfoElement}>
            <div className={styles.iconContainer}>
              <SummaryGoals/>
            </div>
            <div className={styles.textContainer}>
              <div className={styles.summaryTitle}>Goals</div>
              <div className={[styles.summaryValue, styles.goalsList].join(' ')}>
                {goals.join(', ')}
              </div>
            </div>
          </div>
          <div className={styles.summaryInfoElement}>
            <div className={styles.iconContainer}>
              <SummaryTarget/>
            </div>
            <div className={styles.textContainer}>
              <div className={styles.summaryTitle}>Target weight</div>
              <div className={styles.summaryValue}>
                {targetWeight ? mayorSystem === MayorSystem.METRIC ? `${targetWeight} kg` : `${imperialTargetWeight} lbs` : null}
              </div>
            </div>
          </div>
          <div className={styles.summaryInfoElement}>
            <div className={styles.iconContainer}>
              <UserActivityIcon fill={ColorsEnum.DARK_20} />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.summaryTitle}>Activity level</div>
              <div className={styles.summaryValue}>{activity}</div>
            </div>
          </div>
          {weight !== targetWeight ? (
            <div className={styles.summaryInfoElement}>
              <div className={styles.iconContainer}>
                <SummaryCalories/>
              </div>
              <div>
                <div className={styles.textContainer}>
                  <div className={styles.summaryTitle}>Target calorie deficit</div>
                  <div className={styles.summaryValue}>{calories} kcal</div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {gender === Genders.MALE || gender === Genders.FEMALE ? (
          <div className={styles.imageContainer}>
            <img className={styles.image} src={gender === Genders.MALE ? manImage : womanImage} alt=""/>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BMA
