import React, {useEffect} from 'react';
import styles from './styles.module.css'
import {SourcesLinks} from "../../enums/sourcesLinks";
import {setRouteName} from "../../store/OnBoarding/OnBoardingSlice";
import {useAppDispatch} from "../../store/types";
import {getCurrentRouteKey} from "../../layout/OnBoardingLayout";

const CitationsAndSourcesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const routeKey = getCurrentRouteKey();
    if (routeKey) {
      dispatch(setRouteName(routeKey))
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>Citations and Sources</h1>
        </div>
        <div className={styles.pageContent}>
          <p className={styles.text}>
            Eatr is an app designed to streamline your nutrition planning and simplify your daily routine. It offers
            suggested starting points for meal plans based on user input, but the final settings adhere to user
            preferences and configurations. Note that Eatr is not intended for medical diagnosis, advice, or treatment.
            Always consult a licensed professional before making health-related decisions.
          </p>

          <div className={styles.blockContainer}>
            <h2 className={styles.subTitle}>How to Estimate Your BMR</h2>
            <div className={styles.adjustingContainer}>
              <p className={styles.text}>The Harris-Benedict formula estimates BMR by considering weight, height, age,
                and gender. There are 2
                formulas used to calculate BMR, in [kcal / 24hrs] for men and women respectively:</p>
              <ul className={styles.adjustingList}>
                <li className={styles.text}>BMR for Men = 66.47 + (13.75 * weight [kg]) + (5.003 * size [cm]) − (6.755 *
                  age [years])
                </li>
                <li className={styles.text}>BMR for Women = 655.1 + (9.563 * weight [kg]) + (1.85 * size [cm]) − (4.676
                  * age [years])
                </li>
              </ul>
            </div>
            <div className={styles.text}>Learn more about <a rel="nofollow noreferrer" href={SourcesLinks.BMR}>BMR</a>.
            </div>
          </div>

          <div className={styles.blockContainer}>
            <h2 className={styles.subTitle}>Adjusting for Activity Level</h2>
            <div className={styles.adjustingContainer}>
              <p className={styles.text}>Your Total Daily Energy Expenditure (TDEE) is adjusted based on your activity
                level:</p>
              <ul className={styles.adjustingList}>
                <li className={styles.text}>Low activity: BMR * 1.2</li>
                <li className={styles.text}>Medium activity: BMR * 1.375</li>
                <li className={styles.text}>High activity: BMR * 1.55</li>
                <li className={styles.text}>Very high activity: BMR * 1.725</li>
                <li className={styles.text}>Extra activity: BMR * 1.9</li>
              </ul>
            </div>
            <div className={styles.text}>Learn more about <a rel="nofollow noreferrer" href={SourcesLinks.TDEE}>TDEE</a>.
            </div>
          </div>

          <div className={styles.blockContainer}>
            <h2 className={styles.subTitle}>Diet Plans</h2>
            <div className={styles.dietsContainer}>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Balanced</div>
                <div className={styles.text}>This diet follows a macronutrient ratio of 55% carbs, 20% protein, and 25%
                  fat, adhering to <a rel="nofollow noreferrer" href={SourcesLinks.USDA}>USDA</a> and <a rel="nofollow noreferrer" href={SourcesLinks.WHO}>WHO</a> guidelines. The balanced diet plan includes a variety of foods to ensure all essential nutrients are
                  consumed, promoting overall health and well-being.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Pescatarian</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.PESCATARIAN_DIET}>diet</a> uses
                  a similar macronutrient ratio to the balanced diet (55% carbs, 20% protein, and 25%
                  fat)
                  and excludes meat. The pescatarian diet includes fish and other seafood as primary protein sources
                  while
                  maintaining the balanced macronutrient ratio.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Flexitarian</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.FLEXITARIAN_DIET}>diet</a> excludes red meat and
                  focuses on plant-based recipes while
                  maintaining the balanced diet
                  ratio of 55% carbs, 20% protein, and 25% fat. The flexitarian diet is mostly vegetarian but allows for
                  occasional meat and fish consumption, offering flexibility and promoting a plant-based lifestyle.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Vegetarian</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.VEGETARIAN_DIET}>diet</a> uses a similar
                  macronutrient ratio to the balanced diet (55%
                  carbs, 20% protein, and 25%
                  fat)
                  and excludes meat and fish. The vegetarian diet focuses on plant-based foods and includes dairy and
                  eggs,
                  providing a balanced intake of nutrients without meat and fish.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Low-carb</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.LOW_CARB_DIET}>diet</a> features 20% carbs,
                  25% protein, and 55% fat for a moderate
                  low-carb, high-protein
                  approach.
                  The low-carb diet reduces carbohydrate intake while increasing protein and fat, aiding in weight loss
                  and
                  blood sugar management.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Keto</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.KETO_DIET}>diet</a> uses a macronutrient
                  ratio of 10% carbs, 25% protein, and 65%
                  fat, making it a very
                  low-carb,
                  high-protein approach. The ketogenic diet emphasizes very low carb intake to induce ketosis, where the
                  body burns fat for fuel, aiding in weight loss and energy levels.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Mediterranean</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.MEDITERRANEAN_DIET}>diet</a> follows a 45% carb,
                  20% protein, and 35% fat ratio, emphasizing
                  healthy fats and
                  excluding
                  red meat. The Mediterranean diet is rich in fruits, vegetables, whole grains, and healthy fats like
                  olive
                  oil, promoting heart health and longevity.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Clean Eating</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.CLEAN_EATING_DIET}>diet</a> uses a similar
                  macronutrient ratio to the balanced diet (55%
                  carbs, 20% protein, and 25%
                  fat)
                  and excludes processed foods and added sugars. The clean eating plan focuses on whole, unprocessed
                  foods,
                  avoiding additives and preservatives to improve health and well-being.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Paleo</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.PALEO_DIET}>diet</a> uses a moderate
                  low-carb, high-protein approach with 20% carbs,
                  30% protein, and 50% fat,
                  excluding farmed or processed foods. The paleo diet mimics the eating habits of ancient humans,
                  focusing
                  on whole foods like meat, fish, fruits, and vegetables while avoiding grains and dairy.
                </div>
              </div>
              <div className={styles.dietContainer}>
                <div className={[styles.text, styles.boldText].join(' ')}>Vegan</div>
                <div className={styles.text}>This <a rel="nofollow noreferrer"
                                                     href={SourcesLinks.VEGAN_DIET}>diet</a> uses a macronutrient
                  ratio of 55% carbs, 15% protein, and 30%
                  fat, similar to the
                  balanced
                  diet but excludes all animal-based foods. The vegan diet excludes all animal products, relying on
                  plant-based foods to provide essential nutrients and promote overall health.
                </div>
              </div>
            </div>
          </div>

          <div className={styles.blockContainer}>
            <h2 className={styles.subTitle}>Eatr's Recommendation Goals</h2>
            <div>
              <p className={styles.text}>Eatr's calorie targets, nutritional goals, and Meal Plan recommendations are
                personalized based on an
                individual's gender, health condition, and stage of life.</p>
              <ol className={styles.goalsList}>
                <li>
                  <p className={[styles.text, styles.boldText].join(' ')}>Calorie Goal</p>
                  <div>
                    <p className={styles.text}>Eatr's calorie targets consist of BMR (Basal Metabolic Rate) and a
                      calorie deficit or proficit. To
                      ensure healthy weight goals achievement, we offer a calorie deficit or proficit range of 150-500
                      calories. This ensures that our users can feel satiated while still effectively achieving weight
                      goals.</p>
                    <p className={styles.text}>Learn more about <a rel="nofollow noreferrer"
                                                                   href={SourcesLinks.CALORIE_DEFICIT}>Calorie
                      Deficit</a>.</p>
                  </div>
                </li>
                <li>
                  <p className={[styles.text, styles.boldText].join(' ')}>Nutrition Goal</p>
                  <div>
                    <p className={styles.text}>Balanced nutrition is key to healthy achieving weight goals. We recommend
                      corresponding nutritional
                      target values to our users based on different physical conditions and calorie goals.</p>
                    <p className={styles.text}>Regarding the <a rel="nofollow noreferrer"
                                        href={SourcesLinks.CALCULATION_OF_NUTRITIONAL_TARGETS}>Calculation of
                      Nutritional Targets</a>.</p>
                  </div>
                </li>
                <li>
                  <p className={[styles.text, styles.boldText].join(' ')}>Heart Health</p>
                  <div>
                    <p className={styles.text}>We recommend that individuals trying to improve their health and lose
                      weight consume lower amounts
                      of
                      fat, sodium, and cholesterol. This is beneficial for heart health. <a rel="nofollow noreferrer"
                                                                                            href={SourcesLinks.FATS}>Fats</a>, <a
                        rel="nofollow noreferrer" href={SourcesLinks.SODIUM}>Sodium</a>, and <a
                        rel="nofollow noreferrer" href={SourcesLinks.CHOLESTEROL}>Cholesterol</a> are
                      related to heart health.</p>
                  </div>
                </li>
              </ol>
            </div>
          </div>

          <div className={styles.blockContainer}>
            <h2 className={styles.subTitle}>Contact Information:</h2>
            <p className={styles.text}>Any questions or feedback? Please feel free to reach out to <a
              href="mailto:contact@eatr.com">contact@eatr.com</a> –
              we're always happy to hear
              your thoughts!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CitationsAndSourcesPage;
