import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZdKOrglpOd-mYDPKqGj_mP5OwsG27XYE",
  authDomain: "eatr-417214.firebaseapp.com",
  projectId: "eatr-417214",
  storageBucket: "eatr-417214.appspot.com",
  messagingSenderId: "1097928241303",
  appId: "1:1097928241303:web:3a9091e1376bace8aab9d2",
  measurementId: "G-FVH7W6MJLS"
};

const app = initializeApp(firebaseConfig);
export const analitics = getAnalytics(app);
