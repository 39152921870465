import React, {useEffect, useRef} from "react";
import styles from './PrioritiesPage.module.css'
import Slider from "rc-slider";
import './slider.css';
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {
  setBudgetFriendlyMeals,
  setDeliciousMeals, setEasyRecipes,
  setQuickRecipes,
  setRecipeVariety
} from "../../../store/OnBoarding/OnBoardingSlice";
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import {useHistory} from "react-router-dom";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

const min = 1;
const max = 10;

type Variants = 'deliciousMeals' |
  'quickRecipes' |
  'recipeVariety' |
  'easyRecipes' |
  'budgetFriendlyMeals'

const PrioritiesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory()
  const {
    deliciousMeals,
    quickRecipes,
    recipeVariety,
    easyRecipes,
    budgetFriendlyMeals
  } = useAppSelector(state => state.onBoarding)
  const handleChange = (value: number | number[], type: Variants) => {
    if (!Array.isArray(value)) {
      switch (type) {
        case "easyRecipes":
          dispatch(setEasyRecipes(value))
          break;
        case "deliciousMeals":
          dispatch(setDeliciousMeals(value))
          break;
        case "recipeVariety":
          dispatch(setRecipeVariety(value))
          break;
        case "budgetFriendlyMeals":
          dispatch(setBudgetFriendlyMeals(value))
          break;
        case "quickRecipes":
          dispatch(setQuickRecipes(value))
          break;
      }
    }
  };
  const onPressNext = () => {
    history.push(OnBoardingRoutesEnum.USERS_PAGE)
  }

  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 60 : 'calc(100dvh - 60px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <StepInfo
          title="Priorities"
          description="How would you like to balance the following areas when building your meal plan?"
        />
        <div className={styles.listData}>
          <div className={styles.optionsList}>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderTitle}>
                Quick Recipes
              </div>
              <Slider dots min={min} max={max} step={1} onChange={(value) => handleChange(value, 'quickRecipes')}
                      defaultValue={quickRecipes}/>
              <div className={styles.sliderLabels}>
                <span>Not Important</span>
                <span>Very Important</span>
              </div>
            </div>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderTitle}>
                Recipe Variety
              </div>
              <Slider dots min={min} max={max} step={1} onChange={(value) => handleChange(value, 'recipeVariety')}
                      defaultValue={recipeVariety}/>
              <div className={styles.sliderLabels}>
                <span>Not Important</span>
                <span>Very Important</span>
              </div>
            </div>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderTitle}>
                Easy Recipes
              </div>
              <Slider dots min={min} max={max} step={1} onChange={(value) => handleChange(value, 'easyRecipes')}
                      defaultValue={easyRecipes}/>
              <div className={styles.sliderLabels}>
                <span>Not Important</span>
                <span>Very Important</span>
              </div>
            </div>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderTitle}>
                Delicious Meals
              </div>
              <Slider dots min={min} max={max} step={1} onChange={(value) => handleChange(value, 'deliciousMeals')}
                      defaultValue={deliciousMeals}/>
              <div className={styles.sliderLabels}>
                <span>Not Important</span>
                <span>Very Important</span>
              </div>
            </div>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderTitle}>
                Budget-Friendly Meals
              </div>
              <Slider dots min={min} max={max} step={1} onChange={(value) => handleChange(value, 'budgetFriendlyMeals')}
                      defaultValue={budgetFriendlyMeals}/>
              <div className={styles.sliderLabels}>
                <span>Not Important</span>
                <span>Very Important</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={onPressNext}/>
    </div>
  )
}

export default PrioritiesPage
