import {IconProps} from "../types/types";
import React from "react";

const MealPlanCustomIcon: React.FC<IconProps> = ({fill}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5054_7049)">
        <circle cx="16" cy="16" r="14" stroke="#8DCC6F" strokeWidth="1.5" />
        <circle cx="16" cy="16" r="11" stroke="#8DCC6F" strokeWidth="1.5" />
        <path
          d="M16 12.25C16 12.5815 16.1317 12.8995 16.3661 13.1339C16.6005 13.3683 16.9185 13.5 17.25 13.5C17.5815 13.5 17.8995 13.3683 18.1339 13.1339C18.3683 12.8995 18.5 12.5815 18.5 12.25M16 12.25C16 11.9185 16.1317 11.6005 16.3661 11.3661C16.6005 11.1317 16.9185 11 17.25 11C17.5815 11 17.8995 11.1317 18.1339 11.3661C18.3683 11.6005 18.5 11.9185 18.5 12.25M16 12.25H11M18.5 12.25H21M12.25 16C12.25 16.3315 12.3817 16.6495 12.6161 16.8839C12.8505 17.1183 13.1685 17.25 13.5 17.25C13.8315 17.25 14.1495 17.1183 14.3839 16.8839C14.6183 16.6495 14.75 16.3315 14.75 16M12.25 16C12.25 15.6685 12.3817 15.3505 12.6161 15.1161C12.8505 14.8817 13.1685 14.75 13.5 14.75C13.8315 14.75 14.1495 14.8817 14.3839 15.1161C14.6183 15.3505 14.75 15.6685 14.75 16M12.25 16H11M14.75 16H21M17.875 19.75C17.875 20.0815 18.0067 20.3995 18.2411 20.6339C18.4755 20.8683 18.7935 21 19.125 21C19.4565 21 19.7745 20.8683 20.0089 20.6339C20.2433 20.3995 20.375 20.0815 20.375 19.75M17.875 19.75C17.875 19.4185 18.0067 19.1005 18.2411 18.8661C18.4755 18.6317 18.7935 18.5 19.125 18.5C19.4565 18.5 19.7745 18.6317 20.0089 18.8661C20.2433 19.1005 20.375 19.4185 20.375 19.75M17.875 19.75H11M20.375 19.75H21"
          stroke="#8DCC6F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5054_7049">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default MealPlanCustomIcon
