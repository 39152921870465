import { useEffect } from "react";

const Facebook = () => {
  useEffect(() => {
    const ReactPixel =  require('react-facebook-pixel');
    ReactPixel.default.init(process.env.REACT_APP_PIXEL);
  }, []); // Add dependencies if necessary

  return null;
};

export default Facebook;
