import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";

const NotFoundScreen: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    history.push('/')
  }, []);
  return (
    <div>
      NotFoundPage
    </div>
  )
}

export default NotFoundScreen
