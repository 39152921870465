import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {useHistory} from "react-router-dom";
import styles from './Weight.module.css'
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import NumberField from "../../NumberField/NumberField";
import {setWeight, setWeightImperial} from "../../../store/OnBoarding/OnBoardingSlice";
import mixpanel from "mixpanel-browser";
import ChangeMetricButton from "../../ChangeMetricButton/ChangeMetricButton";
import {lb, useConvert} from "../../../hooks/useConvert";
import {MayorSystem} from "../../../store/Options/OptionsSlice";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";

const Weight: React.FC = () => {
  const history = useHistory()
  const {mayorSystem} = useAppSelector(state => state.settings);
  const [onBoardingWeight, setOnBoardingWeight] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const {weight, imperialWeight} = useAppSelector(state => state.onBoarding);
  const dispatch = useAppDispatch();
  const [onBoardingImperialWeight, setOnBoardingImperialWeight] =
    useState<string>('');
  const [errorImperial, setErrorImperial] = useState('');
  const {lbToKg} = useConvert();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const maxLength = e.target.maxLength;
    if (e.target.value.length <= maxLength) {
      let value = e.target.value.slice(0, 3); // Truncate to 3 characters
      setOnBoardingWeight(value);
      setErrorText('')
      setErrorImperial('')
      const gInLb = Math.round((Number(value) * 1000) / lb);
      setOnBoardingImperialWeight(gInLb > 0 ? gInLb.toString() : '0');
    }
  };

  const onChangeImperialWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const maxLength = e.target.maxLength;
    if (e.target.value.length <= maxLength) {
      let value = e.target.value.slice(0, 3); // Truncate to 3 characters
      setOnBoardingImperialWeight(value)
      setErrorText('')
      setErrorImperial('')
      const gInLb = lbToKg(Number(value));
      setOnBoardingWeight(Math.round(gInLb) > 0 ? Math.round(gInLb).toString() : '0');
    }
  };

  const onPressNext = () => {
    const valid = validate(onBoardingWeight);
    const validImperial = validateWeightImperial(onBoardingImperialWeight);
    if (typeof valid !== 'string' && typeof validImperial !== 'string') {
      dispatch(setWeight(Number(onBoardingWeight)));
      dispatch(
        setWeightImperial(Number(onBoardingImperialWeight?.replace(',', '.'))),
      );
      const eventParams = {
        current_weight:
          mayorSystem === MayorSystem.METRIC
            ? onBoardingWeight
            : onBoardingImperialWeight,
        weight_metric: onBoardingWeight,
        unit: mayorSystem === MayorSystem.METRIC ? 'kg' : 'lb',
      };
      mixpanel.track('weight_input_submitted', eventParams);
      logEvent(analitics, 'weight_input_submitted', eventParams);
      history.push('/onboarding/target-weight');
      setErrorText('');
    } else {
      if (typeof valid === 'string' && typeof validImperial === 'string') {
        setErrorText(valid);
        setErrorImperial(validImperial);
      }
      dispatch(setWeight(null));
    }
  }

  function validateWeightImperial(value: string): boolean | string {
    const gInLb = lbToKg(Number(value));
    if (value.length === 0) {
      return 'Please enter your height.';
    }
    if (Math.round(gInLb) < 25) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight less than 55 Lb.';
    }
    if (Math.round(gInLb) > 400) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight over 882 Lb.';
    }
    return true;
  }

  function validate(weight: string): boolean | string {
    if (weight.length === 0) {
      return 'Please enter your weight.';
    }
    if (Math.floor(Number(weight)) < 25) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight less than 25 kg.';
    }
    if (Math.floor(Number(weight)) > 400) {
      return 'For this weight, we cannot calculate the nutritional balance. Weight over 400 kg.';
    }
    return true;
  }

  useEffect(() => {
    if (weight) {
      setOnBoardingWeight(weight.toString());
    }
    if (imperialWeight) {
      setOnBoardingImperialWeight(imperialWeight.toString())
    }
  }, [weight, imperialWeight]);

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
     <div className={styles.containerData}>
       <StepInfo title={"Weight"} description={"What is your current weight?"}/>
       <div className={styles.listContainer}>
         <div className={styles.inputContainer}>
           {mayorSystem === MayorSystem.METRIC ? (
             <NumberField
               value={onBoardingWeight}
               label={"kg"}
               onChange={onChange}
               maxLength={3}
               error={errorText}
               autoFocus={true}
               showError={false}
               onPressEnter={onPressNext}
             />
           ) : (
             <NumberField
               value={onBoardingImperialWeight}
               label={"lb"}
               onChange={onChangeImperialWeight}
               maxLength={3}
               autoFocus={true}
               showError={false}
               error={errorImperial}
               onPressEnter={onPressNext}
             />
           )}
         </div>
         {mayorSystem === MayorSystem.METRIC ? (
           <div style={{
             paddingLeft: 24,
             paddingRight: 24,
             textAlign: 'center',
             color: ColorsEnum.ERROR_TEXT,
             fontFamily: 'Nunito Sans, sans-serif',
           }}>
             {errorText}
           </div>
         ) : (
           <div style={{
             paddingLeft: 24,
             paddingRight: 24,
             textAlign: 'center',
             color: ColorsEnum.ERROR_TEXT,
             fontFamily: 'Nunito Sans, sans-serif',
           }}>
             {errorImperial}
           </div>
         )}
         <ChangeMetricButton metricLabel={'Kg'} imperialLabel={'Lbs'}/>
       </div>
     </div>
      <NextButton fixed={true} disabled={onBoardingWeight.length === 0 || errorText.length > 0 || errorImperial.length > 0}
                  onClick={onPressNext}/>
    </div>
  )
}

export default Weight;
