import React, {useCallback, useMemo} from 'react';
import {Product} from '../../../../store/Products/Products';
import styles from './ProductItem.module.css'
import {useAppDispatch, useAppSelector} from "../../../../store/types";
import {setCouponIndex, setSelectedProduct} from "../../../../store/Products/ProductsSlice";
import {useSubscriptionData} from "../../../../hooks/useSubscriptionData";

interface IProduct {
  product: Product;
  additional: {
    oldValue: number;
    perDay: string;
  }
  coupon: {
    id: string;
    price: number
  },
  mostPopular?: boolean,
  index: number
}

const ProductItem: React.FC<IProduct> = ({product, additional, coupon, index, mostPopular}) => {
  const {selectedProduct} = useAppSelector(state => state.products)
  const dispatch = useAppDispatch()
  const selected = useMemo(() => {
    return selectedProduct ? product.id === selectedProduct.id : false;
  }, [selectedProduct])
  const selectProduct = () => {
    if (!selected) {
      dispatch(setSelectedProduct(product))
      dispatch(setCouponIndex(index))
    }
  }

  function getCurrencySymbol(currencyCode: string): string {
    return (0).toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).replace(/\d/g, '').trim();
  }

  const opacity = useMemo(() => {
    return {
      opacity: selected ? 1 : 0.4
    }
  }, [selected])

  return (
    <div className={[styles.productContainer, selected ? styles.selected : null].join(' ')} onClick={selectProduct} >
      <div className={styles.checkbox}>
        <input checked={selected} type="checkbox"/>
        <span className={styles.checkmark}></span>
      </div>
      <div className={styles.productInfo} style={opacity}>
        <div className={styles.name}>
        {product.name}
        </div>
        <div>
          <span className={styles.oldPrice}>{getCurrencySymbol(product.currency || 'usd')}{product.price}</span>
          <span className={styles.price}>{getCurrencySymbol(product.currency || 'usd')}{(product.price - (coupon?.price || 0)).toFixed(2)}</span>
        </div>
      </div>
      <div style={opacity}>
        <div className={styles.perDatInfo}>
          <span className={styles.perDayCurrency}>{getCurrencySymbol(product.currency || 'usd')}</span>
          <span className={styles.perDay}>{additional?.perDay}</span>
        </div>
        <div className={styles.perDayText}>per day</div>
      </div>
      {mostPopular ? (
        <div className={styles.mostPopular}>
          MOST POPULAR
        </div>
      ) : null}
    </div>
  )
}

export default ProductItem
