import React, {useEffect, useRef} from "react";
import StepInfo from "../StepInfo";
import NextButton from "../../NextButton/NextButton";
import {CuisinesList} from "./cuisinesList";
import LikeIcon from "../../../icons/LikeIcon";
import styles from './Cuisines.module.css'
import DislikeIcon from "../../../icons/DislikeIcon";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import {useAppDispatch, useAppSelector} from "../../../store/types";
import {LikedDislikedEnum, OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {setCuisines} from "../../../store/OnBoarding/OnBoardingSlice";
import {useHistory} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {logEvent} from "firebase/analytics";
import {analitics} from "../../../helper/Firebase";

const Cuisines: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch();
  const {likedCuisines, dislikedCuisines, goals} = useAppSelector(
    state => state.onBoarding,
  );
  function likeCuisines(value: string) {
    dispatch(setCuisines({value, type: LikedDislikedEnum.LIKED}));
  }
  function dislikeCuisines(value: string) {
    dispatch(setCuisines({value, type: LikedDislikedEnum.DISLIKED}));
  }

  const nextPage = () => {
    const options: {
      [key: string]: string;
    } = {};
    CuisinesList.forEach(cuisine => {
      let status = '';
      if (likedCuisines.includes(cuisine.name)) {
        status = 'liked';
      } else if (dislikedCuisines.includes(cuisine.name)) {
        status = 'not_liked';
      } else {
        status = 'not_selected';
      }
      options[cuisine.name + '_cuisine'] = status;
    });
    mixpanel.track('user_cuisine_preferences_set', options);
    logEvent(analitics, 'user_cuisine_preferences_set', options);
    if (goals.includes('Lose weight')) {
      history.push(OnBoardingRoutesEnum.LOSE_TWICE_PAGE);
    } else {
      history.push(OnBoardingRoutesEnum.COOKING_SKILL);
    }
  };

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container}>
      <div className={styles.containerData}>
        <StepInfo title={'Cuisines '}
                  description={'Do you have particular likes or dislikes with any of these cuisines?'}/>
        <div className={styles.listContainer}>
          <div style={{
            paddingBottom: 24
          }}>
            {CuisinesList.map((el, index) => {
              return (
                <div key={index} className={styles.item}>
                  <div className={styles.itemInfo}>
                    <img className={styles.image} src={el.image} alt={el.name}/>
                    <span className={styles.name}>{el.name}</span>
                  </div>
                  <div className={styles.iconsContainer}>
                    <div className={styles.icon} onClick={() => likeCuisines(el.name)}>
                      <LikeIcon fill={ColorsEnum.DARK_20}
                                stroke={likedCuisines && likedCuisines.includes(el.name) ? ColorsEnum.DARK_20 : 'transparent'}/>
                    </div>
                    <div className={styles.icon} onClick={() => dislikeCuisines(el.name)}>
                      <DislikeIcon fill={ColorsEnum.GRAY_SCALE}
                                   stroke={dislikedCuisines && dislikedCuisines.includes(el.name) ? ColorsEnum.GRAY_SCALE : 'transparent'}/>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextPage}/>
    </div>
  )
}

export default Cuisines
