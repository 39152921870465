import React from "react";
import styles from './ProductMainCategoryItem.module.css'
import DisableIcon from "../../../../../icons/DisableIcon";
import {ColorsEnum} from "../../../../../enums/ColorsEnum";

interface ProductMainCategoryItemProps {
  name: string;
  onPress: () => void;
  selected: boolean;
  width?: number;
}

const ProductMainCategoryItem: React.FC<ProductMainCategoryItemProps> = ({
  name,
  onPress,
  selected,
  width
}) => {
  return (
    <div onClick={onPress} className={styles.container}>
      <div className={styles.fakeIcon}></div>
      <div className={styles.textContainer}>
        <span className={styles.text} style={{
          color: ColorsEnum.GRAY_SCALE,
          maxWidth: width ? width - 120 : 'initial'
        }}>{name}</span>
      </div>
      <div className={styles.iconContainer}>
        <DisableIcon fill={selected ? ColorsEnum.ERROR_TEXT : ColorsEnum.GRAY_SCALE} />
      </div>
    </div>
  )
}

export default ProductMainCategoryItem
