export enum ColorsEnum {
  DARK_80 = '#23331C',
  DARK_60 = '#466638',
  DARK_40 = '#6A9953',
  DARK_20 = '#79C257',
  NORMAL = '#B0FF8B',
  NORMAL_20 = '#C0FFA2',
  NORMAL_40 = '#D0FFB9',
  DISABLED_BUTTON = '#bbe0a9',
  NORMAL_60 = '#DFFFD1',
  NORMAL_80 = '#EFFFE8',
  NORMAL_90 = '#f7fef3',
  GRAY_BLACK = '#090D07',
  GRAY_70 = '#B5B6B5',
  WHITE_BG_TRANSPARENT = '#FFFFFFCC',
  TRANSPARENT = 'rgba(255, 255, 255, 0)',
  BORDER_GRAY = '#F3F3F3',
  GRAY_SCALE = '#9D9E9C',
  GRAY_SCALE_10 = '#e6e7e6',
  GRAY_SCALE_20 = '#E8EDF6',
  GRAY_SCALE_70 = '#B5B6B5',
  GRAY_SCALE_90 = '#E6E7E6',
  GRAY_SCALE_95 = '#F3F3F3',
  GRAY_SCALE_WHITE = '#FFFFFF',
  PINK = '#ffd0fd',
  VIOLET = '#e4d1ff',
  DIET_GROUP = '#d1ffd5',
  DISH_GROUP = '#d1fef7',
  TYPE_GROUP = '#d2eaff',
  FEATURED_GROUP = '#d1d3ff',
  YELLOW = '#FFD260',
  CABS = '#d2eaff',
  PROTEINS = '#dfffd1',
  FIBER = '#d1d3ff',
  FATS = '#d1fef7',
  RED = '#FF5353',
  RED_WITH_OPACITY = 'rgba(255,83,83,0.25)',
  EATING_OUT = '#d1fef7',
  EATING_OUT_ICON = '#6fcbbb',
  ERROR_TEXT = '#FFA2A2',
  ERROR_BG = '#FFF6F6',
  BREAKFAST = '#B0FF8B',
  LUNCH = '#8BFFEA',
  DINNER = '#8BCEFF',
  GRAPH = '#8B90FF',
}
