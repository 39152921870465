import React from 'react';
import {Cell, Pie, PieChart} from "recharts";

interface PieChartComponentProps {
  data: {
    name: string,
    value: number,
    color: string
  }[]
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ data }) => {
  return (
    <PieChart width={140} height={140} onClick={() => null }>
      <Pie
        data={data}
        cx="50%" cy="50%"
        innerRadius={40}
        outerRadius={70}
        fill="#8884d8"
        paddingAngle={5}
        isAnimationActive={false}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell style={{outline: 'none'}} key={`cell-${index}`}  fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PieChartComponent;
