import React from "react";
import styles from "./NextButton.module.css";
import {Oval} from "react-loader-spinner";

interface NextButtonProps {
  onClick?: () => void;
  text?: string;
  disabled?: boolean;
  withGradient?: boolean
  fixed?: boolean
  subButton?: boolean;
  subText?: string;
  subOnClick?: () => void;
  loading?: boolean
  withPadding?: boolean
}

const NextButton: React.FC<NextButtonProps> = ({onClick, withPadding, loading, text = 'Next', disabled, withGradient, fixed, subButton, subOnClick, subText}) => {
  const onPressClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick()
      }
    }
  }
  return (
    <div className={[styles.buttonContainer, fixed ? styles.fixed : ''].join(' ')}>
      <div style={{position: 'relative', paddingLeft: withPadding ? 12 : 0,
        paddingRight: withPadding ? 12 : 0,}}>
        <div className={[withGradient ? styles.withGradient : null].join(' ')}>
          {subButton ? (
            <div className={[styles.nextButtonContainer, styles.subButtonContainer].join(' ')}
                 onClick={subOnClick}>
              {subText}
            </div>
          ) : null}
          <div className={[styles.nextButtonContainer, disabled ? styles.disabled : null].join(' ')}
               onClick={onPressClick}>
            {loading ? <Oval
              strokeWidth="4"
              color={'white'}
              secondaryColor={'rgba(255, 255, 255, 0.25)'}
              width="30"
              height="30"
              visible={true}
            /> : text}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NextButton
