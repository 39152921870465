import React, {useEffect, useRef} from "react";
import styles from './PlanFeatures.module.css'
import {ReactComponent as VSIcon} from "../../../assets/icons/VSIcon.svg";
import {ColorsEnum} from "../../../enums/ColorsEnum";
import PlanRow from "./PlanRow/PlanRow";
import NextButton from "../../NextButton/NextButton";
import {useHistory} from "react-router-dom";
import {OnBoardingRoutesEnum} from "../../../enums/onBoardingEnum";
import {useAppSelector} from "../../../store/types";
import {MayorSystem} from "../../../store/Options/OptionsSlice";
import {useScreenHeight} from "../../../context/ScreenHeightContext";

const planItems = [
  {
    name: 'More convenient',
    description: '10-minute quick meals',
    icon: require('../../../assets/smiles/SmilingFace.png'),
    color: ColorsEnum.NORMAL_60
  },
  {
    name: 'More nutritious',
    description: 'Variety of colorful ingredients',
    icon: require('../../../assets/smiles/LoveEmodji.png'),
    color: ColorsEnum.FATS
  },
  {
    name: 'More efficient',
    description: 'Lose n lbs on average per week',
    icon: require('../../../assets/smiles/PartyEmodji.png'),
    color: ColorsEnum.CABS
  }
]

const PlanFeatures: React.FC = () => {
  const {weight, targetWeight, imperialWeight, imperialTargetWeight} = useAppSelector(state => state.onBoarding)
  const {mayorSystem} = useAppSelector(state => state.settings)
  const history = useHistory()
  const nextPage = () => {
    history.push(OnBoardingRoutesEnum.DISLIKES)
  }
  if (weight && targetWeight && imperialWeight && imperialTargetWeight) {
    if (weight > targetWeight) {
      if (mayorSystem === MayorSystem.METRIC) {
        planItems[2].description = `Lose ${weight - targetWeight} kg on average per week`
      } else {
        planItems[2].description = `Lose ${imperialWeight - imperialTargetWeight} lbs on average per week`
      }
    } else {
      planItems[2].description = 'Health benefits in less than 2 weeks'
    }
  }
  const {screenHeight, isWebView} = useScreenHeight()

  const refElement = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo(0,0);
    }
  }, []);
  return (
    <div ref={refElement} className={styles.container} style={{
      height: window.screen.width <= 540 ? isWebView ? screenHeight - 80 - 68 : 'calc(100dvh - 68px)' : 'auto'
    }}>
      <div className={styles.containerData}>
        <div className={styles.listData}>
          <div className={styles.title}>
            Every meal is easy & quick
          </div>
          <div className={styles.images}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={require('../../../assets/images/FoodFirst_cropped.webp')} alt=""/>
              <div className={styles.imageText}>
                Eatr’s plan
              </div>
            </div>
            <div className={styles.iconVS}>
              <VSIcon/>
            </div>
            <div className={styles.imageContainer}>
              <img className={[styles.image, styles.imageFiltered].join(' ')}
                   src={require('../../../assets/images/FoodSecond_cropped.webp')} alt=""/>
              <div className={styles.imageText}>
                Others’ plan
              </div>
            </div>
          </div>
          <div className={styles.smilesContainer}>
            {planItems.map((el, index) => {
              return <PlanRow
                timeout={index === 0 ? 1000 : (index + 1) * 1000}
                key={index + '-features'}
                name={el.name}
                description={el.description}
                color={el.color}
                icon={el.icon}
              />
            })}
          </div>
        </div>
      </div>
      <NextButton fixed={true} withGradient={true} onClick={nextPage}/>
    </div>
  )
}

export default PlanFeatures;
