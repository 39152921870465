import React, {useEffect, useRef, useState} from 'react';
import styles from './SpecialOfferButton.module.css';
import {ReactComponent as Clock} from '../../../assets/clock.svg'

const SpecialOfferButton: React.FC = () => {
  const [time, setTime] = useState(300);
  const [isFixedVisible, setIsFixedVisible] = useState(false);
  const clockRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the interval on component unmount
    }
  }, [time]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFixedVisible(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0,
      }
    );

    if (clockRef.current) {
      observer.observe(clockRef.current);
    }

    return () => {
      if (clockRef.current) {
        observer.unobserve(clockRef.current);
      }
    };
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      <div ref={clockRef} className={styles.buttonContainer}>
        <Clock/>
        <div className={styles.buttonText}>
          This offer ends in {formatTime(time)} min
        </div>
      </div>
      {isFixedVisible && (
        <div className={styles.fixedClock}>
          Introductory offer expires in {formatTime(time)}
        </div>
      )}
    </div>
  )
}

export default SpecialOfferButton;
