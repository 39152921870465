import React, {useRef, useState} from 'react';
import styles from './PromoCodeModal.module.css'
import CloseIcon from "../../../../icons/CloseIcon";
import {ColorsEnum} from "../../../../enums/ColorsEnum";

interface IPromoCodeModalProps {
  close: () => void
}

const PromoCodeModal: React.FC<IPromoCodeModalProps> = ({close}) => {
  const [error, setError] = useState<string>('')
  const [code, setCode] = useState<string>('');
  const containerWidth = useRef<HTMLDivElement>(null);
  const onSubmit = () => {
    if (code.length === 0) {
      setError('Please enter a code')
      return
    }
    setError('The promo code you entered is invalid. Please check the code and try again!')
  }
  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal} ref={containerWidth}>
        <div className={styles.closeIcon} onClick={close}><CloseIcon fill={ColorsEnum.GRAY_BLACK}/></div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            Apply your promo code to get an extra discount
          </div>
        </div>
        <div className={styles.field}>
          <label className={styles.label} htmlFor="discount">Promo code</label>
          <input className={styles.inputField}
                 style={{
                   backgroundColor: error.length > 0 ? ColorsEnum.ERROR_BG : ColorsEnum.NORMAL_80,
                   borderColor: error.length > 0 ? ColorsEnum.ERROR_TEXT + ' !important' : 'transparent'
                 }}
                 type="text" onChange={(e) => {
                   setError('')
            setCode(e.target.value)
          }} value={code}/>

          <div className={styles.error}>{error.length > 0 ? error : null}</div>

        </div>
        <button className={styles.closeBtn} onClick={onSubmit}>Apply</button>
      </div>
    </div>
  )
}

export default PromoCodeModal
