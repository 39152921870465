import {ImageSizeEnum} from "../hooks/appEnum";

export const getImageLinkWithResize = (
  filePath: string,
  size: ImageSizeEnum,
) => {
  const url = filePath.slice(0, filePath.lastIndexOf('/'));
  const fileName = filePath.slice(filePath.lastIndexOf('/') + 1);

  const base = window.location.hostname.includes("eatr.com") ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_DEV
  const prefix = !window.location.hostname.includes("eatr.com") ? 'dev-' : ''
  return `${
    base + '/' + prefix
  }${url}/${size}-${fileName}`;
  // return `${
  //   base + '/' + prefix
  // }${url}/${fileName}`;
};
