import React from 'react';
import GlobalReview from "./GlobalReview/GlobalReview";
import CustomerReviews from "./CustomerReviews/CustomerReviews";

const AppReview: React.FC = () => {
  return (
    <div>
      <GlobalReview />
      <CustomerReviews />
    </div>
  )
}

export default AppReview;
