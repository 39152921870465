import React, {FormEvent, useEffect, useState} from "react";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import useSubscribe from "../../../../hooks/useSubscribe";
import {useAppDispatch, useAppSelector} from "../../../../store/types";
import {useHistory} from "react-router-dom";
import {createSubscription} from "../../../../store/Subscription/SubscriptionSlice";
import {setClearOnboarding} from "../../../../store/OnBoarding/OnBoardingSlice";
import {logoutUser} from "../../../../store/UserSlice/UserSlice";
import Skeleton from "react-loading-skeleton";
import ExpIcon from "../../../../icons/ExpIcon";
import {Oval} from "react-loader-spinner";
import {useSubscriptionData} from "../../../../hooks/useSubscriptionData";

import styles from './styles.module.css'
import {ReactComponent as VisaIcon} from "../../../../assets/icons/visa.svg";
import {ReactComponent as MasterCardIcon} from "../../../../assets/icons/mastercard.svg";
import {ReactComponent as MaestroIcon} from "../../../../assets/icons/maestro.svg";
import {ReactComponent as AmericanExpressIcon} from "../../../../assets/icons/american-express.svg";
import currentStyles from "../../styles.module.css";
import mixpanel from "mixpanel-browser";

const options = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Nunito Sans", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "transparent"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};



const icons = [VisaIcon, MasterCardIcon, MaestroIcon, AmericanExpressIcon];

const Card: React.FC = () => {
  const {couponsList} = useSubscriptionData()
  const history = useHistory();
  const dispatch = useAppDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const {errorPay, successPay, errorMessage, sendEventPaymentStartWeb} = useSubscribe()
  const {selectedProduct, couponIndex} = useAppSelector(state => state.products)
  const [isCardNumberLoading, setCardNumberLoading] = useState<boolean>(false)
  const [isCardExpiryLoading, setCardExpiryLoading] = useState<boolean>(false)
  const [isCardCvcLoading, setCardCvcLoading] = useState<boolean>(false)
  const [cardNumberError, setCardNumberError] = useState<string>('')
  const [cardExpiryError, setCardExpiryError] = useState<string>('')
  const [cardCvcError, setCardCvcError] = useState<string>('');
  const {clientSecret} = useAppSelector(state => state.subscription)
  const [active, setActive] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  useEffect(() => {
    if (elements) {
      const elementCvc = elements.getElement('cardCvc');
      const elementExpiry = elements.getElement('cardExpiry');
      const elementNumber = elements.getElement('cardNumber');
      setCardCvcLoading(true)
      setCardExpiryLoading(true)
      setCardNumberLoading(true)
      elementExpiry?.on('ready', () => {
        setCardCvcLoading(false)
      })
      elementCvc?.on('ready', () => {
        setCardExpiryLoading(false)
      })
      elementNumber?.on('ready', () => {
        setCardNumberLoading(false)
      })
    }
  }, [elements]);

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();
    if (!stripe || !elements || !clientSecret) {
      return;
    }
    const cardNumber = elements.getElement("cardNumber")

    if (!cardNumber) {
      return
    }

    setProcessing(true);

    let {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    });

    if (error && error.message) {
      errorPay(error.message)
      return
    }
    if (paymentMethod && selectedProduct) {
      dispatch(createSubscription({
        priceId: selectedProduct.priceId,
        paymentMethodId: paymentMethod.id,
        couponId: couponsList[couponIndex].id
      })).then( async (redux) => {
        if (redux.meta.requestStatus === 'fulfilled' && paymentMethod && redux.payload) {
          const response = redux.payload
          if (typeof response !== 'string') {
            let {error, paymentIntent} = await stripe.confirmCardPayment(
              response.client,
              {
                payment_method: paymentMethod.id,
              }
            );
            mixpanel.track('payment_start_mail')
            if (error) {
              return;
            }
            if (paymentIntent?.status === 'succeeded') {
              setProcessing(false);
              mixpanel.track('payment_complete_mail')
              successPay().then(() => {
                dispatch(setClearOnboarding())
                dispatch(logoutUser())
                history.push('/complete')
              })
            } else {
              setProcessing(false);
            }
          }
        }
        if (redux.meta.requestStatus === 'rejected') {
          const response = redux.payload;
          if (typeof response === 'string') {
            errorPay(response)
            setProcessing(false);
          }
        }
      })

    }
    // setPaymentIntent(setupIntent);
    // dispatch(clearState())
  }

  let timeout: NodeJS.Timeout;

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  return (
    <form  onSubmit={handleSubmit}>
      <div className={styles.paymentElementTitle}>
        <div className={styles.titleContainer}>
          <div className={styles.paymentTitle}>Credit card</div>
        </div>
        <div className={styles.paymentsIconsList}>{icons.map(El => <El/>)}</div>
      </div>
      <div className={styles.driver}></div>
      <div className={styles.formContainer}>
        <div>
          <label className={styles.field}>
            <div className={styles.label}>
              Card number
            </div>
            {isCardNumberLoading ? (
              <Skeleton borderRadius={12} height={48}/>
            ) : null}
            <div className={[styles.input, cardNumberError.length > 0 ? styles.error : ''].join(' ')}
                 style={{
                   display: isCardNumberLoading ? 'none' : 'block'
                 }}
            >
              <CardNumberElement options={options} onChange={(e) => {
                if (!canSubmit) {
                  sendEventPaymentStartWeb('Card')
                }
                setCanSubmit(true);
                if (e.error) {
                  setCardNumberError(e.error.message);
                } else {
                  setCardNumberError('');
                }
              }}/>
            </div>
            <div className={[styles.error, styles.message].join(' ')}>
              {cardNumberError}
            </div>
          </label>
        </div>
        <div className={styles.row}>
          <label className={styles.field}>
            <div className={styles.label}>
              Expiration date
            </div>
            {isCardExpiryLoading ? (
              <Skeleton borderRadius={12} height={48}/>
            ) : null}
            <div className={[styles.input, cardExpiryError.length > 0 ? styles.error : ''].join(' ')}
                 style={{
                   display: isCardExpiryLoading ? 'none' : 'block'
                 }}
            >
              <CardExpiryElement options={options} onChange={(e) => {
                if (!canSubmit) {
                  sendEventPaymentStartWeb('Card')
                }
                setCanSubmit(true);
                if (e.error) {
                  setCardExpiryError(e.error.message);
                } else {
                  setCardExpiryError('');
                }
              }}/>
            </div>
            <div className={[styles.error, styles.message].join(' ')}>
              {cardExpiryError}
            </div>
          </label>
          <label className={styles.field}>
            <div className={styles.label}>
              Security code
            </div>
            {isCardCvcLoading ? (
              <Skeleton borderRadius={12} height={48}/>
            ) : null}
            <div className={[styles.input, cardCvcError.length > 0 ? styles.error : ''].join(' ')} style={{
              display: isCardCvcLoading ? "none" : "flex",
              padding: '10px 14px'
            }}>
              <div style={{
                width: '100%',
              }}>
                <CardCvcElement options={options} onChange={(e) => {
                  if (!canSubmit) {
                    sendEventPaymentStartWeb('Card')
                  }
                  setCanSubmit(true);
                  if (e.error) {
                    setCardCvcError(e.error.message);
                  } else {
                    setCardCvcError('');
                  }
                }}/>
              </div>
              <div style={{
                position: 'relative'
              }}>
                <div onMouseEnter={showTip}
                     onMouseLeave={hideTip}>
                  <ExpIcon/>
                </div>
                {active && (
                  <div className={`Tooltip-Tip ${"top"}`}>
                    CVV is the last three digits on the back of your credit card
                  </div>
                )}
              </div>
            </div>
            <div className={[styles.error, styles.message].join(' ')}>
              {cardCvcError}
            </div>
          </label>
        </div>
        <div className={styles.formInfo}>
          {errorMessage && <div className={styles.error} style={{
            paddingBottom: 8,
            textAlign: 'center'
          }}>{errorMessage}</div>}
          <button
            disabled={!canSubmit || processing || !stripe || !elements || cardNumberError.length > 0 || cardExpiryError.length > 0 || cardCvcError.length > 0}
            className={styles.button}>
            {processing ? (
                <Oval
                  strokeWidth="4"
                  color={'white'}
                  secondaryColor={'rgba(255, 255, 255, 0.25)'}
                  width="30"
                  height="30"
                  visible={true}
                />
              ) :
              <span className={styles.buttonTextContainer}><span
                className={styles.buttonText}>Get Eatr PRO</span></span>}
          </button>
          <div className={styles.infoText}>
            Cancel anytime in your account settings before the current period ends.
          </div>
        </div>
      </div>
    </form>
  )
}

export default Card
