import {IconProps} from "../types/types";
import React from "react";

const PaymentInformationIcon: React.FC<IconProps> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66406 7.99984L5.9974 11.3332L12.6641 4.6665" stroke="#79C257" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>

  )
}

export default PaymentInformationIcon
