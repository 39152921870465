import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // interface address
  baseURL: window.location.hostname.includes("eatr.com") ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_DEV,
});

axiosInstance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export const defHttp = axiosInstance;
