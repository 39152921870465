import {useCallback, useMemo, useState} from "react";
import {CustomerReview} from "../types/reviews";
import {colorsList} from "./appEnum";

const fakeList: CustomerReview[] = [
  {
    id: '0',
    name: 'Sarah P.',
    rating: 4.8,
    text: 'Fantastic app! User-friendly and efficient. I\'ve managed to plan healthy meals for a whole week in just minutes.'
  },
  {
    id: '1',
    name: 'Mike R.',
    rating: 4.2,
    text: 'Unbelievable results! I\'ve been following the meal plans for a month and feel more energized and healthier. The recipes are delicious and easy to prepare.'
  },
  {
    id: '2',
    name: 'Lily T.',
    rating: 5,
    text: 'A game-changer for meal planning. This app seamlessly integrates healthy eating into my daily routine, saving me time and stress.'
  },
  {
    id: '3',
    name: 'Mia C.',
    rating: 4.8,
    text: 'I shed 20 pounds in three months by following the meal plan and easy-to-make recipes. The variety and taste of the meals kept me motivated.'
  },
  {
    id: '4',
    name: 'Brian B.',
    rating: 4.8,
    text: 'The app helped me lose 30 pounds and transform my eating habits. Recipes are healthy, delicious, and perfect for weight loss.'
  },
  {
    id: '5',
    name: 'Emma M.',
    rating: 4.8,
    text: 'I reached my weight loss goals faster than ever thanks to these healthy, low-calorie recipes. The food is so good that I don\'t even miss my old favorites'
  },
];

function randomColor() {
  const index = Math.floor(Math.random() * colorsList.length);
  return colorsList[index];
}

const fetchMoreData = async (): Promise<CustomerReview[]> => {
  // Simulate an API call to fetch more data
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(fakeList.map(el => {
        el.color = randomColor();
        return el
      }));
    }, 1000);
  });
};


export const useCustomerReviews = (itemsPerPage: number) => {
  const [list, setList] = useState<CustomerReview[]>([])
  const [page, setPage] = useState<number>(0)

  const totalPages = useMemo(() => {
    return Math.ceil(list.length / itemsPerPage)
  }, [itemsPerPage, list]);

  const hasMore = useMemo(() => {
    return page < totalPages
  }, [page, totalPages]);

  const fetchList = useCallback(async () => {
    const response = await fetchMoreData();
    setList(response)
    setPage(1)
  }, [])

  const currentData = () => {
    const begin = (page - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return list.slice(0, end);
  };

  const next = async () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };
  return {
    currentData,
    next,
    hasMore,
    fetchList
  }
}
